<ul class="navbar-nav">
    <li class="nav-item">
        <a class="nav-link" (click)="onToggleMenuSidebar()" role="button"><i class="fas fa-bars"></i
        ></a>
    </li>

</ul>

<!-- <div style="justify-content: center;align-items: center;display: flex;font-size: 35px; font-weight: bold; letter-spacing: 29px;margin-left: 5px;color:#5280ff21">
    <p style="margin-bottom: 0px">
      MINCONTROL: 2023
  </p>

  </div> -->

<ul class="navbar-nav ml-auto">

    <app-language></app-language>
    <app-user></app-user>
    <!-- <li class="nav-item">
        <button class="nav-link" (click)="onToggleControlSidebar()">
            <i class="fas fa-th-large"></i>
        </button>
    </li> -->
</ul>
