import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component, Input, OnInit } from '@angular/core';
import { QuestionParTypeService } from '@services/question-par-type.service';
import { Observable, catchError, map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-question-par-type',
  templateUrl: './question-par-type.component.html',
  styleUrls: ['./question-par-type.component.scss']
})
export class QuestionParTypeComponent implements OnInit {
@Input() type: any;
prepare: any;
  title: string;
  visiblebar: boolean = false;
  data: any;
  selected:any;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  err: any;
  submitted: boolean = false;
  value: boolean = false;
  val: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
    Form:any;
constructor(private service: QuestionParTypeService) { }

 ngOnInit() {
  console.log(this.type.id)
  this.get();
 }
 get(){
  console.log('ok')
  this.data$ = this.service.getById(this.type.id).pipe(
    map(data =>{
      this.data=data;
      console.log(this.data);
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err}))
  );
 }
}
