import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StructureService } from '@services/structure.service';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent implements OnInit{
  @Input() id: any;
  data: any;
  checked:boolean= true;
  constructor(private service: StructureService,private route: ActivatedRoute,){}
 ngOnInit(): void {
  this.id= this.route.snapshot.paramMap.get('id');
  this.get();
  
 }
 get(){
  this.service.getById(this.id).subscribe(data => {
   this.data = data;
  },
  (error) => {
  });
  }
  getStatus(value: boolean){
    if(value){
      return "Fonctionnel";
    }
    else{
      return "Non Fonctionnel";
    }
   }
   getAgrementStatus(value: boolean){
    if(value){
      return "Oui";
    }
    else{
      return "Non";
    }
   }
 }