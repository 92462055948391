import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-parametre',
  templateUrl: './parametre.component.html',
  styleUrls: ['./parametre.component.scss']
})
export class ParametreComponent implements OnInit{

item: any;
home: any;

  ngOnInit(){
    this.item = [
      {label:'Paramétres',},


  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
  }
  }

