<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <div class="col-12 text-align-end justify-content-end align-item-end d-flex">
            <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
        </div>
        <div class="col-12 d-flex justify-content-between pt-0">
            <div class="p-0">
                <h2>
                    Personnels
                </h2>
            </div>
            <div>
                <button pButton pRipple (click)="visibleSidebar = true" type="button" label="Ajouter" icon="pi pi-plus" class="p-button p-button-sm"></button>

            </div>
        </div>
        <main class="main-content border-radius-lg">

            <div class="card">
                <table id="my-table" style="display: none" class="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">First</th>
                            <th scope="col">Last</th>
                            <th scope="col">Handle</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Larry</td>
                            <td>the Bird</td>
                            <td>@twitter</td>
                        </tr>
                    </tbody>
                </table>
                <p-table styleClass="p-datatable-striped" #dt [value]="customers" [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [filterDelay]="0" [globalFilterFields]="[
                'name',
                'country.name',
                'representative.name',
                'status'
            ]">
                    <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                        <div class="table-header col-12 d-flex">
                            <div class="col">
                                <div>
                                    <span class="p-input-icon-left mr-4">
                           <i class="pi pi-search"></i>
                           <input
                               pInputText
                               type="text"
                               (input)="
                                   dt.filterGlobal(
                                       $event.target.value,
                                       'contains'
                                   )
                               "
                               placeholder="Rechercher"
                           />
                       </span>
                                </div>
                            </div>
                            <div class="sizes col d-flex justify-content-end align-content-center align-items-center test">
                                <p-button label="Export" (onClick)="generatePdf()" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                <p-button label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                <p-button label="Importer" icon="pi pi-upload" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>

                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" style="background-color: #2E394B !important">
                        <tr>
                            <th>
                                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                            </th>
                            <th pSortableColumn="name" class="col" style="width: 20% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Noms

                                </div>
                            </th>
                            <th pSortableColumn="country.name" class="col" style="width: 20% !important;">

                                Date de naissance

                            </th>
                            <th pSortableColumn="country.name" class="col" style="width: 20% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Lieu de naissance

                                </div>
                            </th>
                            <th pSortableColumn="representative.name" class="col" style="width: 10% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Photo


                                </div>
                            </th>
                            <th pSortableColumn="date" style="width: 10% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Date

                                </div>
                            </th>
                            <th pSortableColumn="balance" style="width: 10% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Email

                                </div>
                            </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-customer>
                        <tr class="p-selectable-row">
                            <td>
                                <p-tableCheckbox [value]="customer"></p-tableCheckbox>
                            </td>
                            <td>
                                noms
                            </td>
                            <td>
                                01/04/1999
                            </td>

                            <td>
                                yaounde
                            </td>
                            <td>
                                <p-avatar shape="circle" size="xlarge" image="../../../../../assets/img/user.png"></p-avatar>

                            </td>

                            <td>
                                date
                            </td>
                            <td>
                                email
                            </td>


                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">Aucun personnel disponible.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </main>
    </p-scrollPanel>

</section>
<p-sidebar position="right" [style]="{width: '50%'}" [(visible)]="visibleSidebar" [baseZIndex]="10000">

    <ng-template pTemplate="header">
        <h3>Ajouter un personnel</h3>
    </ng-template>

    <ng-template pTemplate="content">
        <form action="">


            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex">
                        <div class="col-md-6">
                            <span class="p-float-label">
                <input style="width: 100% !important;" id="float-input" type="text" pInputText>
                <label for="float-input">Noms</label>
            </span>
                        </div>
                        <div class="col-md-6">
                            <span class="p-float-label">
                <input style="width: 100% !important;" id="float-input" type="date" pInputText>
                <label for="float-input">Date de naissance</label>
            </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex">
                        <div class="col-md-6">

                            <span class="p-float-label">
                <input style="width: 100% !important;" id="float-input" type="text" pInputText>
                <label for="float-input">Lieu de naissance</label>
            </span>
                        </div>
                        <div class="col-md-6">
                            <span class="p-float-label">
                <input style="width: 100% !important;" id="float-input" type="tel" pInputText>
                <label for="float-input">Numero de téléphone</label>
              </span>
                        </div>
                    </div>
                    <div class="col-12 d-flex">
                        <div class="col-md-6">

                            <span class="p-float-label">
                <input style="width: 100% !important;" id="float-input" type="email" pInputText>
                <label for="float-input">Email</label>
              </span>
                        </div>
                        <div class="col-md-6">

                        </div>
                    </div>
                </div>
            </div>




        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="visibleSidebar=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>
        <button pButton pRipple type="button" label="Sauvegarder" class="p-button-raised"></button>
    </ng-template>

</p-sidebar>