import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-formateurs',
  templateUrl: './formateurs.component.html',
  styleUrls: ['./formateurs.component.scss']
})
export class FormateursComponent {
  visibleSidebar: boolean = false;
  displayRegions: boolean = true;
  displayArrondissements: boolean = true;
  displayDepartements: boolean = true;
  items: any[];
  representatives: any[];

   statuses: any[];
   home: any;
   Regions: MenuItem[] | undefined;
   Departements: MenuItem[] | undefined;
   formateurs: any[];
   division='';
   selectedCustomers: any[];
   itemsMenu: MenuItem[] | undefined;
   selectedFormateur: | undefined;

  ngOnInit(){
    this.itemsMenu = [
      {
          label: 'Region',
          
      },
      {
          label: 'Departement',
      },
      {
        label: 'Arrondissement',
        
      }
  ];
    this.items = [

      {label:'Formateurs'}
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/'};

    this.formateurs = [
      {
        id: 1000,
        name: "James Butt",
        country: {
          name: "Algeria",
          code: "dz"
        },
        company: "Benton, John B Jr",
        date: "2015-09-13",
        status: "unqualified",
        activity: 17,
        representative: {
          name: "Ioni Bowcher",
          image: "ionibowcher.png"
        }
      }

    ]
    this.representatives = [
        {name: "Amy Elsner", image: 'amyelsner.png'},
        {name: "Anna Fali", image: 'annafali.png'},
        {name: "Asiya Javayant", image: 'asiyajavayant.png'},
        {name: "Bernardo Dominic", image: 'bernardodominic.png'},
        {name: "Elwin Sharvill", image: 'elwinsharvill.png'},
        {name: "Ioni Bowcher", image: 'ionibowcher.png'},
        {name: "Ivan Magalhaes",image: 'ivanmagalhaes.png'},
        {name: "Onyama Limba", image: 'onyamalimba.png'},
        {name: "Stephen Shaw", image: 'stephenshaw.png'},
        {name: "Xuxue Feng", image: 'xuxuefeng.png'}
    ];

    this.statuses = [
        {label: 'Unqualified', value: 'unqualified'},
        {label: 'Qualified', value: 'qualified'},
        {label: 'New', value: 'new'},
        {label: 'Negotiation', value: 'negotiation'},
        {label: 'Renewal', value: 'renewal'},
        {label: 'Proposal', value: 'proposal'}
    ]
  }

  
  loadRegions(){
    this.division='Regions';
    this.displayRegions= false;
    this.displayArrondissements= true;
    this.displayDepartements=true;

      this.Regions = [
        {
            label: 'Centre',
            
        },
        {
            label: 'Sud',
        },
        {
          label: 'Littoral',
          
        }
    ];
    
  }
  loadDepartements(){
    this.division='Departements';
    this.displayRegions= true;
    this.displayArrondissements= true;
    this.displayDepartements=false;
    this.Departements = [
      {
          label: 'MFOUNDI',
          
      },
      {
          label: 'AKONO',
      },
      {
        label: 'MBAM ET NKIM',
        
      }
  ];
  
}
}
