import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
 import { ToastrService } from 'ngx-toastr';
 import { AppDataState, DataStateEnum } from '@/State/load.state';
 import { catchError, map, Observable, of, startWith } from 'rxjs';
 import { FormControl, FormGroup, Validators } from '@angular/forms';
 import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FonctionService } from '@services/fonction.service';

@Component({
  selector: 'app-fonctions',
  templateUrl: './fonctions.component.html',
  styleUrls: ['./fonctions.component.scss'],
  providers: [ConfirmationService],
})
export class FonctionsComponent {
  data$: Observable<AppDataState<any[]>> |null = null;
   readonly DataStateEnum=DataStateEnum;
   value: boolean = false;
   Form: FormGroup
   submitted: boolean = false;
   departements: any[] | undefined;
 
    visibleSidebar: boolean = false;
    visibleSidebar1: boolean = false;
    visibleSidebar2: boolean = false;
     selectedRegions: any[];
      data: any;
     representatives: any[];
   val:any;
   err: any;
     statuses: any[];
     item2: any[];
     home: any;
     loading: boolean = true;
     specialite: any;
     prepare: any;
     title: string = "";
     activityValues: number[] = [0, 100];
     items: [] | undefined;
     invalid= false;
     subscribError: any;
   nameInvalidError: any;
   codeInvalidError: any;
     get description(){return this.Form.get('description'); }
     get libelle(){return this.Form.get('libelle'); }
     constructor(private modalService: NgbModal,private confirmationService: ConfirmationService,private toastr: ToastrService, private service: FonctionService) {}

     ngOnInit(){
      this.Form= new FormGroup({
        "id": new FormControl(""),
        "libelle": new FormControl("", [Validators.required]),
        "description": new FormControl("", [Validators.required]),
   
      })
      this.get()
       this.item2 = [
         {label:'Fonctions',},
        
         
     ];
     this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
 
   }
  get(){
   this.data$ = this.service.getAll().pipe(
     map(data =>{
       this.data=data;
       console.log(this.data);
       return ({dataState:DataStateEnum.LOADED})
     }),
     startWith({dataState:DataStateEnum.LOADING}),
     catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
   );
  }
 
  delete(){
   this.err='';
   this.submitted = true;
     this.service.delete(this.val).subscribe(data => {
       this.submitted = false;
       this.modalService.dismissAll();
       this.ngOnInit();
       this.toastr.success('fonction supprimé avec succés', 'Success');
 
     },
     (error)=>{
       this.err= error;
       this.submitted = false;
       this.toastr.error('Nous rencontrons une erreur', 'Error');
     })
  }
  update(){
   this.submitted = true;
   console.log(this.Form.value)
     this.service.update(this.Form.value).subscribe(data => {
       this.submitted = false;
       this.visibleSidebar = false;
       this.ngOnInit();
       this.toastr.success('fonction modifié avec succés', 'Success');
 
     },
     (error)=>{
       this.submitted = false;
       this.toastr.error('Nous renvontrons une erreur', 'Error');
     })
  }
  add(){
   this.submitted = true;
   if( this.Form.valid){
     this.service.create(this.Form.value).subscribe((result)=>{
      this.submitted = false;
      this.visibleSidebar = false;
 
       this.ngOnInit();
       this.toastr.success('fonction ajouté avec succées', 'Success');
 
     },
     (error)=>{
      this.submitted = false;
      this.toastr.error('Nous renvontrons une erreur', 'Error');
 
     }
     )
   }
  }
  confirm(content) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected region?',
      accept: () => {
        this.toastr.error('La fonction a ete supprimer avec sucess', 'Success');
        content.close();
        this.ngOnInit();

    }

    });
}
open() {
  this.prepare = {};
  this.submitted = false;
  this.title='Ajouter une fonction';
  this.visibleSidebar = true;


 }
 edit(item: any){
  this.prepare = {...item};
  this.title='Modifier une fonction'
  this.visibleSidebar = true;
}
 opens(content,value:any) {
  this.modalService.open(content, { centered: true })
  this.val= {...value};


}
close(){
  this.err= "";
  this.modalService.dismissAll()      }

editRegion(){
  this.toastr.success('Le service a ete modifier avec sucess', 'Success');
        this.visibleSidebar = false;

}
}
