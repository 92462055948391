import { Component } from '@angular/core';

@Component({
  selector: 'app-infos-suivie-formation',
  templateUrl: './infos-suivie-formation.component.html',
  styleUrls: ['./infos-suivie-formation.component.scss']
})
export class InfosSuivieFormationComponent {

}
