<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
            <div class="d-flex justify-content-between" style="width: 100%;height: 30px;padding: 20px;">
                <div></div>
                <div>
                    <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

                </div>

            </div>
            <div class="d-flex justify-content-between" style="width: 100%;height: 110px;padding: 20px;">
                <div class="text-secondary pt-4 pl-4 pb-2">
                    <h4>
                        Liste des diplomes
                    </h4>


                </div>
                <div>
                    <button pButton pRipple (click)="open1()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin-right: 10px;margin-top: 30px;"></button>

                </div>


            </div>

            <div class="card">



                <p-table styleClass="p-datatable-striped" #dt [value]="niveaux" [(selection)]="selectedniveau" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [filterDelay]="0" [globalFilterFields]="[
        'index',
        'name',
        
        ]">
                    <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                        <div class="table-header col-12 d-flex">

                            <div class="col d-flex justify-content-between align-content-center align-items-center">
                                <div>
                                    <span class="p-input-icon-left mr-4">
                     <i class="pi pi-search"></i>
                     <input
                         pInputText
                         type="text"
                         (input)="
                             dt.filterGlobal(
                                 $event.target.value,
                                 'contains'
                             )
                         "
                         placeholder="Rechercher"
                     />
                 </span>
                                </div>
                                <div class="test">

                                    <p-button label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                    <p-button type="button" id="dropdownManual1" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                </div>


                            </div>

                        </div>



                    </ng-template>
                    <ng-template pTemplate="header" style="background-color: #2E394B !important">
                        <tr style="width: 10% !important;">
                            <th pSortableColumn="index" class="col" style="width: 10% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Index

                                </div>
                            </th>
                            <th style="width: 20% !important;" pSortableColumn="name" class="col">

                                Diplome

                            </th>
                            <th style="width: 20% !important;" pSortableColumn="code" class="col">
                                <div class="flex justify-content-between align-items-center">
                                    Sigle
                                </div>
                            </th>
                            <th style="width: 15% !important;" pSortableColumn="code" class="col">
                                <div class="flex justify-content-between align-items-center">
                                    Description
                                </div>
                            </th>

                            <th style="width: 13% !important;" pSortableColumn="" class="col">
                                <div class="flex justify-content-between align-items-center">
                                    Actions
                                </div>
                            </th>

                    </ng-template>
                    <ng-template pTemplate="body" let-niveau>
                        <tr class="p-selectable-row">
                            <td>
                                {{niveau.index}}
                            </td>
                            <td>
                                {{niveau.name}}
                            </td>
                            <td>
                                {{niveau.code}}
                            </td>
                            <td>
                                {{niveau.description}}
                            </td>

                            <td>
                                <div class="row d-flex test">
                                    <div class="col-5">
                                        <p-button (click)="edit1(niveau)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                    </div>
                                    <div class="col-5">
                                        <p-button (onClick)="confirm(niveau, cd2)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                                    </div>
                                </div>

                            </td>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">Aucune commune trouve</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>



        </main>
    </p-scrollPanel>


</section>
<p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar1" [baseZIndex]="10000">

    <ng-template pTemplate="header">
        <h4>{{title}}</h4>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="container">
            <div class="container">
                <form action="" [formGroup]="niveauForm">

                    <div class="col-12 field">

                        <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                            <label class="form-label" for="form3Example3">Nom<span class="text-danger me-1">*</span></label>
                            <div class="input-group">
                                <input type="text" formControlName="name" name="name" placeholder="Entrer le nom" class="form-control" [(ngModel)]="niveau.name" required />
                            </div>
                            <div *ngIf="name.invalid && (name.touched || name.dirty) ">
                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="name.errors?.required ">*le nom est obligatoire</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 field ">
                        <div>
                            <div class="input-field " [class.error]="sigle.invalid && sigle.hasError( 'required', 'type') ">
                                <label for="pass " class="form-label ">Sigle<span class="text-danger me-1 ">*</span></label>
                                <div class="input-group">
                                    <input type="text" formControlName="sigle" name="sigle" class="form-control" placeholder="Entrer le sigle" [(ngModel)]="niveau.sigle" required>
                                </div>
                                <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                <div *ngIf="sigle.invalid && (sigle.touched || sigle.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="sigle.errors?.required ">*le sigle est obligatoire</span>
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="sigle.errors?.minlength ">*le sigle doit avoir au moins 2 charactéres</span>

                                </div>
                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                            </div>
                        </div>
                    </div>
                    <div class="col-12 field">

                        <div>
                            <label class="form-label" for="form3Example3">Description</label>
                            <div class="input-group">
                                <textarea type="text" formControlName="description" name="description" placeholder="Entrer la description" class="form-control" [(ngModel)]="niveau.name"></textarea>
                            </div>

                        </div>
                    </div>

                </form>
                <div class="d-flex align-items-center justify-content-center" style="margin-top: 200px;">
                    <button pButton pRipple type="button" (click)="visibleSidebar1=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>

                    <button pButton pRipple type="button" label="Sauvegarder" class="p-button-raised" (click)="saveRegion()"></button>
                </div>
            </div>

        </div>

    </ng-template>




</p-sidebar>
<p-confirmDialog #cd2 header="Confirmation" icon="pi pi-exclamation-triangle">
    <ng-template pTemplate="header">
        <h3>Voulez vous supprimer cet fonction</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton class="p-button-secondary" (click)="cd2.reject()" label="Annuler"></button>
        <button type="button" pButton class="p-button-danger" label="Supprimer" (click)="cd2.accept()"></button>
    </ng-template>
</p-confirmDialog>