<ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
        <app-spinner></app-spinner>
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.ERROR">
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.LOADED">
        <div class="d-flex justify-content-between" style="width: 100%;height: 60px;padding: 0px;">
            <div class="col">
                <h4>
                    LISTE DES DEPARTEMENTS
                </h4>
            </div>
            <div>
                <button pButton pRipple (click)="open1()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin-right: 10px;"></button>
            </div>
        </div>

        <div class="card">
            <p-table styleClass="p-datatable-striped" #dt [value]="departements" [(selection)]="selectedDepartement" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [filterDelay]="0" [globalFilterFields]="[
              'index',
              'name',
              'code',
          ]">
                <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                    <div class="table-header col-12 d-flex">

                        <div class="col d-flex justify-content-between align-content-center align-items-center">
                            <div>
                                <span class="p-input-icon-left mr-4">
                             <i class="pi pi-search"></i>
                             <input
                                 pInputText
                                 type="text"
                                 (input)="
                                     dt.filterGlobal(
                                         $event.target.value,
                                         'contains'
                                     )
                                 "
                                 placeholder="Rechercher"
                             />
                         </span>
                            </div>
                            <div class="test">
                                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                                    <p-button ngbDropdownAnchor id="dropdownManual" (click)="myDrop.open()" label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownManual">

                                        <button ngbDropdownItem>Exporter des departement</button>
                                        <button ngbDropdownItem>Exporter les commune</button>

                                    </div>
                                </div>
                                <div class="d-inline-block" ngbDropdown #myDrop1="ngbDropdown">
                                    <p-button ngbDropdownAnchor type="button" id="dropdownManual1" (click)="myDrop1.open()" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                    <div ngbDropdownMenu aria-labelledby="dropdownManual1">

                                        <button ngbDropdownItem>Imprimer des departement</button>
                                        <button ngbDropdownItem>Imprimer les commune</button>

                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" style="background-color: #2E394B !important" let-columns>
                    <tr>
                        <th style="width: 5% !important;" pSortableColumn="index" class="col" style="width: 10% !important;">
                            <div class="flex justify-content-between align-items-center">
                                #

                            </div>
                        </th>
                        <th style="width: 15% !important;" pSortableColumn="name" class="col">

                            Departement

                        </th>
                        <th style="width: 15% !important;" pSortableColumn="code" class="col">
                            <div class="flex justify-content-between align-items-center">
                                Code
                            </div>
                        </th>

                        <th style="width: 15% !important;" pSortableColumn="" class="col">
                            <div class="flex justify-content-between align-items-center">
                                Arrondissement
                            </div>
                        </th>
                        <th style="width: 15% !important;" pSortableColumn="" class="col">
                            <div class="flex justify-content-between align-items-center">
                                Region
                            </div>
                        </th>
                        <th style="width: 15% !important;" pSortableColumn="" class="col">
                            <div class="flex justify-content-between align-items-center">
                                Actions
                            </div>
                        </th>

                </ng-template>
                <ng-template pTemplate="body" let-departements let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
                    <tr class="p-selectable-row">
                        <td>{{rowIndex + 1}}</td>
                        <td>
                            {{departements.libelle}}
                        </td>
                        <td>
                            {{departements.code}}
                        </td>

                        <td routerLink="/division/departement/{{departements.id}}">

                            <span style="color: rgb(4, 20, 20);"><i class="pi pi-eye"></i> {{departements.arrondissements.length}}</span>
                        </td>
                        <td>
                            {{departements.region.libelle}}
                        </td>
                        <td>
                            <div class="row d-flex">
                                <div class="col-5">
                                    <p-button (click)="edit1(departements)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                </div>
                                <div class="col-5">
                                    <p-button (click)="opens(content, departements)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                                </div>
                            </div>

                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">Aucun departement trouve</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>


        <p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar1" [baseZIndex]="10000">

            <ng-template pTemplate="header">
                <h4>{{title}}</h4>
            </ng-template>

            <ng-template pTemplate="content">
                <div class="container">
                    <div class="container">
                        <form action="" [formGroup]="departementForm">
                            <span *ngIf="error===true" class="error fs-6 text-danger mx-2 my-2 ">{{message}}</span>
                            <div class="col-12 field">
                                <input *ngIf="modifier===true" [(ngModel)]="departement.id" type="text" style="display:none" pInputText formControlName="id" name="id" />

                                <div class="input-field" [class.error]="region.invalid && region.hasError('required','type')">
                                    <label class="form-label" for="form3Example3">Region<span class="text-danger me-1">*</span></label>
                                    <div class="input-group">
                                        <select *ngIf="modifier===true" name="regionId" formControlName="regionId" class="form-control" [(ngModel)]="departement.region.id" required>
                                            <option *ngFor="let item of regions;" value="{{item.id}}">{{item.libelle}}</option>
                                        </select>
                                        <select *ngIf="modifier===false" name="regionId" formControlName="regionId" class="form-control" required>
                                            <option *ngFor="let item of regions;" value="{{item.id}}">{{item.libelle}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="region.invalid && (region.touched || region.dirty) ">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="region.errors?.required ">*la region est obligatoire</span>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 field">

                                <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                                    <label class="form-label" for="form3Example3">libelle<span class="text-danger me-1">*</span></label>
                                    <div class="input-group">
                                        <input type="text" formControlName="libelle" name="libelle" placeholder="Entrer le nom" [(ngModel)]="departement.libelle" class="form-control" required />
                                    </div>
                                    <div *ngIf="name.invalid && (name.touched || name.dirty) ">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="name.errors?.required ">*le libelle est obligatoire</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 field ">
                                <div>
                                    <div class="input-field " [class.error]="code.invalid && code.hasError( 'required', 'type') ">
                                        <label for="pass " class="form-label ">Code<span class="text-danger me-1 ">*</span></label>
                                        <div class="input-group">
                                            <input type="text" formControlName="code" name="code" placeholder="Entrer le code" class="form-control" [(ngModel)]="departement.code" required />
                                        </div>
                                        <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                        <div *ngIf="code.invalid && (code.touched || code.dirty) ">
                                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.required ">*le code est obligatoire</span>
                                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.minlength ">*le code doit avoir au moins 2 charactéres</span>

                                        </div>
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                                    </div>
                                </div>
                            </div>

                            <div class="d-flex align-items-center justify-content-center" style="margin-top: 100px;">
                                <button pButton pRipple type="button" (click)="visibleSidebar1=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>

                                <button pButton pRipple type="button" label="Ajouter" class="p-button-raised" (click)="AddDepartement()" *ngIf="modifier===false" [disabled]="submitted"><i class="pi pi-spin pi-spinner" *ngIf="submitted == true"></i></button>
                                <button pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="editDepartement()" *ngIf="modifier===true" [disabled]="submitted"><i class="pi pi-spin pi-spinner" *ngIf="submitted == true"></i></button>
                            </div>
                        </form>

                    </div>

                </div>

            </ng-template>




        </p-sidebar>
        <ng-template #content let-modal>
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title">Voulez vous supprimer ce departement?</h5>
                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    Cette action est irreversible
                </div>
                <span class="error fs-6 text-danger mx-2 my-2 ">{{message}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" pButton class="p-button-secondary" (click)="modal.close('Save click')" label="Annuler"></button>
                <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted == true"></i></button>

            </div>
        </ng-template>
    </ng-container>

</ng-container>