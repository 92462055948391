import { Component } from '@angular/core';

@Component({
  selector: 'app-infos-manuels',
  templateUrl: './infos-manuels.component.html',
  styleUrls: ['./infos-manuels.component.scss']
})
export class InfosManuelsComponent {
values1: any;
}
