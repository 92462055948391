<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">

        <div class="col-12 d-flex justify-content-between align-items-center pt-10">
            <div class="p-0">
                <h4>
                    LISTE DES STRUCTURES
                </h4>
            </div>
            <div>
                <p-breadcrumb [model]="item2" [home]="home"></p-breadcrumb>
            </div>

        </div>
        <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
            <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                <div class="alert alert-danger" role="alert">
                    code : {{ result.errorMessage.code }} Nous rencontrons une erreur
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                <div class="col-12 d-flex justify-content-between pt-0">
                    <div></div>
                    <div>
                        <p-dropdown [options]="regions" [(ngModel)]="val" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Region">
                            <ng-template let-region pTemplate="item">
                                <div class="flex align-items-center gap-2">

                                    <div>{{ region.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <p-dropdown [options]="regions" [(ngModel)]="val" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Departement">
                            <ng-template let-region pTemplate="item">
                                <div class="flex align-items-center gap-2">

                                    <div>{{ region.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <p-dropdown [options]="regions" [(ngModel)]="val" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Arrondissement">
                            <ng-template let-region pTemplate="item">
                                <div class="flex align-items-center gap-2">

                                    <div>{{ region.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <p-dropdown [options]="regions" [(ngModel)]="val" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Statut">
                            <ng-template let-region pTemplate="item">
                                <div class="flex align-items-center gap-2">

                                    <div>{{ region.name }}</div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                    </div>


                </div>
                <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">

                    <div class="card">



                        <p-table styleClass="p-datatable-striped" #dt [value]="data" [(selection)]="value" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [filterDelay]="0" [globalFilterFields]="[
      'index',
      'name',
      'description',
      'date creation'
  ]">
                            <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                                <div class="table-header col-12 d-flex">

                                    <div class="col d-flex justify-content-between align-content-center align-items-center">
                                        <div>
                                            <span class="p-input-icon-left mr-4">
                     <i class="pi pi-search"></i>
                     <input
                         pInputText
                         type="text"
                         (input)="
                             dt.filterGlobal(
                                 $event.target.value,
                                 'contains'
                             )
                         "
                         placeholder="Rechercher"
                     />
                 </span>
                                        </div>
                                        <div class="test">
                                            <p-button label="Exporter" icon="pi pi-file-excel" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>


                                            <p-button label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>

                                        </div>


                                    </div>
                                </div>

                            </ng-template>
                            <ng-template pTemplate="header" style="background-color: #2E394B !important">
                                <tr style="width: 5% !important;">
                                    <th pSortableColumn="index" class="col" style="width: 10% !important;">
                                        <div class="flex justify-content-between align-items-center">
                                            #

                                        </div>
                                    </th>
                                    <th style="width: 10% !important;" pSortableColumn="name" class="col">

                                        Nom

                                    </th>
                                    <th style="width: 10% !important;" pSortableColumn="name" class="col">
                                        Code

                                    </th>
                                    <th style="width: 10% !important;" pSortableColumn="code" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            Situation
                                        </div>
                                    </th>
                                    <th style="width: 10% !important;" pSortableColumn="" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            Localisation
                                        </div>
                                    </th>
                                    <th style="width: 10% !important;" pSortableColumn="" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            Contact
                                        </div>
                                    </th>

                            </ng-template>
                            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                                <tr class="p-selectable-row" routerLink="/dashboard-structure/{{item.id}}">
                                    <td>
                                        {{rowIndex + 1}}
                                    </td>
                                    <td>
                                        {{item.name}}
                                    </td>
                                    <td>
                                        {{item.code}}
                                    </td>
                                    <td>
                                        {{getStatus(item.estFonctionnel)}} <br>
                                        <span *ngIf="item.estFonctionnel == false" style="font-size: 10px;">{{item.situationStructure.libelleFR}}</span>
                                    </td>

                                    <td>
                                        {{item.adresse.ville}}
                                    </td>

                                    <td>
                                        {{item.adresse.telephone}}{{item.adresse.telephone2}}
                                    </td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="6">Aucune formation trouve</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                </main>
            </ng-container>
        </ng-container>
    </p-scrollPanel>

</section>