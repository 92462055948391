<div class="d-flex justify-content-between" style="width: 100%;padding-bottom: 10px;">
    <div class="col">
        <h3>
            LISTE DES TYPE DE QUESTION
        </h3>
    </div>
    <div>
        <button pButton pRipple (click)="open1()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin-right: 10px;"></button>
    </div>
</div>
<div class="card">
    <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
            <app-spinner></app-spinner>
        </ng-container>
        <ng-container *ngSwitchCase="DataStateEnum.ERROR">
            <div class="alert alert-danger" role="alert">
                code : {{ result.errorMessage.code }} Nous rencontrons une erreur
            </div>
        </ng-container>
        <p-table styleClass="p-datatable-striped" #dt [value]="data" [(selection)]="selected" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [filterDelay]="0" [globalFilterFields]="[
'index',
'libelle',

]">
            <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                <div class="table-header col-12 d-flex">

                    <div class="col d-flex justify-content-between align-content-center align-items-center">
                        <div>
                            <span class="p-input-icon-left mr-4">
             <i class="pi pi-search"></i>
             <input
                 pInputText
                 type="text"
                 (input)="
                     dt.filterGlobal(
                         $event.target.value,
                         'contains'
                     )
                 "
                 placeholder="Rechercher"
             />
         </span>
                        </div>
                        <div>

                            <p-button label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                            <p-button type="button" id="dropdownManual1" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                        </div>


                    </div>

                </div>



            </ng-template>
            <ng-template pTemplate="header" style="background-color: #2E394B !important">
                <tr style="width: 10% !important;">
                    <th pSortableColumn="index" class="col" style="width: 10% !important;">
                        <div class="flex justify-content-between align-items-center">
                            #

                        </div>
                    </th>
                    <th style="width: 20% !important;" pSortableColumn="name" class="col">

                        Libelle

                    </th>
                    <th style="width: 15% !important;" pSortableColumn="code" class="col">
                        <div class="flex justify-content-between align-items-center">
                            Description
                        </div>
                    </th>

                    <th style="width: 13% !important;" pSortableColumn="" class="col">
                        <div class="flex justify-content-between align-items-center">
                            Actions
                        </div>
                    </th>

            </ng-template>
            <ng-template pTemplate="body" let-type let-rowIndex="rowIndex">
                <tr class="p-selectable-row">
                    <td>
                        {{type.numero}}
                    </td>
                    <td>
                        {{type.libelle}}
                    </td>

                    <td>
                        {{type.description}}
                    </td>

                    <td>
                        <div class="row d-flex">
                            <div class="col-5">
                                <p-button (click)="edit(type)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                            </div>
                            <div class="col-5">
                                <p-button (click)="opens(content, type)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                            </div>
                        </div>

                    </td>

                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5" style="text-align: center;">Aucun type de bourse trouvé</td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
    <ng-template #content let-modal>
        <div class="modal-header">
            <h5 class="modal-title" id="modal-basic-title">Voulez vous supprimer ce type de question?</h5>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <div class="mb-3">
                Cette action est irreversible
            </div>
            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="err ">*{{err?.message}}.</span>

        </div>
        <div class="modal-footer">
            <button type="button" pButton class="p-button-secondary" (click)="close()" label="Annuler"></button>
            <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

        </div>
    </ng-template>
    <p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visiblebar" [baseZIndex]="10000">

        <ng-template pTemplate="header">
            <h4>{{title}}</h4>
        </ng-template>

        <ng-template pTemplate="content">
            <div class="container">
                <div class="container">
                    <form action="" [formGroup]="Form">

                        <div class="col-12 field">
                            <input *ngIf="title==='Modifier un type de question'" type="text" formControlName="id" style="display: none;" class="form-control" [(ngModel)]="prepare.id" required />

                            <div class="input-field" [class.error]="libelle.invalid && libelle.hasError('required','type')">
                                <label class="form-label" for="form3Example3">Libelle<span class="text-danger me-1">*</span></label>
                                <div class="input-group">
                                    <input type="text" formControlName="libelle" name="libelle" placeholder="Entrer le libelle" class="form-control" [(ngModel)]="prepare.libelle" required />
                                </div>
                                <div *ngIf="libelle.invalid && (libelle.touched || libelle.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="libelle.errors?.required ">*le libelle est obligatoire</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 field ">
                            <div>
                                <div class="input-field " [class.error]="code.invalid && code.hasError( 'required', 'type') ">
                                    <label for="pass " class="form-label ">Numero<span class="text-danger me-1 ">*</span></label>
                                    <div class="input-group">
                                        <input type="number" formControlName="numero" name="numero" class="form-control" [(ngModel)]="prepare.code" required>
                                    </div>
                                    <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                    <div *ngIf="code.invalid && (code.touched || code.dirty) ">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.required ">*le numero est obligatoire</span>
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.minlength ">*le numero doit avoir au moins 2 charactéres</span>

                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 field">

                            <div>
                                <label class="form-label" for="form3Example3">Description</label>
                                <div class="input-group">
                                    <textarea type="text" formControlName="description" name="description" placeholder="Entrer la description" class="form-control" [(ngModel)]="prepare.description" required=""></textarea>
                                    <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                    <div *ngIf="(description.invalid && description.touched || description.dirty)">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="description.errors?.required ">*la description est obligatoire</span>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message.error.message}}.</span>

                        <div class="d-flex align-items-center justify-content-center" style="margin-top: 100px;">
                            <button pButton pRipple type="button" [disabled]="submitted" (click)="visiblebar=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>

                            <button *ngIf="title==='Ajouter un type de question'" [disabled]="submitted" pButton pRipple type="button" (click)="adds()" label="Sauvegarder" class="p-button-raised"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>
                            <button *ngIf="title==='Modifier un type de question'" [disabled]="submitted" pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="update()"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

                        </div>
                    </form>
                </div>

            </div>

        </ng-template>




    </p-sidebar>

</div>