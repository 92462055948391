import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-dashboard-structure',
  templateUrl: './dashboard-structure.component.html',
  styleUrls: ['./dashboard-structure.component.scss']
})
export class DashboardStructureComponent implements OnInit {
  item2: any;
  items: MenuItem[] | undefined;
  home:any;
  value: number = 1;
  id: any;
constructor(private route: ActivatedRoute){}
  ngOnInit(): void {
    this.id= this.route.snapshot.paramMap.get('id');
    this.item2 = [
      {label:'Structures', routerLink: ['/structures']},
      {label: 'Details structure'}
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'}; 
  this.items = [
    {
      label: 'INFORMATIONS GENERALE',
      icon: 'pi pi-bars',
      command: () => {
      this.value = 1;
    
  }},
    {
        label: 'IDENTIFICATION ET LOCALISATION',
        icon: 'pi pi-home',
        command: () => {
          this.value = 2;
        
      }

    },
    {
      label: 'INFORMATIONS SUR LES FORMATEURS',
      icon: 'pi pi-book',
      command: () => {
        this.value = 3;
      
    }
  },
  {
    label: 'INFORMATIONS SUR LES APPRENANTS',
    icon: 'pi pi-user',
    command: () => {
      this.value = 4;
    
  }
},
{
  label: 'INFORMATIONS SUR LES MANUELS, EQUIPEMENT ET INFRASTRUCTURE',
  icon: 'pi pi-tags',
  command: () => {
    this.value = 5;
  
}
},
{
  label: "INFORMATIONS SUR L'ORIENTAION PROFFESIONELLE",
  icon: 'pi pi-folder',
  command: () => {
    this.value = 6;
  
}
},
{
  label: 'INFORMATIONS SUR LE SUIVIE POST FORMATION',
  icon: 'pi pi-send',
  command: () => {
    this.value = 7;
  
}
},
{
  label: ' INFORMATIONS SUR LE VIH ET VIOLENCE',
  icon: 'pi pi-user-plus',
  command: () => {
    this.value = 8;
  
}
},
{
  label: 'DIFFICULTE ET PERSPECTIVE',
  icon: 'pi pi-fw pi-plus',
  command: () => {
    this.value = 9;
  
}
},
];
  }

}
