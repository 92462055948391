<ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
        <app-spinner></app-spinner>
    </ng-container>
    <p-table styleClass="p-datatable-striped" #dt [value]="data" [(selection)]="selected" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="[
'index',
'libelle',

]">
        <ng-template pTemplate="caption" style="background-color: #2E394B !important">
            <div class="table-header col-12 d-flex">

                <div class="col d-flex justify-content-between align-content-center align-items-center">
                    <div>
                        <span class="p-input-icon-left mr-4">
     <i class="pi pi-search"></i>
     <input
         pInputText
         type="text"
         (input)="
             dt.filterGlobal(
                 $event.target.value,
                 'contains'
             )
         "
         placeholder="Rechercher"
     />
 </span>
                    </div>
                    <div>
                    </div>


                </div>

            </div>



        </ng-template>
        <ng-template pTemplate="header" style="background-color: #2E394B !important">
            <tr style="width: 10% !important;">
                <th pSortableColumn="index" class="col" style="width: 10% !important;">
                    <div class="flex justify-content-between align-items-center">
                        #

                    </div>
                </th>
                <th style="width: 20% !important;" pSortableColumn="name" class="col">

                    Libelle

                </th>
                <th style="width: 15% !important;" pSortableColumn="code" class="col">
                    <div class="flex justify-content-between align-items-center">
                        Proposition de reponse
                    </div>
                </th>

                <th style="width: 13% !important;" pSortableColumn="" class="col">
                    <div class="flex justify-content-between align-items-center">
                        Actions
                    </div>
                </th>

        </ng-template>
        <ng-template pTemplate="body" let-type let-rowIndex="rowIndex">
            <tr class="p-selectable-row">
                <td>
                    {{rowIndex + 1}}
                </td>
                <td>
                    {{type.libelle}}
                </td>

                <td>

                </td>

                <td>
                    <div class="row d-flex">
                        <div class="col-5">
                            <p-button icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                        </div>
                        <div class="col-5">
                            <p-button icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                        </div>
                    </div>

                </td>

            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="5" style="text-align: center;">Aucun type de bourse trouvé</td>
            </tr>
        </ng-template>
    </p-table>
</ng-container>