import { Component, OnInit } from '@angular/core';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SessionService} from '@services/sessions.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-session',
  templateUrl: './session.component.html',
  styleUrls: ['./session.component.scss']
})
export class SessionComponent implements OnInit {
  options: any[] | undefined;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  Form: any;
  titreSession: any;
  value: boolean = false;
  submitted: boolean = false;
  visibleSidebar: boolean = false;
  typebourses: any[] | undefined;
  itemsButton: any[];
  sessionSave: any;
  typebourse: any;
  updated: boolean=false;
  data:any;
  modifier: Boolean = false;
  constructor(private toastr: ToastrService,private service: SessionService){}
  ngOnInit(){
    this.Form = new FormGroup({
      "id": new FormControl(""),
      "annee": new FormControl("", [Validators.required /*, Validators.minLength(4)*/]),
    })
    this.getBourse()
    this.itemsButton = [
      
          {label: 'Edit',icon: 'pi pi-fw pi-pencil'},
          {label: 'Delete', icon: 'pi pi-fw pi-trash'}
          
  ]
      
  

   
  }
  addBourse(){
    if( this.Form.valid){
      this.service.create(this.Form.value).subscribe((result)=>{
       this.submitted = false;

      },
      (error)=>{
       this.submitted = false;
      }
      )
    }
  }
  addBourseSession(){
    this.submitted = true;
    if( this.Form.valid){
      this.service.create(this.Form.value).subscribe((result)=>{
       this.submitted = false;
       this.visibleSidebar = false;
  
        this.ngOnInit();
        this.toastr.success('Session ajoutée avec succes', 'Success');
  
      },
      (error)=>{
       this.submitted = false;
       this.toastr.error('Nous renvontrons une erreur', 'Error');
  
      }
      )
    }
  }


  change(){
    this.modifier =! this.modifier
  }
  getBourse(){
    this.data$ = this.service.getAll().pipe(
      map(data =>{
        this.data=data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
    
  }
  editBourse(){
  
    this.submitted = true;
    console.log(this.Form.value)
      this.service.update(this.Form.value).subscribe((result) => {
        this.submitted = false;
        this.visibleSidebar = false;
        this.ngOnInit();
        this.toastr.success('session modifiée avec succés', 'Success');

      },
      (error)=>{
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
  }
  editForm(session:any){
    this.sessionSave= {...session};
    this.visibleSidebar=true;
    this.titreSession='Modifier cette session';

    

  }
  changeState(){
        this.sessionSave = {};
        this.submitted = false;
        this.titreSession='Ajouter une session';
        this.visibleSidebar = true;
        

  }

}
