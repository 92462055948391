import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-infos-difficulte',
  templateUrl: './infos-difficulte.component.html',
  styleUrls: ['./infos-difficulte.component.scss']
})
export class InfosDifficulteComponent implements OnInit{
  ngOnInit(): void {
      console.log('value 1');
  }
 }