<!-- Brand Logo -->
<a [routerLink]="['/']" style="background-color: #283345;text-decoration: none;" class="brand-link">
    <pf-image src="assets/img/log.jpg" alt="Hotel" class="brand-image" height="33" width="33" rounded style="opacity: 1"></pf-image>
    <span class="brand-text font-weight-bolder color-white">MINEFOP</span>
</a>

<!-- Sidebar -->
<div class="sidebar">


    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            <app-menu-item *ngFor="let item of menu" [menuItem]="item"></app-menu-item>

        </ul>
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false" style="margin-top: 25px;font-size: medium;">
            <app-menu-item *ngFor="let item of admin" [menuItem]="item"></app-menu-item>
            <app-menu-item *ngFor="let item of perso" [menuItem]="item"></app-menu-item>
            <app-menu-item *ngFor="let item of forma" [menuItem]="item"></app-menu-item>
            <app-menu-item *ngFor="let item of struc" [menuItem]="item"></app-menu-item>
            <app-menu-item *ngFor="let item of autres" [menuItem]="item"></app-menu-item>
        </ul>
    </nav>



</div>
