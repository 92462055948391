<div class="card">
    <div class="container">
        <div class="surface-section">
            <div class="font-medium text-800 mb-3">
                <h5 style="padding-top: 10px;">INFORMATIONS SUR LES MANUELS, EQUIPEMENT ET INFRASTURE</h5>
            </div>
            <p-accordion [activeIndex]="0">
                <p-accordionTab header="Effectifs des apprenants par Specialites et type de formation">
                    <div class="container">
                        <div class="surface-section">
                            <div class="font-medium text-800 mb-3">
                                <h5 style="padding-top: 10px;">Disposition</h5>
                            </div>
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center py-2 px-2 order-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium" style="margin-right: 30px;">Dispose des manuels d'apprentissage pour apprenant?</div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Heat</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Nombre</div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Heat</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose des manuels d'apprentissage pour formateurs? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Nombre </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>

                            </ul>
                        </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Referentiel de formation">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>specialite</th>
                                <th>Existence d'un referentiel de formation/Existence of a training curriculum</th>
                                <th>Si oui, le referentiel est t'il homologue?/if yes, it is approved?</th>
                            </tr>
                        </thead>


                        <tbody>
                        </tbody>


                    </table>

                </p-accordionTab>
                <p-accordionTab header="Equipement de formation">

                    <div class="container">
                        <div class="surface-section">
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center py-2 px-2 order-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium" style="margin-right: 30px;">Dispose access continu a l'energie electrique?</div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Heap</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose d'une connexion fonctionelle a l'eternet?</div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Heat</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose de l'eau potable? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose de cantines? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose d'une bibliotheque equipee? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose d'un internat? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose des aires de jeu? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose des latrines fonctionnelles? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose d'une salle multimedia fonctionelle/mediatheque? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-6 font-medium">Dispose d'une infirmerie? </div>
                                    <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Nobre d'infrastructure en fonction de leur etat">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>Infrastructure</th>
                                <th>Bon etats</th>
                                <th>Mauvais</th>
                            </tr>
                        </thead>


                        <tbody>
                            <tr>
                                <td>Nombre de salle de classe</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre d'ateliers de pratiques équipés</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre de laboratoire de formation équipés</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombres de blocs administratif</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre de salle de réunion </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre de salle de formateurs</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre de bureaux</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre de magasin</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>



                    </table>
                </p-accordionTab>
                <p-accordionTab header="Equipement mobiliers pour l'annee">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Bon etats</th>
                                <th>Mauvais</th>
                            </tr>
                        </thead>


                        <tbody>
                            <tr>
                                <td>Tables bancs 2 places</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Tables bancs 3 places</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Tables bancs 4 places</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombres de chaise</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombres de tables </td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre d'amoire ou placard</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Nombre de tableaux</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>



                    </table>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div>

</div>