<section class="content main content-wrapper">


    <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
        <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
            <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.ERROR">
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                <div class="d-flex justify-content-between" style="width: 100%;">
                    <div>
                        <div class="text-secondary pt-4 pl-4 pb-2">
                            <h6>
                                LIBLLE: {{values.libelle}}
                            </h6>
                            <h6>
                                CODE: {{values.code}}
                            </h6>
                            <h6>
                                NOMBRE DE ARRONDISSEMENTS : {{values.arrondissements.length}}
                            </h6>
                        </div>
                    </div>
                    <div>
                        <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>
                        <div class="" style="margin-left: 200px;">
                            <button pButton pRipple (click)="open()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button"></button>

                        </div>
                    </div>

                </div>

                <div class="card">


                    <p-table styleClass="p-datatable-striped" #dt [value]="values.arrondissements" [(selection)]="selectedCommune" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [filterDelay]="0" [globalFilterFields]="[
          'index',
          'name',
          'code',
      ]">
                        <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                            <div class="table-header col-12 d-flex">

                                <div class="col d-flex justify-content-between align-content-center align-items-center">
                                    <div>
                                        <span class="p-input-icon-left mr-4">
                         <i class="pi pi-search"></i>
                         <input
                             pInputText
                             type="text"
                             (input)="
                                 dt.filterGlobal(
                                     $event.target.value,
                                     'contains'
                                 )
                             "
                             placeholder="Rechercher"
                         />
                     </span>
                                    </div>
                                    <div class="test">
                                        <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                                            <p-button ngbDropdownAnchor id="dropdownManual" (click)="myDrop.open()" label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownManual">

                                                <button ngbDropdownItem>Exporter des departement</button>
                                                <button ngbDropdownItem>Exporter les commune</button>

                                            </div>
                                        </div>
                                        <div class="d-inline-block" ngbDropdown #myDrop1="ngbDropdown">
                                            <p-button ngbDropdownAnchor type="button" id="dropdownManual1" (click)="myDrop1.open()" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownManual1">

                                                <button ngbDropdownItem>Imprimer des departement</button>
                                                <button ngbDropdownItem>Imprimer les commune</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" style="background-color: #2E394B !important">
                            <tr style="width: 10% !important;">
                                <th pSortableColumn="index" class="col" style="width:5% !important;">
                                    <div class="flex justify-content-between align-items-center">
                                        #

                                    </div>
                                </th>
                                <th pSortableColumn="name" class="col" style="width: 10% !important;">

                                    Arrondissement

                                </th>
                                <th pSortableColumn="name" class="col" style="width: 10% !important;">

                                    Commune
                                </th>
                                <th pSortableColumn="code" class="col" style="width: 10% !important;">
                                    <div class="flex justify-content-between align-items-center">
                                        Code
                                    </div>
                                </th>
                                <th pSortableColumn="code" class="col" style="width: 10% !important;">
                                    <div class="flex justify-content-between align-items-center">
                                        Departement
                                    </div>
                                </th>
                                <th pSortableColumn="code" class="col" style="width: 10% !important;">
                                    <div class="flex justify-content-between align-items-center">
                                        Region
                                    </div>
                                </th>
                                <th pSortableColumn="country.name" class="col" style="width: 10% !important;">
                                    <div class="flex justify-content-between align-items-center">
                                        Actions
                                    </div>
                                </th>

                        </ng-template>
                        <ng-template pTemplate="body" let-value let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
                            <tr class="p-selectable-row">
                                <td>
                                    {{rowIndex + 1}}
                                </td>
                                <td>
                                    {{value.libelle}}
                                </td>
                                <td>
                                    {{value.code}}
                                </td>
                                <td>
                                    {{value.commune}}
                                </td>
                                <td>
                                    {{value.departement.libelle}}
                                </td>
                                <td>
                                    {{value.departement.region.libelle}}
                                </td>

                                <td>
                                    <div class="row d-flex">
                                        <div class="col-5">
                                            <p-button (click)="edit(value)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                        </div>
                                        <div class="col-5">
                                            <p-button (onClick)="opens(content,value)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                                        </div>
                                    </div>

                                </td>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">Aucune commune trouve</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </ng-container>
        </ng-container>

        <p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar2" [baseZIndex]="10000">

            <ng-template pTemplate="header">
                <h4>{{title}}</h4>
            </ng-template>

            <ng-template pTemplate="content">
                <div class="container">
                    <form action="" [formGroup]="communeForm">
                        <span class="error fs-6 text-danger mx-2 my-2 ">{{message}}</span>
                        <input *ngIf="modifier===true" [(ngModel)]="prepare.id" type="text" style="display:none" pInputText formControlName="id" name="id" />
                        <div class="col-12 field">

                            <div class="input-field" [class.error]="departement.invalid && departement.hasError('required','type')">
                                <label class="form-label" for="form3Example3">Departement<span class="text-danger me-1">*</span></label>
                                <div class="input-group">
                                    <select name="departementId" id="departementId" formControlName="departementId" class="form-control" [(ngModel)]="values.id" required>
                                        <option *ngFor="let data of departements" value="{{data.id}}">{{data.libelle}}</option>
                                    </select>
                                </div>
                                <div *ngIf="departement.invalid && (departement.touched || departement.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="departement.errors?.required ">*le departement est obligatoire</span>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 field">

                            <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                                <label class="form-label" for="form3Example3">Libelle<span class="text-danger me-1">*</span></label>
                                <div class="input-group">
                                    <input type="text" formControlName="libelle" name="libelle" placeholder="Entrer le libelle" class="form-control" [(ngModel)]="prepare.libelle" required />
                                </div>
                                <div *ngIf="name.invalid && (name.touched || name.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="name.errors?.required ">*le nom est obligatoire</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 field">

                            <div class="input-field">
                                <label class="form-label" for="form3Example3">Commune<span class="text-danger me-1">*</span></label>
                                <div class="input-group" [class.error]="commune.invalid && commune.hasError('required','type')">
                                    <input type="text" formControlName="commune" name="commune" placeholder="Entrer la commune" class="form-control" [(ngModel)]="prepare.commune" required/>
                                </div>
                                <div *ngIf="commune.invalid && (commune.touched || commune.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="commune.errors?.required ">*la commune est obligatoire</span>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 field ">
                            <div>
                                <div class="input-field " [class.error]="code.invalid && code.hasError( 'required', 'type') ">
                                    <label for="pass " class="form-label ">Code<span class="text-danger me-1 ">*</span></label>
                                    <div class="input-group">
                                        <input type="text" formControlName="code" name="code" class="form-control" placeholder="Entrer le code" [(ngModel)]="prepare.code" required>
                                    </div>
                                    <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                    <div *ngIf="code.invalid && (code.touched || code.dirty) ">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.required ">*le code est obligatoire</span>
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.minlength ">*le code doit avoir au moins 2 charactéres</span>

                                    </div>
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center" style="margin-top: 50px;">
                            <button pButton pRipple type="button" (click)="visibleSidebar2=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>

                            <button pButton pRipple type="button" label="Ajouter" class="p-button-raised" (click)="addArrondissement()" *ngIf="modifier===false" [disabled]="submitted"><i class="pi pi-spin pi-spinner" *ngIf="submitted == true"></i></button>
                            <button pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="editCommune()" *ngIf="modifier===true" [disabled]="submitted"><i class="pi pi-spin pi-spinner" *ngIf="submitted == true"></i></button>
                        </div>
                    </form>
                </div>

            </ng-template>




        </p-sidebar>
        <ng-template #content let-modal>
            <div class="modal-header">
                <h5 class="modal-title" id="modal-basic-title">Voulez vous supprimer cette arrondissement?</h5>
                <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    Cette action est irreversible
                </div>
                <span class="error fs-6 text-danger mx-2 my-2 ">{{message}}</span>
            </div>
            <div class="modal-footer">
                <button type="button" pButton class="p-button-secondary" (click)="modal.close('Save click')" label="Annuler"></button>
                <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted == true"></i></button>

            </div>
        </ng-template>

    </main>
</section>