import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeStructureService } from '@services/typeStructure.service';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Observable, catchError, map, of, startWith } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-type-structures',
  templateUrl: './type-structures.component.html',
  styleUrls: ['./type-structures.component.scss'],
  providers: [ConfirmationService]
})
export class TypeStructuresComponent {
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  submitted: boolean = false;
  typeStructures: any[] | undefined;
  typeStructure: any;
   visibleSidebar1: boolean = false;
   selectedtypeStructure: any;
  title : string ="";
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    data: any;
    nameInvalidError: any;
    codeInvalidError: any;
    typeStructureForm: any;
    val:any;
    err: any;
  Form: any;
  visibleSidebar: boolean;
      get label(){return this.typeStructureForm.get('label'); }
      get code(){return this.typeStructureForm.get('code'); }
    constructor(private modalService: NgbModal,private toastr: ToastrService,private service: TypeStructureService) {}
 

    open1() {
      this.typeStructure = {};
      this.submitted = false;
      this.title = "Ajouter un type de structure";
      this.visibleSidebar1 = true;
     }
     edit1(item: any){
      this.typeStructure = {...item};
      this.title = "Modifier un type de structure";
      this.visibleSidebar1 = true;
    }
    update(){
      this.submitted = true;
      console.log(this.typeStructureForm.value)
        this.service.update(this.typeStructureForm.value).subscribe(data => {
          this.submitted = false;
          this.visibleSidebar1 = false;
          this.ngOnInit();
          this.toastr.success('type de structure modifié avec succes');
    
        },
        (error)=>{
          this.submitted = false;
          this.toastr.error('Nous rencontrons une erreur', 'Error');
        })
     }
  
     opens(content,value:any) {
      this.modalService.open(content, { centered: true })
      this.val= {...value};

  
    }
    close(){
      this.err= "";
      this.modalService.dismissAll()      }
   /* saveRegion(){
      this.toastr.success('Le type de structure a ete ajouter avec success', 'Success');
      this.visibleSidebar1 = false;
    }*/
    add(){
      this.submitted = true;
      if( this.typeStructureForm.valid){
        this.service.create(this.typeStructureForm.value).subscribe((result)=>{
         this.submitted = false;
         this.visibleSidebar1 = false;
    
          this.ngOnInit();
          this.toastr.success('Type structure ajouté avec succées', 'Success');
    
        },
        (error)=>{
         this.submitted = false;
         this.toastr.error('Nous renvontrons une erreur', 'Error');
    
        }
        )
      }
     }
     delete(){
      this.err='';
      this.submitted = true;
        this.service.delete(this.val).subscribe(data => {
          this.submitted = false;
          this.modalService.dismissAll();
          this.ngOnInit();
          this.toastr.success('le type de structure a ete supprimer avec successs');
    
        },
        (error)=>{
          this.err= error;
          this.submitted = false;
          this.toastr.error('Nous rencontrons une erreur', 'Error');
        })
     }
      
     get(){
      this.data$ = this.service.getAll().pipe(
        map(data =>{
          this.data=data;
          console.log(this.data);
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
     }
    

    
  ngOnInit(){
    this.typeStructureForm = new FormGroup({
      "id": new FormControl(""),
      "code": new FormControl("", [Validators.required]),
      "label": new FormControl("", [Validators.required]),
    })
    this.item2 = [
     
      {label:"Type structures"},
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
  this.get();
}


 }
