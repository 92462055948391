<h5> MINCONTROL</h5>
<hr class="mb-2" />
<div style="padding: 8px 0">
    <pf-checkbox (change)="handleDarkModeChange($event)">
         mode sombre
    </pf-checkbox>
    <pf-select
        class="mt-3"
        [value]="navbarVariant"
        [options]="navbarLightVariants"
        type="custom"
        label="Couleur de la barre de navigation"
        (change)="onNavbarVariantChange($event)"
    >
    </pf-select>
    <pf-select
        class="mt-3"
        [value]="navbarVariant"
        [options]="navbarDarkVariants"
        type="custom"
        label=" Couleur de la barre de navigation"
        (change)="onNavbarVariantChange($event)"
    >
    </pf-select>
    <pf-select
        class="mt-3"
        [options]="[]"
        type="custom"
        disabled="true"
        label="Variantes de couleurs d'accentuation"
    >
    </pf-select>
    <pf-select
        class="mt-3"
        [value]="sidebarSkin"
        [options]="lightSidebarSkins"
        type="custom"
        label="Variantes de la barre latérale légère"
        (change)="onSidebarSkinChange($event)"
    >
    </pf-select>
    <pf-select
        class="mt-3"
        [value]="sidebarSkin"
        [options]="darkSidebarSkins"
        type="custom"
        label="Variantes de la barre latérale sombre"
        (change)="onSidebarSkinChange($event)"
    >
    </pf-select>

    <pf-select
        class="mt-3"
        [options]="[]"
        type="custom"
        disabled="true"
        label=" Logo "
    >
    </pf-select>
</div>
