<div class="animation-area" style="z-index: 1;">

    <div class="container-fluid pt-2">




    </div>
    <ul class="box-area">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>



        <div class="row">

            <div class="d-lg-flex justify-content-center gap-3">
                <div class="d-lg-flex flex-column " routerLink="/dashboard" style="cursor: pointer;">
                    <div class="card" style="width: 175px;height: 100px;">
                        <div class="card-body border border-3 d-flex align-items-center justify-content-center" style="background-color: #D38455;">
                            <i class="pi pi-table" style="color: white;font-size:2.5rem"></i>
                        </div>
                    </div>
                    <span class="text-center text-light">Carte de la Formation <br> Proffesionnelle</span>
                </div>

                <div class="d-lg-flex flex-column" (click)="pending()" style="cursor: pointer;width: 175px;">
                    <div class="card" style="width: 175px;height: 100px;">
                        <div class="card-body border border-3 d-flex align-items-center justify-content-center " style="background-color: #A55858;">
                            <i class="pi pi-folder-open" style="color: white;font-size:2.5rem"></i>
                        </div>
                    </div>
                    <span class="text-center text-light">Gestion des Examens</span>
                </div>

            </div>
        </div>
    </ul>
    <div class="container-fluid" style="text-align: end;z-index: 999;">
        <div class="col-lg-12">
            <div class="navbar-nav me-2 " (click)="logout()" style="cursor: pointer;">
                <div class="nav-item dropdown">
                    <a class="nav-link text-light" role="button" data-bs-toggle="dropdown" aria-expanded="true">
                        <span class="navbar-text text-light activeC me-2"><i class="pi pi-sign-out"></i> Déconnexion</span>
                        <i class="fa fa-sign-out fs-4 me-1"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>