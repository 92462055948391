import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { RegionsService } from '@services/regions.service';
import { DepartementService } from '@services/departements.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({

  selector: 'app-departement',
  templateUrl: './departement.component.html',
  styleUrls: ['./departement.component.scss'],
  providers: [ConfirmationService],
  
})
export class DepartementComponent implements OnInit {
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  values: any;
  err: any;
  val: any;
  submitted: boolean = false;
  departements: any[] | undefined;
  departement: any;
   visibleSidebar: boolean = false;
   selectedDepartement: any;
    representatives: any[];
  title : string ="";
    statuses: any[];
    item2: any[];
    home: any;
    id: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    Form: any;
    invalid= false;
    datas: any;
    display: any;
  subscribError: any;
  nameInvalidError: any;
  codeInvalidError: any;
    get region(){return this.Form.get('regionId'); }
    get libelle(){return this.Form.get('libelle'); }
    get code(){return this.Form.get('code'); }
    constructor(private modalService: NgbModal,private route: ActivatedRoute,private confirmationService: ConfirmationService,private location: Location,private toastr: ToastrService,private service: RegionsService,private services: DepartementService) {}
    ngOnInit(){
      this.id= this.route.snapshot.paramMap.get('id');
      this.get();
      this.getdepart();
        this.Form = new FormGroup({
        "id": new FormControl(""),
        "regionId": new FormControl("", [Validators.required]),
        "libelle": new FormControl("", [Validators.required]),
        "code": new FormControl("", [Validators.required,Validators.minLength(2)])
      })
      this.item2 = [
        {label:'Division',routerLink: ['/division']},
        {label:'Departement'},
        
    ];
    this.home = {icon: 'pi pi-home', routerLink: '/dashboard/bord'};

  
  
  
  }
  opens(content,value:any) {
    this.modalService.open(content, { centered: true })
    this.val= {...value};


  }
  close(){
    this.err= "";
    this.modalService.dismissAll()      }
  getdepart(){
   this.service.getAll().subscribe(data => {
    this.datas = data;
   },
   (error) => {
   });
   }
  
  get(){
    this.datas$ = this.service.getById(this.id).pipe(
      map(data =>{
        this.values = data;
        console.log(this.values);
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
    confirm(content) {
        this.confirmationService.confirm({
          message: 'Are you sure you want to delete the selected departement?',
          accept: () => {
            this.toastr.error('La region a ete supprimer avec sucess', 'Success');
            content.close();
            this.ngOnInit();
           
        }
        });
    }
    open1() {
      this.departement= {};
      this.submitted = false;
      this.title = "Ajouter un departement";
      this.display= 'none';
      this.visibleSidebar = true;
      
     }
     edit1(item: any){
      this.departement = {...item};
      console.log(this.departement);
      this.title = "Modifier un departement";
      this.display= 'block';
      this.visibleSidebar = true;
      
    }
  
    
    saveRegion(){
      this.toastr.success('La region a ete modifier avec sucess', 'Success');
      this.visibleSidebar = false;
    }
      

    
    delete(){
      this.err='';
      this.submitted = true;
        this.services.delete(this.val).subscribe(data => {
          this.submitted = false;
          this.modalService.dismissAll();
          this.ngOnInit();
          this.toastr.success('département supprimé avec succés', 'Success');
    
        },
        (error)=>{
          this.err= error;
          this.submitted = false;
          this.toastr.error('err.message.error.message', 'Error');
        })
     }
     update(){
      this.submitted = true;
      console.log(this.Form.value)
        this.services.update(this.Form.value).subscribe(data => {
          this.submitted = false;
          this.visibleSidebar = false;
          this.ngOnInit();
          this.toastr.success('département modifié avec succés', 'Success');
    
        },
        (error)=>{
          this.submitted = false;
          this.toastr.error('Nous rencontrons une erreur', 'Error');
        })
     }
     add(){
      this.submitted = true;
      console.log(this.Form.value);
      if( this.Form.valid){
        this.services.create(this.Form.value).subscribe((result)=>{
         this.submitted = false;
         this.visibleSidebar = false;
    
          this.ngOnInit();
          this.toastr.success('département ajouté avec succées', 'Success');
    
        },
        (error)=>{
         this.submitted = false;
         this.toastr.error('Nous rencontrons une erreur', 'Error');
    
        }
        )
      }
     }

}