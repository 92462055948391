import { Component,OnInit } from '@angular/core';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ParametreService } from '@services/parameter.service';

@Component({
  selector: 'app-parametre-generaux',
  templateUrl: './parametre-generaux.component.html',
  styleUrls: ['./parametre-generaux.component.scss']
})
export class ParametreGenerauxComponent implements OnInit{
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  Form: any;
  value: boolean = false;
  submitted: boolean = false;
  typebourses: any[] | undefined;
  typebourse: any;
  data:any;
  modifier: Boolean = false;
  constructor(private toastr: ToastrService,private service: ParametreService){}
  ngOnInit(){
    this.Form = new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "email": new FormControl("", [Validators.required,Validators.minLength(2)]),
      "telephone": new FormControl(""),
      "siteweb": new FormControl(""),
      "adresse": new FormControl(""),
      "id": new FormControl(""),

    })
    this.getBourse()
  }
  addBourse(){
    if( this.Form.valid){
      this.service.create(this.Form.value).subscribe((result)=>{
       this.submitted = false;

      },
      (error)=>{
       this.submitted = false;
      }
      )
    }
  }
  change(){
    this.modifier =! this.modifier
  }
  getBourse(){
    this.data$ = this.service.getAll().pipe(
      map(data =>{
        this.data=data;
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
    );
  }
  editBourse(){
    this.submitted = true;
    console.log(this.Form.value)
      this.service.update(this.Form.value).subscribe(data => {
        this.submitted = false;
        this.change();
        this.ngOnInit();
        this.toastr.success('parametre modifié avec succés', 'Success');

      },
      (error)=>{
        this.submitted = false;
        this.toastr.error('Nous renvontrons une erreur', 'Error');
      })
  }

}
