import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeQuestionService } from '@services/type-question.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith, catchError, of } from 'rxjs';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss']
})
export class QuestionComponent {
  prepare: any;
  title: string;
  visiblebar: boolean = false;
  data: any;
  selected:any;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  err: any;
  submitted: boolean = false;
  value: boolean = false;
  val: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
    Form:any;
   
  constructor(private modalService: NgbModal, private service: TypeQuestionService,private toastr: ToastrService,){}
  ngOnInit() {
  
    this.get();
  }
  open1() {
    this.prepare = {};
    this.title = "Ajouter un type de question";
    this.visiblebar = true;
   }
   edit(item: any){
    this.prepare = {...item};
    this.title = "Modifier un type de question";
    this.visiblebar = true;
  }
 
     get(){
    console.log('ok')
    this.data$ = this.service.getAll().pipe(
      map(data =>{
        this.data=data;
        console.log(this.data);
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err}))
    );
   }
  
  
  
}
