import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
 import { ToastrService } from 'ngx-toastr';
 import { AppDataState, DataStateEnum } from '@/State/load.state';
 import { catchError, map, Observable, of, startWith } from 'rxjs';
 import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegionsService } from '@services/regions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-divisions',
  templateUrl: './divisions.component.html',
  styleUrls: ['./divisions.component.scss'],
  providers: [ConfirmationService]
})
export class DivisionsComponent implements OnInit{
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  Form: FormGroup
  submitted: boolean = false;
  departements: any[] | undefined;

   visibleSidebar: boolean = false;
   visibleSidebar1: boolean = false;
   visibleSidebar2: boolean = false;
    selectedRegions: any[];
     data: any;
    representatives: any[];
  val:any;
  err: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    specialite: any;
    prepare: any;
    title: string = "";
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
  nameInvalidError: any;
  codeInvalidError: any;
    get libelle(){return this.Form.get('libelle'); }
    get code(){return this.Form.get('code'); }
    constructor(private modalService: NgbModal,private confirmationService: ConfirmationService,private toastr: ToastrService, private service: RegionsService) {}

      confirm(content) {
          this.confirmationService.confirm({
            message: 'Are you sure you want to delete the selected region?',
            accept: () => {
              this.toastr.error('La region a ete supprimer avec sucess', 'Success');
              content.close();
              this.ngOnInit();

          }

          });
      }
      open() {
        this.prepare = {};
        this.submitted = false;
        this.title='Ajouter une region';
        this.visibleSidebar = true;


	     }
       edit(item: any){
        this.prepare = {...item};
        this.title='Modifier la region'
        this.visibleSidebar = true;
      }
       opens(content,value:any) {
        this.modalService.open(content, { centered: true })
        this.val= {...value};

    
      }
      close(){
        this.err= "";
        this.modalService.dismissAll()      }

      editRegion(){
        this.toastr.success('La region a ete modifier avec sucess', 'Success');
              this.visibleSidebar = false;

      }


      saveRegion(){}



    ngOnInit(){
      this.Form= new FormGroup({
        "id": new FormControl(""),
      "libelle": new FormControl("", [Validators.required]),
      "code": new FormControl("", [Validators.required,Validators.minLength(2)])
      })
      this.item2 = [
        {label:'Division',},
       
        
    ];
    this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
    this.get();

  }
 get(){
  this.data$ = this.service.getAll().pipe(
    map(data =>{
      this.data=data;
      console.log(this.data);
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );
 }

 delete(){
  this.err='';
  this.submitted = true;
    this.service.delete(this.val).subscribe(data => {
      this.submitted = false;
      this.modalService.dismissAll();
      this.ngOnInit();
      this.toastr.success('parametre supprimé avec succés', 'Success');

    },
    (error)=>{
      this.err= error;
      this.submitted = false;
      this.toastr.error('Nous rencontrons une erreur', 'Error');
    })
 }
 update(){
  this.submitted = true;
  console.log(this.Form.value)
    this.service.update(this.Form.value).subscribe(data => {
      this.submitted = false;
      this.visibleSidebar = false;
      this.ngOnInit();
      this.toastr.success('parametre modifié avec succés', 'Success');

    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Nous renvontrons une erreur', 'Error');
    })
 }
 add(){
  this.submitted = true;
  if( this.Form.valid){
    this.service.create(this.Form.value).subscribe((result)=>{
     this.submitted = false;
     this.visibleSidebar = false;

      this.ngOnInit();
      this.toastr.success('Région ajouté avec succées', 'Success');

    },
    (error)=>{
     this.submitted = false;
     this.toastr.error('Nous renvontrons une erreur', 'Error');

    }
    )
  }
 }
}
