<section class="content main content-wrapper">

    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
            <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
                <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                    <app-spinner></app-spinner>
                </ng-container>
                <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                </ng-container>
                <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                    <div class="d-flex justify-content-between" style="width: 100%;height: 30px;">
                        <div>
                            <div class="text-secondary pt-4 pl-4 pb-2">
                                <h4>LISTE DES SPECIALITES</h4>

                            </div>
                        </div>
                        <div>
                            <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

                        </div>

                    </div>
                    <div class="d-flex justify-content-between" style="width: 100%;padding: 20px;">
                        <div></div>
                        <div>
                            <button pButton pRipple (click)="open()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin-right: 10px;margin-top: 30px;"></button>

                        </div>


                    </div>
                    <div class="card">



                        <p-table styleClass="p-datatable-striped" #dt [value]="values" [(selection)]="selected" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [filterDelay]="0" [globalFilterFields]="[
              'index',
              'libelle',
              'code',
          ]">
                            <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                                <div class="table-header col-12 d-flex">

                                    <div class="col d-flex justify-content-between align-content-center align-items-center">
                                        <div>
                                            <span class="p-input-icon-left mr-4">
                             <i class="pi pi-search"></i>
                             <input
                                 pInputText
                                 type="text"
                                 (input)="
                                     dt.filterGlobal(
                                         $event.target.value,
                                         'contains'
                                     )
                                 "
                                 placeholder="Rechercher"
                             />
                         </span>
                                        </div>
                                        <div class="test">
                                            <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                                                <p-button ngbDropdownAnchor id="dropdownManual" (click)="myDrop.open()" label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>

                                            </div>
                                            <div class="d-inline-block" ngbDropdown #myDrop1="ngbDropdown">
                                                <p-button ngbDropdownAnchor type="button" id="dropdownManual1" (click)="myDrop1.open()" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header" style="background-color: #2E394B !important" let-columns>
                                <tr style="width: 5% !important;">
                                    <th pSortableColumn="index" class="col" style="width: 10% !important;">
                                        <div class="flex justify-content-between align-items-center">
                                            #

                                        </div>
                                    </th>
                                    <th style="width: 15% !important;" pSortableColumn="name" class="col">

                                        LIBELLE
                                    </th>
                                    <th style="width: 15% !important;" pSortableColumn="code" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            CODE
                                        </div>
                                    </th>

                                    <th style="width: 15% !important;" pSortableColumn="" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            FILIERE
                                        </div>
                                    </th>
                                    <th style="width: 15% !important;" pSortableColumn="" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            SECTEUR
                                        </div>
                                    </th>

                                    <th style="width: 15% !important;" pSortableColumn="" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            ACTIONS
                                        </div>
                                    </th>

                            </ng-template>
                            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-rowgroup="rowgroup" let-rowspan="rowspan">
                                <tr class="p-selectable-row">
                                    <td>{{rowIndex + 1}}</td>
                                    <td routerLink="/detail-formation/{{item.id}}">
                                        <span style="color: rgb(4, 20, 20);"><i class="pi pi-eye"></i> </span> {{item.libelle}}
                                        <td>
                                            {{item.code}}
                                        </td>

                                        <td>
                                            {{item.filiere.libelle}}
                                        </td>
                                        <td>
                                            {{item.filiere.secteurActivite.libelle}}
                                        </td>

                                        <td>
                                            <div class="row d-flex test">
                                                <div class="col-5">
                                                    <p-button (click)="edit(item)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                                </div>
                                                <div class="col-5">
                                                    <p-button (click)="opens(content, item)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                                                </div>
                                            </div>

                                        </td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="6" style="text-align: center;">Aucune specilaité trouvée</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </ng-container>
            </ng-container>

        </main>
    </p-scrollPanel>
</section>
<p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar1" [baseZIndex]="10000">

    <ng-template pTemplate="header">
        <h4>{{title}}</h4>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="container">
            <div class="container">
                <form action="" [formGroup]="Form">
                    <span class="error fs-6 text-danger mx-2 my-2 ">{{message}}</span>
                    <div class="col-12 field">
                        <input *ngIf="modifier===true" [(ngModel)]="formation.id" type="text" style="display:none" pInputText formControlName="id" name="id" />
                        <div class="col-12 field">

                            <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                                <label class="form-label" for="form3Example3">libelle<span class="text-danger me-1">*</span></label>
                                <div class="input-group">
                                    <input type="text" formControlName="libelle" name="libelle" placeholder="Entrer le libelle" [(ngModel)]="formation.libelle" class="form-control" required />
                                </div>
                                <div *ngIf="name.invalid && (name.touched || name.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="name.errors?.required ">*le libelle est obligatoire</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 field ">
                            <div>
                                <div class="input-field " [class.error]="code.invalid && code.hasError( 'required', 'type') ">
                                    <label for="pass " class="form-label ">Code<span class="text-danger me-1 ">*</span></label>
                                    <div class="input-group">
                                        <input type="text" formControlName="code" name="code" placeholder="Entrer le code" class="form-control" [(ngModel)]="formation.code" required />
                                    </div>
                                    <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                    <div *ngIf="code.invalid && (code.touched || code.dirty) ">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.required ">*le code est obligatoire</span>
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.minlength ">*le code doit avoir au moins 2 charactéres</span>

                                    </div>
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 field">
                            <div class="input-field" [class.error]="filiere.invalid && filiere.hasError('required','type')">
                                <label class="form-label" for="form3Example3">Filiere<span class="text-danger me-1">*</span></label>
                                <div class="input-group">
                                    <select *ngIf="modifier===true" name="filiereId" formControlName="filiereId" class="form-control" [(ngModel)]="formation.filiere.id" required>
                            <option *ngFor="let item of filieres;" value="{{item.id}}">{{item.libelle}}</option>
                        </select>
                                    <select *ngIf="modifier===false" name="filiereId" formControlName="filiereId" class="form-control" required>
                            <option *ngFor="let item of filieres;" value="{{item.id}}">{{item.libelle}}</option>
                        </select>
                                </div>
                                <div *ngIf="filiere.invalid && (filiere.touched || filiere.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="filiere.errors?.required ">*la filiere est obligatoire</span>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 field">
                            <div class="input-field" [class.error]="referentiel.invalid && referentiel.hasError('required','type')">
                                <label class="form-label" for="form3Example3">Referentiel<span class="text-danger me-1">*</span></label>
                                <div class="input-group">
                                    <select *ngIf="modifier===true" name="referentielFormationId" formControlName="referentielFormationId" class="form-control" [(ngModel)]="formation.referentielFormation.id" required>
                            <option *ngFor="let item of referentiels;" value="{{item.id}}">{{item.libelle}}</option>
                        </select>
                                    <select *ngIf="modifier===false" name="referentielFormationId" formControlName="referentielFormationId" class="form-control" required>
                            <option *ngFor="let item of referentiels;" value="{{item.id}}">{{item.libelle}}</option>
                        </select>
                                </div>
                                <div *ngIf="referentiel.invalid && (referentiel.touched || referentiel.dirty) ">
                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="referentiel.errors?.required ">*le refentiel est obligatoire</span>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 field">
                            <div class="input-field">
                                <label class="form-label" for="form3Example3">Description</label>
                                <div class="input-group">
                                    <textarea formControlName="description" name="description" [(ngModel)]="formation.description" class=" form-control"></textarea>
                                </div>

                            </div>
                        </div>



                    </div>
                    <div class="d-flex align-items-center justify-content-center " style="margin-top: 50px; ">
                        <button pButton pRipple type="button " (click)="visibleSidebar1=false " label="Annuler " class="p-button-raised p-button-secondary " style="margin-right: 10px; "></button>

                        <button pButton pRipple type="button " label="Ajouter " class="p-button-raised " (click)="Add() " *ngIf="modifier===false " [disabled]="submitted "><i class="pi pi-spin pi-spinner " *ngIf="submitted==true "></i></button>
                        <button pButton pRipple type="button " label="Modifier " class="p-button-raised " (click)="Update() " *ngIf="modifier===true " [disabled]="submitted "><i class="pi pi-spin pi-spinner " *ngIf="submitted==true "></i></button>
                    </div>
                </form>

            </div>

        </div>

    </ng-template>




</p-sidebar>
<ng-template #content let-modal>
    <div class="modal-header ">
        <h5 class="modal-title " id="modal-basic-title ">Voulez vous supprimer cet specialite?</h5>
        <button type="button " class="btn-close " aria-label="Close " (click)="modal.dismiss( 'Cross click') "></button>
    </div>
    <div class="modal-body ">
        <div class="mb-3 ">
            Cette action est irreversible
        </div>
        <span class="error fs-6 text-danger mx-2 my-2 ">{{message}}</span>
    </div>
    <div class="modal-footer ">
        <button type="button " pButton class="p-button-secondary " (click)="modal.close( 'Save click') " label="Annuler "></button>
        <button type="button " [disabled]="submitted " (click)="delete() " pButton class="p-button-danger " label="Supprimer "><i class="pi pi-spin pi-spinner " *ngIf="submitted==true "></i></button>

    </div>
</ng-template>