import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-infos-formateurs',
  templateUrl: './infos-formateurs.component.html',
  styleUrls: ['./infos-formateurs.component.scss']
})
export class InfosFormateursComponent implements OnInit{
  values1:any;

  ngOnInit(): void {
   
 }}