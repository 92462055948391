<ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
        <app-spinner></app-spinner>
    </ng-container>
    <div class="d-flex justify-content-between" style="width: 100%;padding-bottom: 10px;">
        <div>
            <button pButton pRipple type="button" label="Ajouter une question" icon="pi pi-plus" class="p-button" style="margin-right: 10px;"></button>
            <button pButton pRipple type="button" label="Ajouter plusieur question" icon="pi pi-plus" class="p-button" style="margin-right: 10px;"></button>
        </div>
        <div>

            <p-button label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
            <p-button type="button" id="dropdownManual1" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
        </div>
    </div>
    <p-accordion [activeIndex]="0">
        <p-accordionTab *ngFor="let item of data" header="{{item.numero}}  {{item.libelle}}">
            <app-question-par-type [type]="item"></app-question-par-type>
        </p-accordionTab>
    </p-accordion>
</ng-container>