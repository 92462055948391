import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit{
  //userInformation
  userInformation!: any;
  //variable for validate form
  submitted: boolean = false;
  //error variable
  err:any;
  constructor(private router:Router) { }
  get name(){return this.userInformation.get('name'); }
  get email(){return this.userInformation.get('email'); }
  get password(){return this.userInformation.get('password'); }
  get confirmPassword(){return this.userInformation.get('confirmPassword'); }

  ngOnInit(): void {
    this.userInformation= new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "email": new FormControl("", [Validators.required,Validators.email]),
      "password": new FormControl("", [Validators.required]),
      "confirmPassword": new FormControl("", [Validators.required]),

    })
  }
  adduser(){

  }


  private validateform(form: FormGroup ){
    Object.keys(form.controls).forEach(field =>{
      const controls =  form.get(field);
      if(controls instanceof FormControl){
        controls.markAsDirty({onlySelf: true});

      }else if(controls instanceof FormGroup){
        this.validateform(controls)
      }
    })

  }
}
