import { Component } from '@angular/core';
import { Location } from '@angular/common'
import {ConfirmationService} from 'primeng/api';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detail-secteur-porteur',
  templateUrl: './detail-secteur-porteur.component.html',
  styleUrls: ['./detail-secteur-porteur.component.scss'],
  providers: [ConfirmationService]
})
export class DetailSecteurPorteurComponent {
  value: boolean = false;
  submitted: boolean = false;
  specialites: any[] | undefined;
  specialite: any;
  title: string = "";
   visibleSidebar2: boolean = false;
   selectedspecialite: any;
    representatives: any[];
    item2: any[];
    home: any;
    statuses: any[];
 
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    constructor(private confirmationService: ConfirmationService,private location: Location,private toastr: ToastrService) {}
    back(){
      this.location.back();
    }
    confirm(item,content) {
        this.confirmationService.confirm({
          message: 'Voulez vous supprimez cette specialite?',
          accept: () => {
            this.toastr.error('la specialite a ete supprimer avec sucess', 'Success');
            content.close();
            this.ngOnInit();
           
        }
        });
    }
    open() {
      this.specialite= {};
      this.submitted = false;
      this.title = "Ajouter un arrondissement";
      this.visibleSidebar2 = true;
     }
     edit(item: any){
      this.specialite = {...item};
      this.title = "Modifier un arrondissement";
      this.visibleSidebar2 = true;
    }
  
    
    saveRegion(){
      this.toastr.success('La specialite a ete ajouter avec success au secteur', 'Success');
      this.visibleSidebar2 = false;
    }
      

    
  ngOnInit(){
    this.item2 = [
      {label:'Secteurs',routerLink: ['/dashboard/gestion-formation/secteurPorteur']},
      {label:'Detail du secteur'},
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
   this.specialites  = [
     { 'index': 1 ,name: 'Australia', code: 'AU','commune':10},
     {'index': 2, name: 'Canada', code: 'CA','commune':10},
     {'index': 3, name: 'Burkina', code: 'BF','commune':10},
    { 'index': 4,name: 'Cameroon', code: 'CM','commune':10},
     
 ];



}
}
