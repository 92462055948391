import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component, Input, OnInit } from '@angular/core';
import { ApprenantBySpecialiteService } from '@services/apprenant-by-specialite.service';
import { EtatCanditatsService } from '@services/etat-canditats.service';
import { TypeFormationService } from '@services/typeFormationService';
import { Tooltip } from 'chart.js';
import { Observable, catchError, map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-infos-apprenants',
  templateUrl: './infos-apprenants.component.html',
  styleUrls: ['./infos-apprenants.component.scss']
})
export class InfosApprenantsComponent implements OnInit{
  @Input() id: any;
  data: any;
  data$: Observable<AppDataState<any[]>> |null = null;
  types:any;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  effectifParSexe: any;
  table:any[]=[];
  sexes:any[]=[];
  constructor(private service1:ApprenantBySpecialiteService,
    private typeFormation: TypeFormationService,
    private state: EtatCanditatsService){}
  ngOnInit(): void {
      console.log(this.id);
      this.get();
      this.getTypeFormation();
  }
  get(){
    console.log('ok')
    this.data$ = this.service1.getById(this.id).pipe(
      map(data =>{
        this.data=data;
        this.iterate1(data);

        console.log(this.data)
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err}))
    );
   }
   getTypeFormation(){
    console.log('ok')
    this.typeFormation.getAll().subscribe(data => {
      this.types = data;
     },
     (error) => {
     });
   }
   iterate1(data:any){  
    console.log(data)
   data.forEach(element => {
    let tab = [];
    element.effectifs.forEach(item => {
      tab.push(item.m)
      tab.push(item.f)
    });
    this.table.push(tab);
   });
   console.log(this.table)
   }
   iterate2(data:any){  
    console.log(data)
   data.forEach(element => {
    this.sexes.push("H")
      this.sexes.push("F")
    
   });
   console.log(this.table)
   }
  
 }