import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class FiliereService extends BaseService<any> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "formations/filieres"
    );
}


}
