<section class="content main content-wrapper">

    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <div class="col-12 d-flex justify-content-between align-items-center pt-10">
            <div class="p-0">
                <h4>
                    DETAILS DE LA STRUCTURE
                </h4>
            </div>
            <div>
                <p-breadcrumb [model]="item2" [home]="home"></p-breadcrumb>
            </div>

        </div>

        <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">

            <div class="row">

                <div class="col-md-3" style="margin-left: 0px !important;">

                    <p-menu [model]="items"></p-menu>
                </div>
                <div class="col-md-9">

                    <app-infos-generale *ngIf="value == 1"></app-infos-generale>
                    <app-identification *ngIf="value == 2"></app-identification>
                    <app-infos-formateurs *ngIf="value == 3"></app-infos-formateurs>
                    <app-infos-apprenants *ngIf="value == 4" [id]="id"></app-infos-apprenants>
                    <app-infos-manuels *ngIf="value == 5"></app-infos-manuels>
                    <app-infos-orientation *ngIf="value == 6"></app-infos-orientation>
                    <app-infos-suivie-formation *ngIf="value == 7"></app-infos-suivie-formation>
                    <app-infos-vih-violence *ngIf="value == 8"></app-infos-vih-violence>
                    <app-infos-difficulte *ngIf="value == 9"></app-infos-difficulte>



                </div>

            </div>




        </main>
    </p-scrollPanel>
</section>