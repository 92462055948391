
<ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
  <ng-container *ngSwitchCase="DataStateEnum.LOADING">
      <app-spinner></app-spinner>
  </ng-container>
  <ng-container *ngSwitchCase="DataStateEnum.ERROR">
  </ng-container>
  <ng-container *ngSwitchCase="DataStateEnum.LOADED">
    <form [formGroup]="Form">
    <ul class="list-none p-0 m-0">
      <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-2 font-medium">Noms</div>
          <input *ngIf="modifier===true" [(ngModel)]="data.id" type="text" style="display:none" pInputText formControlName="id"/>

          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">

            <p *ngIf="modifier===false"> {{data.name}}</p>
            <input *ngIf="modifier===true" [(ngModel)]="data.name" type="text" style="outline:none !important" pInputText formControlName="name"/>

          </div>

      </li>
      <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-2 font-medium">Email</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">

              <p *ngIf="modifier===false"> {{data.email}}</p>
              <input *ngIf="modifier===true" [(ngModel)]="data.email" type="text" style="outline:none !important" pInputText formControlName="email"/>

          </div>

      </li>
      <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-2 font-medium">SiteWeb</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
            <p *ngIf="modifier===false">
              {{data.siteweb}}
            </p>
            <input *ngIf="modifier===true" [(ngModel)]="data.siteweb" type="text" style="outline:none !important" pInputText formControlName="siteweb"/>


          </div>

      </li>
      <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">


            <p *ngIf="modifier===false">{{data.telephone}}</p>
            <input *ngIf="modifier===true" [(ngModel)]="data.telephone" type="text" style="outline:none !important" pInputText formControlName="telephone"/>

          </div>

      </li>
      <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
          <div class="text-500 w-6 md:w-2 font-medium">adresse</div>
          <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">

            <p *ngIf="modifier===false">
              {{data.adresse}}
            </p>
               <input *ngIf="modifier===true" [(ngModel)]="data.adresse" type="text" style="outline:none !important" pInputText formControlName="adresse"/>

              </div>

      </li>
    </ul>
    <div class="col-12 field d-flex justify-content-center align-center">
      <div  *ngIf="modifier===false">
      <button type="button" (click)="change()" class="btn btn-secondary">Modifier <i class="pi pi-pencil"></i>
      </button>
    </div>
    <div class="d-flex"  *ngIf="modifier===true">
      <button type="button" (click)="change()" class="btn btn-secondary mr-2">Annuler <i class="pi pi-times"></i>
      </button>
      <button type="button" [disabled]="submitted" (click)="editBourse()" class="btn btn-primary">Enregistrer <i class="pi pi-check" *ngIf="!submitted" ></i><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i>

      </button>
    </div>
  </div>
  </form>
  </ng-container>
</ng-container>
