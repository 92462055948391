import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Parameter } from '../models/parameter';
import { BaseService } from './base.service';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TypeStructureService extends BaseService<any> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "structures/typeStructues"
    );
}


}
