<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <div class="col-12 d-flex">
            <div class="col text-align-end justify-content-end align-item-end d-flex">
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>

        <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
            <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.ERROR">
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                <div class="container d-flex col-12 p-4 pb-0 pt-0">
                    <section class="userDetails card col-12">
                        <div class=" surface-section">
                            <div class="font-medium text-900 mb-3">
                                <h4>Details des qualification formation</h4>
                            </div>
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Specialite</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.specialite}}</div>

                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Type de formation</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <p-chip label="{{data.typeFormation.libelle}}" class="mr-2"></p-chip>

                                    </div>

                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Diplome de qualificaion obtenue</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.diplomeQualification.libelle}}</div>

                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Niveau scolaire requis</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.niveauEtude.libelle}}</div>

                                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <div class="text-500 w-6 md:w-2 font-medium">Diplome Requis</div>
                                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.diplome.libelle}}</div>

                                    </li>
                                    <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                        <div class="text-500 w-6 md:w-2 font-medium">Duree minimum de formation</div>
                                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                            {{data.duree}}</div>

                                    </li>
                            </ul>
                            <div class="col-12 d-flex justify-content-center p-3">
                                <p-button label="Modifier" icon="pi pi-pencil" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                <p-button label="Supprimer" icon="pi pi-trash" (click)="opens(content,id)" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>

                            </div>
                        </div>
                    </section>

                </div>
            </ng-container>
        </ng-container>






    </p-scrollPanel>

</section>
<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Voulez vous supprimer cet qualification pour formation?</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            Cette action est irreversible
        </div>
        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="err ">*{{err?.message}}.</span>

    </div>
    <div class="modal-footer">
        <button type="button" pButton class="p-button-secondary" (click)="close()" label="Annuler"></button>
        <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

    </div>
</ng-template>