<div class="card">
    <div class="container">
        <div class="surface-section">
            <div class="font-medium text-800 mb-3">
                <h5 style="padding-top: 10px;">INFORMATIONS SUR LES FORMATEURS</h5>
            </div>
            <p-accordion [activeIndex]="0">
                <p-accordionTab header="Effectif des formateurs par diplome academique">
                    <table class="table table-condensed table-bordered">
                        <thead>
                            <tr>
                                <th>Diplome Academique</th>
                                <th>Masculin</th>
                                <th>Feminin</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>DOCTORAT/PHD</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>MASTER II/DEA/DESS</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>MAITRISE/MASTER I</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>LICENCE/BACHELOR</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>DEUG/DUT/General University Diploma/University Technology Diploma</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>BAC General/General GCE A LEVEL</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>BAC Technique/Technical GCE A LEVEL</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>PROBATOIRE/FORM 6</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>BEPC/GCE O LEVEL</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>CEP OU CEPE/ F.S.L.C</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Sans diplome academique/No academique qualification</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </p-accordionTab>
                <p-accordionTab header="Effectif des formateurs par diplome professionnel">
                    <table class="table table-condensed table-bordered">
                        <thead>

                            <tr>
                                <th>Diplome Professionnelle</th>
                                <th>Homme</th>
                                <th>Femme</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tbody>
                                <tr>
                                    <td>DIPLEG, DIPES II/DIPES, CAPES, DIPLET,DIPET II,DIPCO,DIPENI </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>INGENIEUR/MASTER PRO</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>DIPCEG, DIPES I/DIPCEG, DIPCET, DIPET I,CAPCEG,DIPENIA, ING TRAVAUX</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>LICENCE PRO/Professionnal Bachelor's degree</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>BTS/HND</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>BEP, BP, BACC PRO,BT/Professional GCE-A</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>CAPIEG, CAPIEMP, CAPI, CAPIET OU EQUIVALENT/ or equivalent certificate</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>CAPIAEG, CAPIA, CAPIAET OU EQUIVALENT/ or equivalent certificate</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>CAP/certicate of Professional competence</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>DQP/VQP</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>CQP/VQC</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Autres/other</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Sans diplome/No vocational qualification</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>TOTAL</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </tbody>

                    </table>

                </p-accordionTab>
                <p-accordionTab header="Effectif des formateurs par tranche d'age">
                    <table class="table table-condensed table-bordered">
                        <thead>

                            <tr>
                                <th>Tranche d'age</th>

                                <th> Homme
                                </th>
                                <th>Femme</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>De 18 a 24 ans/ From 18 and 24 years</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>De 25 a 39 ans/ From 25 and 39 years</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>De 40 a 59 ans/ From 40 and 59 years</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>De 60 ans et plus/ 60 years and above</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </p-accordionTab>
                <p-accordionTab header="Effectif des formateurs par specialite de formation et type de formation">
                    <table class="table table-condensed table-bordered">
                        <thead>

                            <tr>
                                <th rowspan="2">Specialites</th>
                                <th colspan="2" scope="colgroup">Formation Initial(FI)</th>
                                <th colspan="2" scope="colgroup">Formation Continue(CT)</th>
                                <th colspan="2" scope="colgroup">Formaion par apprentissage(AT)</th>
                            </tr>
                            <tr>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>

                            </tr>
                        </tbody>
                    </table>
                </p-accordionTab>
                <p-accordionTab header="Effectif des formateurs par statut professionnel">
                    <table class="table table-condensed table-bordered">
                        <thead>

                            <tr>
                                <th rowspan="2">Statut</th>
                                <th colspan="2" scope="colgroup">Effectif</th>
                                <th rowspan="2">Total</th>
                            </tr>
                            <tr>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Formateurs vacataires professionnels/Part-time vocational trainers</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Formateurs vacataires non professionnels/Part-time non vocational trainers</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Formateurs permanents/Permanent trainers</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </p-accordionTab>
                <p-accordionTab header="Effectif des formateurs par type de handicape">
                    <table class="table table-condensed table-bordered">
                        <thead>

                            <tr>
                                <th>Type d'handicape</th>

                                <th> Homme
                                </th>
                                <th>Femme</th>
                                <th>Total</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>Hadicape Moteurs(ou physique)/ Mobility (phyical) impairment</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Aveugle ou mal voyant/Visaul impairement</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Sourd muet/ Hearing impairement</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Autres</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </p-accordionTab>
                <p-accordionTab header="Effectif des personelle administartif">
                    <table class="table table-condensed table-bordered">
                        <thead>

                            <tr>
                                <th>Qualite</th>

                                <th> Homme
                                </th>
                                <th>Femme</th>
                                <th>Total</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>
                                <td>personelle Administratif enseignant/Administrative Teaching staff</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>

                            <tr>
                                <td>personelle Administratif non enseignant/Administrative Non Teaching staff</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Total</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                </p-accordionTab>
                <p-accordionTab header="Capacite d'accueil">
                    <table class="table table-condensed table-bordered">
                        <thead>

                            <tr>
                                <th rowspan="2">Specialites</th>
                                <th colspan="2" scope="colgroup">Formation Initial(FI)</th>
                                <th colspan="2" scope="colgroup">Formation Continue(CT)</th>
                                <th colspan="2" scope="colgroup">Formaion par apprentissage(AT)</th>
                            </tr>
                            <tr>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                                <th scope="col">Homme</th>
                                <th scope="col">Femme</th>
                            </tr>

                        </thead>
                        <tbody>
                            <tr>

                            </tr>
                        </tbody>
                    </table>

                </p-accordionTab>
            </p-accordion>
        </div>
    </div>

</div>