import { Component } from '@angular/core';
import { Location } from '@angular/common'
import { ConfirmationService } from 'primeng/api';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Observable, catchError, map, of, startWith } from 'rxjs';
import { StructureService } from '@services/structure.service';

@Component({
  selector: 'app-structures',
  templateUrl: './structures.component.html',
  styleUrls: ['./structures.component.scss'],
  providers: [ConfirmationService]
})
export class StructuresComponent {
  data: any;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  submitted: boolean = false; 
  val: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    regions: any;
    constructor(private service: StructureService) {}
   saveFormation(){}
      

    
  ngOnInit(){
    this.item2 = [

      {label:'Structure'},
      /*{label:'Listes des Formations', routerLink: ['/dashboard/gestion-formation/formation']},*/
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/Dashboard'};
  this.regions = [
    { 'index': 1 ,'name': 'Kamer Consulting', 'code': 'DA','type':'CPF','ville':'Douala'},
    { 'index': 2 ,'name': 'Centre Proffesionelle', 'code': 'CPT','type':'CPF','ville':'Edea', },
   ];
this.get();
}
get(){
  console.log('ok')
  this.data$ = this.service.getAll().pipe(
    map(data =>{
      this.data=data;
      console.log(this.data);
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err}))
  );
 }
 getStatus(value: boolean){
  if(value){
    return "Fonctionnel";
  }
  else{
    return "Non Fonctionnel";
  }
 }

}
