import { Component, OnInit } from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

@Component({
  selector: 'app-personnel',
  templateUrl: './personnel.component.html',
  styleUrls: ['./personnel.component.scss'],
  providers: [ConfirmationService]
})
export class PersonnelComponent implements OnInit {
  customers: any[];
  visibleSidebar: boolean = false;
   selectedCustomers: any[];

   representatives: any[];

   statuses: any[];
   items: any[];
   home: any;
   loading: boolean = true;

   activityValues: number[] = [0, 100];
   constructor(private confirmationService: ConfirmationService) {}


     ngOnInit(){
      this.items = [

        {label:'Personnels'},
        {label:'Liste des Personnels'}
    ];
    this.home = {icon: 'pi pi-home', routerLink: '/'};

      this.customers = [
        {
          id: 1000,
          name: "James Butt",
          country: {
            name: "Algeria",
            code: "dz"
          },
          company: "Benton, John B Jr",
          date: "2015-09-13",
          status: "unqualified",
          activity: 17,
          representative: {
            name: "Ioni Bowcher",
            image: "ionibowcher.png"
          }
        }

      ]
      this.representatives = [
          {name: "Amy Elsner", image: 'amyelsner.png'},
          {name: "Anna Fali", image: 'annafali.png'},
          {name: "Asiya Javayant", image: 'asiyajavayant.png'},
          {name: "Bernardo Dominic", image: 'bernardodominic.png'},
          {name: "Elwin Sharvill", image: 'elwinsharvill.png'},
          {name: "Ioni Bowcher", image: 'ionibowcher.png'},
          {name: "Ivan Magalhaes",image: 'ivanmagalhaes.png'},
          {name: "Onyama Limba", image: 'onyamalimba.png'},
          {name: "Stephen Shaw", image: 'stephenshaw.png'},
          {name: "Xuxue Feng", image: 'xuxuefeng.png'}
      ];

      this.statuses = [
          {label: 'Unqualified', value: 'unqualified'},
          {label: 'Qualified', value: 'qualified'},
          {label: 'New', value: 'new'},
          {label: 'Negotiation', value: 'negotiation'},
          {label: 'Renewal', value: 'renewal'},
          {label: 'Proposal', value: 'proposal'}
      ]
    }


generatePdf(){
  const doc = new jsPDF();
  autoTable(doc, {
    body: [
      [
        {
          content: 'Mincontrol',
          styles: {
            halign: 'left',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        },
        {
          content: 'KTC-MANAGER' ,
          styles: {
            halign: 'right',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
    styles: {
      fillColor: '#3366ff'
    }
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
      ]
    ]
  });
  autoTable(doc, { html: '#my-table' })

  doc.save('table.pdf')

}
printPdf(){
  const doc = new jsPDF();
  autoTable(doc, {
    body: [
      [
        {
          content: 'Mincontrol',
          styles: {
            halign: 'left',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        },
        {
          content: 'KTC-MANAGER' ,
          styles: {
            halign: 'right',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
    styles: {
      fillColor: '#3366ff'
    }
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
      ]
    ]
  });
  autoTable(doc, { html: '#my-table' })

}
}
