<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
    <div class="col-12 d-flex">
      
          <div class="col text-align-end justify-content-end align-item-end d-flex">
      <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
    </div>
  </div>
  <div class="container d-flex col-12 p-4 pb-0 pt-0">
  
  
  
      

      <!-- ===== ===== User Details Sections ===== ===== -->
      <section class="userDetails card col-12">
  
  <div class="surface-section">
       
<ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
        <app-spinner></app-spinner>
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.ERROR">
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.LOADED">
    <div class="font-medium text-3xl text-900 mb-3">Attribution des autorisation au role {{data.libelle}}</div>
   

        <p-accordion>
            <p-accordionTab *ngFor="let role of data.modules" header="{{role.name}}" class="accordion-header">
                <p-table  [value]="role.permissions" [tableStyle]="{'min-width': '50rem'}">
                    <ng-template pTemplate="header" style="background-color: #2E394B !important">
                        <tr style="width: 10% !important;">
                            <th pSortableColumn="index"  style="width: 5% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    #
    
                                </div>
                            </th>
                            <th  pSortableColumn="name" class="col-9">
    
                        Permissions
    
                            </th>
                          
                            
                            <th pSortableColumn="country.name" class="col-3 ">
                                <div class="flex justify-content-between align-items-center">
                                   Status Permission
                                </div>
                            </th>
    
                    </ng-template>
                    <ng-template pTemplate="body"let-value let-rowIndex="rowIndex">
                        <tr>
                            <td>
                                {{rowIndex + 1}}
                            </td>
                            <td>
                                {{value.permission}}
                            </td>
                  
                            <td>
                            <div class=" d-flex">
                                <div >
                                    <p-checkbox [(ngModel)]="value.hasPermission" (onChange)="update(value.id,$event)" [binary]="true" inputId="binary"></p-checkbox>

                                  
                                </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
         
        </p-accordion>
    </ng-container>
  </ng-container>

  </div>
  
      </section>
  
  
  
  </div>
  </p-scrollPanel>
  
  </section>
  