<div class="card">
    <div class="container">
        <p-tabView>
            <p-tabPanel header="IDENTIFICATION ET LOCALISATION">
                <div class="surface-section">

                    <ul class="list-none p-0 m-0">
                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Nom du CFP </div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.name}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Sigle</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.sigle}}</div>
                                </li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Localisation du siege </div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.ville}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Localisation du siege </div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.ville}}</div>
                                </li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Localisation du siege </div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.ville}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Coordonnees GPS du CFP </div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">
                                        <ul class="list-unstyled">
                                            <li>longitude: {{data.adresse.longitute}}</li>
                                            <li>latitude: {{data.adresse.latitude}}</li>
                                        </ul>
                                    </div>
                                </li>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Region </div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.arrondissement.departement.region.libelle}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Departement</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.arrondissement.departement.libelle}}</div>
                                </li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Arrondissement</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.arrondissement.libelle}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Commune</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.arrondissement.commune}}</div>
                                </li>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Village-Quatier</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.village}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Millieu d'implantation</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.milieuImplantation.libelleFR}}</div>
                                </li>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Boite postale</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.boitePostale}}</div>

                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Telephone</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.telephone}} {{data.adresse.telephone2}}</div>
                                </li>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Email</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.adresse.email}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Annee d'ouverture</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.anneeOuverture}}</div>
                                </li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Possede un agreement?</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{getAgrementStatus(data.estAgree)}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li *ngIf="data.estAgree" class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Annee du dernier agreement</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.anneeOuverture}}
                                    </div>

                                </li>
                            </div>
                        </div>


                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Posede un titre foncier?</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{getAgrementStatus(data.estTerrainTitre)}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Est locataire?</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{getAgrementStatus(data.estLocataire)}}</div>
                                </li>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Ordre d'enseignement</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.ordreStructure.libelleFR}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Promoteur du centre</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.typePromoteur.libelleFR}}</div>
                                </li>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Nom du Promoteur/Directeur</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.promoteur.personnel.nom}} {{data.promoteur.personnel.prenom}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Sexe du Promoteur/Directeur</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.promoteur.personnel.genre}}</div>
                                </li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Type de CFPI</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">{{data.typeStructure.libelleFR}}</div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-bodata.typeStructurerder flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Filieres de formations</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">
                                        <ul class="list-unstyled">
                                            <li *ngFor="let item of data.filieres">{{item.libelle}} ({{item.code}})</li>
                                        </ul>
                                    </div>
                                </li>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">

                                    <div class="text-600 w-6 md:w-4 font-medium">Secteur d'activite</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">
                                        <ul class="list-unstyled">
                                            <ul class="list-unstyled">
                                                <li *ngFor="let item of data.filieres">{{item.secteurActivite.libelle}}</li>
                                            </ul>
                                        </ul>
                                    </div>
                                </li>
                            </div>
                            <div class="col-md-6">
                                <li class="flex align-items-center py-2 px-2 border-top-1 surface-bodata.typeStructurerder flex-wrap justify-content-between">
                                    <div class="text-600 w-6 md:w-4 font-medium">Dispose d'une convention avec l'etat</div>
                                    <div class="text-900 w-full md:w-5 md:flex-order-0 flex-order-1">
                                        {{getAgrementStatus(data.disposeConventionEtat)}}
                                    </div>
                                </li>
                            </div>
                        </div>

                        <li class="flex align-items-center py-2 px-2 border-top-1 surface-bodata.typeStructurerder flex-wrap justify-content-between">
                            <div class="text-600 w-6 md:w-5 font-medium">liste des convention</div>
                            <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">

                                <ul *ngIf="data.disposeConventionEtat" class="list-unstyled">

                                </ul>
                            </div>
                        </li>


                        <li class="flex align-items-center py-2 px-2 border-top-1 surface-bodata.typeStructurerder flex-wrap justify-content-between">
                            <div class="text-600 w-5 md:w-5 font-medium">
                                <ul class="list-unstyled">
                                    <li>Qualite du repondant</li>
                                    <li>Nom du repondant</li>
                                    <li>Telephone du repondant</li>
                                    <li>Email du repondant's</li>
                                </ul>
                            </div>
                            <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">


                            </div>
                        </li>


                    </ul>
                </div>
            </p-tabPanel>
            <p-tabPanel header="LISTE DES SPECIALITE PAR FILIERES POUR L'ANNEE">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Filieres</th>
                            <th>Bon etats</th>

                        </tr>
                    </thead>


                    <tbody>
                        <tr>
                            <td>Tables bancs 2 places</td>
                            <td></td>

                        </tr>

                    </tbody>



                </table>

            </p-tabPanel>

        </p-tabView>
    </div>

</div>