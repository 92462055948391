<section class="content main content-wrapper">


  <main class="main-content position-relative max-height-vh-100 h-50 mt-1 border-radius-lg">
      <div class="d-flex justify-content-between" style="width: 100%;height: 60px;">
          <div>
              <div class="text-secondary pt-3 pl-4 pb-4">
                  <h4>
                     Paramétres
                  </h4>


              </div>
          </div>
          <div>
              <p-breadcrumb [model]="item" [home]="home" class="bg-transparent"></p-breadcrumb>

          </div>

      </div>
      <p-tabView>
          <p-tabPanel header="Paramétres Généraux">
            <app-parametre-generaux></app-parametre-generaux>
          </p-tabPanel>
          <p-tabPanel header="Sessions">
            <app-session></app-session>
          </p-tabPanel>

      </p-tabView>
  </main>
