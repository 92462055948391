<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
            <div class="d-flex justify-content-between" style="width: 100%;height: 30px;padding: 20px;">
                <div></div>
                <div>
                    <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

                </div>

            </div>

            <div class="card">
                <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
                    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                        <app-spinner></app-spinner>
                    </ng-container>
                    <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                        <div class="alert alert-danger" role="alert">
                            code : {{ result.errorMessage.code }} Nous rencontrons une erreur
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="DataStateEnum.LOADED">


                        <div class="surface-section">
                            <div class="font-medium text-3xl text-900 mb-3">Movie Information</div>
                            <div class="text-500 mb-5">Morbi tristique blandit turpis. In viverra ligula id nulla hendrerit rutrum.</div>
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Title</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Heat</div>
                                    <div class="w-6 md:w-2 flex justify-content-end">
                                        <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-text"></button>
                                    </div>
                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Genre</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <p-chip label="Crime" class="mr-2"></p-chip>
                                        <p-chip label="Drama" class="mr-2"></p-chip>
                                        <p-chip label="Thriller"></p-chip>
                                    </div>
                                    <div class="w-6 md:w-2 flex justify-content-end">
                                        <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-text"></button>
                                    </div>
                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Director</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Michael Mann</div>
                                    <div class="w-6 md:w-2 flex justify-content-end">
                                        <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-text"></button>
                                    </div>
                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Actors</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Robert De Niro, Al Pacino</div>
                                    <div class="w-6 md:w-2 flex justify-content-end">
                                        <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-text"></button>
                                    </div>
                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Plot</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                        A group of professional bank robbers start to feel the heat from police when they unknowingly leave a clue at their latest heist.</div>
                                    <div class="w-6 md:w-2 flex justify-content-end">
                                        <button pButton pRipple label="Edit" icon="pi pi-pencil" class="p-button-text"></button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                </ng-container>



            </div>



        </main>
    </p-scrollPanel>


</section>