import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit{
  constructor(private router:Router,private route:ActivatedRoute) { }
  passwords!: any;
  code:any;
  submitted = false;
  submited = false;

  err:any;
  get password(){return this.passwords.get('password'); }
  get confirmPassword(){return this.passwords.get('confirmPassword'); }


  ngOnInit(): void {
    this.code= this.route.snapshot.paramMap.get('code');
    this.passwords= new FormGroup({
      "passwordResetCode": new FormControl(this.code, [Validators.required]),
      "password": new FormControl("", [Validators.required]),
      "confirmPassword": new FormControl("", [Validators.required]),

    })
  }
  resetPassword(){

  }


  private validateform(form: FormGroup ){
    Object.keys(form.controls).forEach(field =>{
      const controls =  form.get(field);
      if(controls instanceof FormControl){
        controls.markAsDirty({onlySelf: true});

      }else if(controls instanceof FormGroup){
        this.validateform(controls)
      }
    })

  }
}
