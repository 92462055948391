<div class="card">
    <div class="container">
        <div class="container">
            <div class="surface-section">
                <div class="font-medium text-800 mb-3">
                    <h5 style="padding-top: 10px;">ORIENTATION PROFESSIONAL</h5>
                </div>
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-2 px-2 order-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-4 font-medium" style="margin-right: 30px;">Existence d'un service d'orientation Professionnelle dans votre centre?</div>
                        <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">Heat</div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-4 font-medium">prise en compte de l'orientation Professionnelle dans votre dispositif de formation Professionnelle</div>
                        <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            <ul class="list-unstyled">
                                <li>Avant la formation/Before training</li>
                                <li>Pendant la formation/Before training</li>
                                <li>Apres la formation/After training</li>
                            </ul>
                        </div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-4 font-medium">les apprenants choissisent t'il les filieres de formation avec l'accompagnement de ce service ? </div>
                        <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">Michael Mann</div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-4 font-medium">Sinon, collaborez-vous avec les structures publiques d'orientation existante a l'exemple du COSUP</div>
                        <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">Michael Mann</div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-4 font-medium">Quel accompagnement le service d'orientation assure aux apprenants</div>
                        <div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">
                            <ul class="list-unstyled">
                                <li>Orientation d'impregnation/Impregnation orientation</li>
                                <li>Orientation de consolidation /Consolidation Orientation</li>
                                <li>Orientaion d'insertion/ Insertion orientation</li>
                                <li>Suivie post-formation/ Post-trainning</li>
                                <li>Autres (a preciser)/ Others(to specify)</li>
                            </ul>
                        </div>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</div>