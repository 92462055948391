import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.scss']
})
export class SelectionComponent implements OnInit{
  constructor( ) { }
  rol!:any;
  ngOnInit(): void {
    this.role()
  }

  logout(){
    alert('bientot implémenté')
  }

role(){
}
pending(){
  alert("pas encore implémenté c'est en cours de developpement")
}
}
