import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-question-recolte-donnee',
  templateUrl: './question-recolte-donnee.component.html',
  styleUrls: ['./question-recolte-donnee.component.scss']
})
export class QuestionRecolteDonneeComponent implements OnInit{
  item2:any;
  home:any;
  ngOnInit() {
    this.item2 = [
      {label:'Questionaire de donnees',},
     
      
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
  }
  

}
