import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Observable, catchError, map, of, startWith } from 'rxjs';
import { ArrondissementService } from '@services/arrondissement.service';
import { DepartementService } from '@services/departements.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegionsService } from '@services/regions.service';
@Component({
  selector: 'app-all-arondissement',
  templateUrl: './all-arondissement.component.html',
  styleUrls: ['./all-arondissement.component.scss'],
  providers: [ConfirmationService]
})
export class AllArondissementComponent {
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  submitted: boolean = false;
  departements: any[] | undefined;
  communes: any[] | undefined;
  communeForm: any;
  prepare: any;
   visibleSidebar1: boolean = false;
   selectedDepartement: any;
    representatives: any[];
  title : string ="";
    statuses: any[];
 index: number = 0;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
  subscribError: any;
  nameInvalidError: any;
  codeInvalidError: any;
  modifier: Boolean = false;
  message: string;
  error: Boolean = false;
  get id(){return this.communeForm.get('id'); }
  get departement(){return this.communeForm.get('departementId'); }
  get name(){return this.communeForm.get('libelle'); }
  get commune(){return this.communeForm.get('commune'); }
  get code(){return this.communeForm.get('code'); }
    constructor(private confirmationService: ConfirmationService,private serviceCommune: ArrondissementService,private modalService: NgbModal,private toastr: ToastrService,private service: DepartementService) {}
    opens(content,value:any) {
      this.message = "";
      this.modalService.open(content, { centered: true })
      this.prepare= {...value};

  
    }
    open1() {
      this.modifier = false;
      this.getDepartement();
      this.prepare= {};
      this.message = "";
      this.submitted = false;
      this.title = "Ajouter un l'arrondissement";
      this.visibleSidebar1 = true;
     }
     edit1(item: any){
      this.modifier = true;
      this.getDepartement();
      this.message = "";
      this.prepare = {...item};
      this.title = "Modifier un l'arrondissement";
      this.visibleSidebar1 = true;
    }
  
    getDepartement(){
      this.data$ = this.service.getAll().pipe(
        map(data =>{
          this.departements=data;
          console.log(this.departements)
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
    }
    getCommune(){
      this.data$ = this.serviceCommune.getAll().pipe(
        map(data =>{
          this.communes=data;
          console.log(this.communes)
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
    }
    Add(){
      if( this.communeForm.valid){
        this.submitted = true;
        console.log(this.departement)
        this.serviceCommune.create(this.communeForm.value).subscribe((result)=>{
          this.submitted = true;
         this.toastr.success('Arrondissement creer avec sucess');
         this.visibleSidebar1 = false;
         this.ngOnInit();
        },
        (error)=>{
         console.log(error);
         this.message = error.message.error.message;
         this.submitted = false;
         this.toastr.error('Une erreur est arriver, veullez re-essayez');
        
        }
      
        )
       
        }  
      }
    edit(){
         if(this.communeForm.valid){
          this.submitted = true;
          console.log(this.communeForm.value)
          this.serviceCommune.updateCommune(this.communeForm.value).subscribe(data => {
            this.submitted = false;           
            this.toastr.success('le departemrnt a ete modifier avec sucess');
            this.visibleSidebar1 = false;
            this.ngOnInit();
          },
          (error)=>{
            this.submitted = false;
            this.error = true;
            this.message = error.message.error.message;
            this.toastr.error('Nous renvontrons une erreur');
           
          })
         
         }
        
        }
    delete(){
          this.submitted = true;
            this.serviceCommune.deleteCommune(this.prepare).subscribe(data => {
              this.submitted = false;
              this.modalService.dismissAll();
              this.ngOnInit();
              this.toastr.success('Arrondissement supprime avec sucess');
        
            },
            (error)=>{
              this.submitted = false;
              this.message = error.message.error.message;
              this.toastr.error('Nous rencontrons une erreur');
            })
            
            
         }  
      
      
      
      

    
  ngOnInit(){
   this.getCommune();
   this.communeForm = new FormGroup({
    "id": new FormControl(""),
    "departementId": new FormControl("", [Validators.required]),
    "libelle": new FormControl("", [Validators.required]),
    "commune": new FormControl("",[Validators.required]),
    "code": new FormControl("", [Validators.required,Validators.minLength(2)])
  })    
   
}

}


