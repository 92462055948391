<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <div class="col-12 d-flex justify-content-between align-items-center">
            <div class="p-0">
                <h2>
                    Autorisations
                </h2>
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>

        </div>
        <div class="col-12 d-flex justify-content-between pt-0">
            <div></div>
            <div>
                <button pButton pRipple (click)="editAutorisation(user)" type="button" label="Ajouter" icon="pi pi-plus" class="p-button p-button-sm"></button>

            </div>
        </div>
        <main class="main-content border-radius-lg">

            <div class="card" *ngIf="statusData==='complete'">
                <p-table styleClass="p-datatable-striped" #dt [value]="data" [(selection)]="selectedUsers" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [filterDelay]="0" [globalFilterFields]="[
                    'name',
                    'role_name',
                    'description',
                    'total',
                    'status'
                ]">
                    <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                        <div class="table-header col-12 d-flex">
                            <div class="col">
                                <div>
                                    <span class="p-input-icon-left mr-4">
                               <i class="pi pi-search"></i>
                               <input
                                   pInputText
                                   type="text"
                                   (input)="
                                       dt.filterGlobal(
                                           $event.target.value,
                                           'contains'
                                       )
                                   "
                                   placeholder="Rechercher"
                               />
                           </span>
                                </div>
                            </div>
                            <div class="sizes col d-flex justify-content-end align-content-center align-items-center test">
                                <!--<p-button label="Export" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>-->
                                <p-button label="Imprimer" (click)="generatePDF()" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                <p-button label="Importer" icon="pi pi-excel" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>

                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" style="background-color: #2E394B !important">
                        <tr>
                            <th pSortableColumn="name" style="width: 5%;">
                                #
                            </th>
                            <th pSortableColumn="name" class="col-9">
                                <div class="flex justify-content-between align-items-center">
                                    Libelle

                                </div>
                            </th>
                            <th pSortableColumn="" class="col-2">
                                <div class="flex justify-content-between align-items-center">
                                    Actions
                                </div>
                            </th>



                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-datas let-rowIndex="rowIndex">
                        <tr class="p-selectable-row">

                            <td>
                                {{rowIndex + 1 }}
                            </td>
                            <td style="cursor: pointer;" routerLink="/autorisation/detail-autorisation/{{datas.id}}">
                                {{datas.libelle}}
                            </td>
                            <td>
                                <div class=" d-flex">
                                    <div>
                                        <p-button (click)="editAutorisation(datas)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                    </div>

                                </div>

                            </td>


                        </tr>

                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="3" style="display: flex;text-align: center;">Aucun roles trouvés.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </main>
    </p-scrollPanel>

</section>
<p-sidebar position="right" [style]="{width: '50%'}" [(visible)]="visibleSidebar" [baseZIndex]="10000">

    <ng-template pTemplate="header">
        <h3>{{ChaineEditAutorisation}}</h3>
    </ng-template>

    <ng-template pTemplate="content">
        <form [formGroup]="Form">
            <div class="col-12 field">
                <input type="text" style="display: none;" formControlName="id" class="form-control" [(ngModel)]="saveUserToEdit.id" />

                <div class="input-field" [class.error]="libelle.invalid && libelle.hasError('required','type')">
                    <label class="form-label" for="form3Example3">Libelle<span class="text-danger me-1">*</span></label>
                    <div class="input-group">
                        <input type="text" formControlName="libelle" name="name" placeholder="Entrer le nom" class="form-control" [(ngModel)]="saveUserToEdit.libelle" required />
                    </div>
                    <div *ngIf="libelle.invalid && (libelle.touched || libelle.dirty) ">
                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="libelle.errors?.required ">*le libelle obligatoire</span>
                    </div>
                </div>
            </div>


        </form>
    </ng-template>

    <!--<ng-template pTemplate="content">
    <form action="">
        <span class="p-float-label mt-2">
    <input style="width: 100% !important;" id="float-input" type="text" pInputText>
    <label for="float-input">Nom</label>
</span>
        <span class="p-float-label mt-5">
      <input style="width: 100% !important;"  id="float-input" type="text" pInputText>
      <label for="float-input">Description</label>
  </span>
        

</form>
</ng-template>-->

    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" [disabled]="submitted" (click)="visibleSidebar=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>

        <button *ngIf="ChaineEditAutorisation==='Ajouter une autorisation'" [disabled]="submitted" pButton pRipple type="button" (click)="add()" label="Sauvegarder" class="p-button-raised"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>
        <button *ngIf="ChaineEditAutorisation==='Modifier cette autorisation'" [disabled]="submitted" pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="update()"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

    </ng-template>


</p-sidebar>
<ng-template #content let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Voulez vous supprimer cette autorisations</h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            Cette action est irreversible
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" pButton class="p-button-secondary" (click)="modal.close('Save click')" label="Annuler"></button>
        <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

    </div>
</ng-template>
<app-spinner *ngIf="statusData==='load'"></app-spinner>