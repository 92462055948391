import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeQuestionService } from '@services/type-question.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, catchError, map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-types-question',
  templateUrl: './types-question.component.html',
  styleUrls: ['./types-question.component.scss']
})
export class TypesQuestionComponent implements OnInit{
  prepare: any;
  title: string;
  visiblebar: boolean = false;
  data: any;
  selected:any;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  err: any;
  submitted: boolean = false;
  value: boolean = false;
  val: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
    Form:any;
    get code(){return this.Form.get('numero'); }
      get libelle(){return this.Form.get('libelle'); }
      get description(){return this.Form.get('description'); }
  constructor(private modalService: NgbModal, private service: TypeQuestionService,private toastr: ToastrService,){}
  ngOnInit() {
    this.Form = new FormGroup({
      "id": new FormControl(""),
      "numero": new FormControl("", [Validators.required]),
      "libelle": new FormControl("", [Validators.required]),
      "description": new FormControl("", [Validators.required]),
    })
    this.get();
  }
  open1() {
    this.prepare = {};
    this.title = "Ajouter un type de question";
    this.visiblebar = true;
   }
   edit(item: any){
    this.prepare = {...item};
    this.title = "Modifier un type de question";
    this.visiblebar = true;
  }
  opens(content,value:any) {
    this.modalService.open(content, { centered: true })
    this.prepare= {...value};
  }
  close(){
    this.err= "";
    this.modalService.dismissAll()
   }
     get(){
    console.log('ok')
    this.data$ = this.service.getAll().pipe(
      map(data =>{
        this.data=data;
        console.log(this.data);
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err}))
    );
   }
  
   delete(){
    this.err='';
    this.submitted = true;
      this.service.delete(this.prepare).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
        this.toastr.success('type de bourse question as ete supprimer avec success');
  
      },
      (error)=>{
        this.err= error;
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
   }
   adds(){
    this.subscribError = "";

    this.submitted = true;
    console.log(this.Form.value)
    if(this.Form.valid){
      this.service.create(this.Form.value).subscribe((result)=>{
       this.submitted = false;
       this.visiblebar = false;
  
        this.ngOnInit();
        this.toastr.success('le type de question a ete ajouter avec success');
  
      },
      (error)=>{
        this.subscribError = error;

       this.submitted = false;
       this.toastr.error('Nous rencontrons une erreur', 'Error');
  
      }
      )  
    
    }
    
   }
   update(){
    this.subscribError = '';
    this.submitted = true;
    console.log(this.Form.value)
      this.service.update(this.Form.value).subscribe(data => {
        this.submitted = false;
        this.visiblebar = false;
        this.ngOnInit();
        this.toastr.success('type de question modifié avec success');
  
      },
      (error)=>{
        this.subscribError =  error;
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
   }
  }


