import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { BaseService } from './base.service';
import { Service } from '@/models/service';



@Injectable({
  providedIn: 'root'
})

export class ServicesService extends BaseService<Service> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "services"
    );
}

}
