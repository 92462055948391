import {AppState} from '@/store/state';
import {UiState} from '@/store/ui/state';
import {Component, HostBinding, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {AppService} from '@/servicesNotification/app.service';
import {Observable} from 'rxjs';

const BASE_CLASSES = 'main-sidebar elevation-4';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu = MENU;
    public admin = ADMIN;
    public perso = PERSO;
    public forma = FORMA;
    public struc = STRUC;
    public autres = AUTRES
    constructor(
        public appService: AppService,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
    }
}

export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'home',
        path: ['/dashboard']
    },

];

export const ADMIN = [
{
  name: 'Administration',
  iconClasses: 'four',
  children: [

    {
      name: 'Utilisateurs',
      iconClasses: 'fas fa-circle',
      path: ['/utilisateur']
  },
  {
    name: 'Autorisations',
    iconClasses: 'fas fa-circle',
    path: ['/autorisation']
},
{
  name: 'Notifications',
  iconClasses: 'fas fa-circle',
  path: ['/notification']
},
{
  name: 'Divisions',
  iconClasses: 'fas fa-circle',
  path: ['/division']
},
{
  name: 'Paramétres',
  iconClasses: 'fas fa-circle',
  path: ['/parametre']
},
]

}

]
export const PERSO = [
  {
    name: 'Gestion du Personnel',
    iconClasses: 'guest',
    children: [

      {
        name: 'Personnels',
        iconClasses: 'fas fa-circle',
        path: ['/personnel']
    },
    {
      name: 'Services',
      iconClasses: 'fas fa-circle',
      path: ['/services']
  },
  {
    name: 'Fonctions',
    iconClasses: 'fas fa-circle',
    path: ['/fonctions']
  },
  ]

  }

  ]

  export const FORMA = [
    {
      name: 'Gestion des Formations',
      iconClasses: 'clipboard',
      children: [

        {
          name: 'Specialité',
          iconClasses: 'fas fa-circle',
          path: ['/formation']
      },
      {
        name: 'Reférentiel',
        iconClasses: 'fas fa-circle',
        path: ['/referentiel']
    },
      {
        name: 'Filiére',
        iconClasses: 'fas fa-circle',
        path: ['/filiere']
    },
    {
      name: "Secteur D'activitite",
      iconClasses: 'fas fa-circle',
      path: ['/secteurPorteur']
    },
      {
        name: 'Diplôme de Qualification',
        iconClasses: 'fas fa-circle',
        path: ['/typeDiplomeQualification']
    },
    {
      name: 'Qualification pour Formation',
      iconClasses: 'fas fa-circle',
      path: ['/qualification-formation']
  },
    {
      name: 'Diplôme',
      iconClasses: 'fas fa-circle',
      path: ['/diplome']
    },
    {
      name: "Niveau D'etude",
      iconClasses: 'fas fa-circle',
      path: ['/niveauxEtude']
    },
    {
      name: 'Types de Formation',
      iconClasses: 'fas fa-circle',
      path: ['/typeFormation']
    },
    {
      name: 'Statut Professionnel',
      iconClasses: 'fas fa-circle',
      path: ['/statutFormation']
    },

    ]

    }

    ]


    export const STRUC = [
      {
        name: 'Gestion des Structures',
        iconClasses: 'guest_in_house',
        children: [

          {
            name: 'Structures',
            iconClasses: 'fas fa-circle',
            path: ['/structures']
        },
        {
          name: 'Apprenants',
          iconClasses: 'fas fa-circle',
          path: ['/apprenants']
      },
      {
        name: 'Formateurs',
        iconClasses: 'fas fa-circle',
        path: ['/formateurs']
    },
        
        {
          name: 'Type de Structure',
          iconClasses: 'fas fa-circle',
          path: ['/typeStructure']
      },
      {
        name: 'Type de Promoteur',
        iconClasses: 'fas fa-circle',
        path: ['/typePromoteurs']
      },
      {
        name: 'Qualité Personnel',
        iconClasses: 'fas fa-circle',
        path: ['/qualite-personnel-structure']
      },
      {
        name: 'Ressource Financiére',
        iconClasses: 'fas fa-circle',
        path: ['/ressources-financiere']
      }

      ]

      }

      ]
export const AUTRES = [
  {
    name: 'Autres',
    iconClasses: 'settings',
    children: [

      {
        name: 'Types Handicaps',
        iconClasses: 'fas fa-circle',
        path: ['/typeHandicap']
    },
    {
      name: 'Types de bourses',
      iconClasses: 'fas fa-circle',
      path: ['/typeBourse']
  },
  {
    name: 'Tranches D\'âges',
    iconClasses: 'fas fa-circle',
    path: ['/trancheAge']
  },
  {
    name: 'Questionnaires de Dnnées',
    iconClasses: 'fas fa-circle',
    path: ['/questionnaire']
  },

  ]

  }

  ]

