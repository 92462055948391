import { Component, Input } from '@angular/core';
import { EtatCanditatsService } from '@services/etat-canditats.service';
import { StatApprenantAnneeEtudeService } from '@services/stat-apprenant-annee-etude.service';

@Component({
  selector: 'app-apprenant-niveau-etude',
  templateUrl: './apprenant-niveau-etude.component.html',
  styleUrls: ['./apprenant-niveau-etude.component.scss']
})
export class ApprenantNiveauEtudeComponent {
  @Input() id: any;
  value: boolean = false;
  data:any;
  states:any;
  table:any[]=[];
  sexes:any[]=[];
  constructor(private state: EtatCanditatsService,private service: StatApprenantAnneeEtudeService){}
  ngOnInit(): void {
      console.log(this.id);
      this.get();
      this.getState();
  }
  get(){
    console.log('ok')
    this.service.getById(this.id).subscribe(data => {
      this.data = data;
      this.iterate1(data)
     },
     (error) => {
     });
   }
   getState(){
    console.log('ok')
    this.state.getAll().subscribe(data => {
      this.states = data;
      this.iterate2(data)
     },
     (error) => {
     });
   }
   iterate1(data:any){  
    console.log(data)
   data.forEach(element => {
    let tab = [];
    element.effectifs.forEach(item => {
      tab.push(item.m)
      tab.push(item.f)
      tab.push(item.total)
    });
    this.table.push(tab);
   });
   console.log(this.table)
   }
   iterate2(data:any){  
    console.log(data)
   data.forEach(element => {
    this.sexes.push("H")
      this.sexes.push("F")
      this.sexes.push("Total")
    
   });
   console.log(this.sexes)
   }
  
}
