import { Injectable } from '@angular/core';

import { Observable, throwError} from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { BaseService } from './base.service';
import { Region } from '@/models/region';
import { Departement } from '@/models/departement';
import { Arrondissement } from '@/models/arrondissement';



const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' })
};

@Injectable({
  providedIn: 'root'
})

export class ArrondissementService extends BaseService<Departement> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "divisions/arrondissements"
    );
    
}
updateCommune(item: Arrondissement): Observable<Arrondissement> {
  return this.httpCient.put<Arrondissement>(`${environment.apiUrl}/divisions/structure/arrondissements`, JSON.stringify(item), this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleErrors)
    )
}
deleteCommune(item: Arrondissement) {
  return this.httpCient.delete<Arrondissement>(`${environment.apiUrl}/divisions/structure/arrondissements/{id}/delete?id=${item.id}`, this.httpOptions)
    .pipe(
      retry(2),
      catchError(this.handleErrors)
    )
}
private handleErrors(error: HttpErrorResponse) {
    let errorMessage;
    console.log(error);
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      if (error.status == 200) {
        errorMessage = { "Code d'erreur": error.status, "message": "OK" };

      } else {
        errorMessage = { "Code d'erreur": error.status, "message": error.error.message };

      }
    }
    return throwError(errorMessage);
  }
}


