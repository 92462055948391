import { Component } from '@angular/core';
import { Location } from '@angular/common'
import {ConfirmationService} from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-detail-formation',
  templateUrl: './detail-formation.component.html',
  styleUrls: ['./detail-formation.component.scss'],
  providers: [ConfirmationService]

})
export class DetailFormationComponent {
  data: any;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  submitted: boolean = false;
  typeformations: any[] | undefined;
  typeformation: any;
   visibleSidebar1: boolean = false;
   selectedtypeformation: any;
  title : string ="";
  err: any;
  val: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
  id: any;
  constructor(private location: Location,private confirmationService: ConfirmationService,private route: ActivatedRoute,){

  }
  
  back(){
    this.location.back();
  }
  confirm() {
    this.confirmationService.confirm({
        message: 'Voulez vous supprimer cette formation?',
      
    });
}
  ngOnInit() {
    this.id= this.route.snapshot.paramMap.get('id');
    this.item2 = [
       
      {label:"Specialite"},
      {label:"Details"},
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
  }
}
