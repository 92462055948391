import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Observable, catchError, map, of, startWith } from 'rxjs';
import { DepartementService } from '@services/departements.service';
import { RegionsService } from '@services/regions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-all-departements',
  templateUrl: './all-departements.component.html',
  styleUrls: ['./all-departements.component.scss'],
  providers: [ConfirmationService]
})
export class AllDepartementsComponent implements OnInit {
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  submitted: boolean = false;
  departements: any[] | undefined;
  departement: any;
  regions: any[] | undefined;
  departementForm: any;
   visibleSidebar1: boolean = false;
   selectedDepartement: any;
    representatives: any[];
  title : string ="";
    statuses: any[];
 index: number = 0;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
  subscribError: any;
  nameInvalidError: any;
  codeInvalidError: any;
  modifier: Boolean = false;
  message: string;
  error: Boolean = false;
    get region(){return this.departementForm.get('regionId'); }
    get name(){return this.departementForm.get('libelle'); }
    get code(){return this.departementForm.get('code'); }
    get id(){return this.departementForm.get('id'); }
    constructor(private confirmationService: ConfirmationService,private regionS: RegionsService,private modalService: NgbModal,private toastr: ToastrService,private service: DepartementService) {}
    opens(content,value:any) {
      this.message = "";
      this.modalService.open(content, { centered: true })
      this.departement= {...value};

  
    }
    open1() {
      this.modifier = false;
      this.getRegion();
      this.departement= {};
      this.message = "";
      this.title = "Ajouter un departement";
      this.visibleSidebar1 = true;
     }
     edit1(item: any){
      this.modifier = true;
      this.getRegion();
      this.message = "";
      this.departement = {...item};
      this.title = "Modifier un departement";
      this.visibleSidebar1 = true;
    }
  
    getDepartement(){
      this.data$ = this.service.getAll().pipe(
        map(data =>{
          this.departements=data;
          console.log(this.departements)
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
    }
    getRegion(){
      this.data$ = this.regionS.getAll().pipe(
        map(data =>{
          this.regions=data;
          console.log(this.regions)
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
    }
    AddDepartement(){
      if( this.departementForm.valid){
        this.submitted = true;
        console.log(this.departement)
        this.service.create(this.departementForm.value).subscribe((result)=>{
          this.submitted = false;
         this.toastr.success('Le departement as ete creer avec sucess');
         this.visibleSidebar1 = false;
         this.ngOnInit();
        },
        (error)=>{
         console.log(error.message.error.message);
         this.error = true;
         this.message = error.message.error.message;
         this.submitted = false;
         this.toastr.error('Une erreur est arriver, veullez re-essayez');
        
        }
      
        )
       
        }  
      }
    editDepartement(){
         if(this.departementForm.valid){
          this.submitted = true;
          console.log(this.departementForm.value)
          this.service.update(this.departementForm.value).subscribe(data => {
            this.submitted = false;  
            this.toastr.success('le departemrnt a ete modifier avec sucess');
            this.visibleSidebar1 = false;
            this.ngOnInit();
          },
          (error)=>{
            this.submitted = false;
            console.log()
            this.message = error.message.error.message;
            this.toastr.error('Nous renvontrons une erreur');
           
          })
         
         }
        
        }
    delete(){
          this.submitted = true;
            this.service.delete(this.departement).subscribe(data => {
              this.submitted = false;
              this.modalService.dismissAll();
              this.ngOnInit();
              this.toastr.success('le departement a ete supprimé avec succés');
        
            },
            (error)=>{
              this.submitted = false;
              console.log(error.message.error.message)
              this.message = error.message.error.message;
              this.toastr.error('Nous rencontrons une erreur');
            })
            
            
         }  
      
      
      
      

    
  ngOnInit(){
   
    this.getDepartement();
    
  
    this.departementForm = new FormGroup({
      "id":new FormControl(""),
      "regionId": new FormControl("", [Validators.required]),
      "libelle": new FormControl("", [Validators.required]),
      "code": new FormControl("", [Validators.required,Validators.minLength(2)])
    })
  
    
   
}

}
