import { Component } from '@angular/core';

@Component({
  selector: 'app-infos-vih-violence',
  templateUrl: './infos-vih-violence.component.html',
  styleUrls: ['./infos-vih-violence.component.scss']
})
export class InfosVihViolenceComponent {

}
