import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Location } from '@angular/common'
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Observable, catchError, map, of, startWith } from 'rxjs';
import { SpecialiteService } from '@services/specialite.service';
import { FiliereService } from '@services/filiere.service';
import { ReferentielService } from '@services/referentiel.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-formations',
  templateUrl: './formations.component.html',
  styleUrls: ['./formations.component.scss'],
  providers: [ConfirmationService]
})
export class FormationsComponent {
  /** 
   * Valalidation of form field of formation name and description
  */
  readonly DataStateEnum=DataStateEnum;
  data$: Observable<AppDataState<any[]>> |null = null;
  values: any;
  selected: any;
  Form:any;
  invalid= false;
 // userInvalidError: any;
  nameInvalidError:any;
  codeInvalidError: any;
  referentielInvalidError: any;
  subscribError: any;
  userInvalidError: any;
  passInvalidError: any;
  display:any;
  filieres:any;
  referentiels:any;
  get id(){return this.Form.get("id");}
  get name(){return this.Form.get('libelle'); }
  get code(){return this.Form.get('code'); }
  get referentiel(){return this.Form.get('referentielFormationId'); }
  get filiere(){return this.Form.get('filiereId'); }
  get description(){return this.Form.get('description'); }


  value: boolean = false;
  submitted: boolean = false;
  formation: any | undefined;
  
   visibleSidebar1: boolean = false;
   selectedFormation: any;
    representatives: any[];
  title : string ="";
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    message: any;
    modifier: boolean = false;
    constructor(private toastr: ToastrService,private modalService: NgbModal,private location: Location,private service: SpecialiteService,private serviceFiliere: FiliereService,private serviceRef: ReferentielService) {}
    open() {
      this.formation= {};
      this.getFiliere();
      this.getRef();
      this.modifier = false;
      this.message = ""
      this.title = "Ajouter une specialite";
      this.display= 'none';
      this.visibleSidebar1 = true;
     }
    edit(item: any){
      this.formation = {...item};
      this.getFiliere();
      this.getRef();
      this.message = "";
      this.modifier = true;    
      console.log(this.formation);
      this.title = "Modifier une specialite";
      this.display= 'block';
      this.visibleSidebar1 = true;
    }
    opens(content,value:any) {
      this.message = "";
      this.modalService.open(content, { centered: true })
      this.formation= {...value};

  
    }
    get(){
      this.data$ = this.service.getAll().pipe(
        map(data =>{
          this.values = data;
          console.log(this.filieres);
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
    }
    getFiliere(){
      this.serviceFiliere.getAll().subscribe(data => {
        this.filieres = data;
       },
       (error) => {
       });
       }    
    getRef(){
      this.serviceRef.getAll().subscribe(data => {
        this.referentiels = data;
       },
       (error) => {
       });
       
    }
    Add(){
      if( this.Form.valid){
        this.submitted = true;
        console.log(this.Form.value)
        this.service.create(this.Form.value).subscribe((result)=>{
          this.submitted = false;
         this.toastr.success('La specialite as ete creer avec sucess');
         this.visibleSidebar1 = false;
         this.ngOnInit();
        },
        (error)=>{
         console.log(error.message.error.message);
         this.message = error.message.error.message;
         this.submitted = false;
         this.toastr.error('Une erreur est arriver, veullez re-essayez');
        
        }
      
        )
       
        }  
      }
    Update(){
         if(this.Form.valid){
          this.submitted = true;
          console.log(this.Form.value)
          this.service.update(this.Form.value).subscribe(data => {
            this.submitted = false;  
            this.toastr.success('la specialite a ete modifier avec sucess');
            this.visibleSidebar1 = false;
            this.ngOnInit();
          },
          (error)=>{
            this.submitted = false;
            console.log()
            this.message = error.message.error.message;
            this.toastr.error('Nous renvontrons une erreur');
           
          })
         
         }
        
        }
    delete(){
          this.submitted = true;
            this.service.delete(this.formation).subscribe(data => {
              this.submitted = false;
              this.modalService.dismissAll();
              this.ngOnInit();
              this.toastr.success('la specialite a ete supprimé avec succés');
        
            },
            (error)=>{
              this.submitted = false;
              console.log(error.message.error.message)
              this.message = error.message.error.message;
              this.toastr.error('Nous rencontrons une erreur');
            })
            
            
         }  
      

    
  ngOnInit(){
    this.Form = new FormGroup({
      "id": new FormControl(""),
      "libelle": new FormControl("", [Validators.required]),
      "code": new FormControl("", [Validators.required]),
      "filiereId": new FormControl("", [Validators.required]),
      "referentielFormationId": new FormControl("", [Validators.required]),
      "description": new FormControl(""),
      
      
    })


    this.item2 = [

      {label:'Specialite'},
      /*{label:'Listes des Formations', routerLink: ['/dashboard/gestion-formation/formation']},*/
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/Dashboard'};
this.get();

}

}
