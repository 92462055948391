import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { Location } from '@angular/common'
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DataStateEnum, AppDataState } from '@/State/load.state';
import { Observable, map, startWith, catchError, of } from 'rxjs';
import { DepartementService } from '@services/departements.service';
import { ArrondissementService } from '@services/arrondissement.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-arrondissement',
  templateUrl: './arrondissement.component.html',
  styleUrls: ['./arrondissement.component.scss'],
  providers: [ConfirmationService]
})
export class ArrondissementComponent {
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  values: any;
  value: boolean = false;
  submitted: boolean = false;
  communes: any[] | undefined;
  prepare: any;
  title: string = "";
   visibleSidebar2: boolean = false;
   selectedCommune: any;
    representatives: any[];
    item2: any[];
    home: any;
    statuses: any[];
 
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
    communeForm: any;
    message: string;
    modifier: Boolean = false;
    departements: any;
    id: any;
    regionId:any;
    get departement(){return this.communeForm.get('departementId'); }
    get name(){return this.communeForm.get('libelle'); }
    get commune(){return this.communeForm.get('commune'); }
    get code(){return this.communeForm.get('code'); }
    get idCommune(){return this.communeForm.get('id'); }
    constructor(private modalService: NgbModal,private serviceCommune: ArrondissementService,private service: DepartementService,private route: ActivatedRoute,private confirmationService: ConfirmationService,private location: Location,private toastr: ToastrService) {}
    open() {
      this.modifier = false;
      this.getDepartement();
      this.prepare= {};
      this.message = "";
      this.submitted = false;
      this.title = "Ajouter un l'arrondissement";
      this.visibleSidebar2 = true;
     }
     edit(item: any){
      this.modifier = true;
      this.getDepartement();
      this.message = "";
      this.prepare = {...item};
      this.title = "Modifier un l'arrondissement";
      this.visibleSidebar2 = true;
    }
    opens(content,value:any) {
      this.message = "";
      this.modalService.open(content, { centered: true })
      this.prepare= {...value};
    }
  
    get(){
      this.datas$ = this.service.getById(this.id).pipe(
        map(data =>{
          this.values = data;
          console.log(this.values.id);
          this.item2 = [
            {label:'Division',routerLink: ['/division']},
            {label:'Departement',routerLink: [`/division/region/${this.values.region.id}`]},
            {label:'Arrondissement',},
        ];
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
    }
    getDepartement(){
      this.datas$ = this.service.getAll().pipe(
        map(data =>{
          this.departements=data;
          console.log(this.departements)
          return ({dataState:DataStateEnum.LOADED})
        }),
        startWith({dataState:DataStateEnum.LOADING}),
        catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      );
    }
    addArrondissement(){
      if( this.communeForm.valid){
        this.submitted = true;
        console.log(this.departement)
        this.serviceCommune.create(this.communeForm.value).subscribe((result)=>{
          this.submitted = false;
         this.toastr.success('Arrondissement creer avec sucess');
         this.visibleSidebar2 = false;
         this.ngOnInit();
        },
        (error)=>{
         console.log(error);
         this.message = error.message.error.message;
         this.submitted = false;
         this.toastr.error('Une erreur est arriver, veullez re-essayez');
        
        }
      
        )
       
        }  
         
    }

    editCommune(){
      if(this.communeForm.valid){
        this.submitted = true;
        console.log(this.communeForm.value)
        this.serviceCommune.updateCommune(this.communeForm.value).subscribe(data => {
          this.submitted = false;          
          this.toastr.success('le departemrnt a ete modifier avec sucess');
          this.visibleSidebar2 = false;
          this.ngOnInit();
        },
        (error)=>{
          this.submitted = false;
          this.message = error.message.error.message;
          this.toastr.error('Nous renvontrons une erreur');
         
        })
       
       }
      
     
    }
    delete(){
      this.submitted = true;
            this.serviceCommune.deleteCommune(this.prepare).subscribe(data => {
              this.submitted = false;
              this.modalService.dismissAll();
              this.ngOnInit();
              this.toastr.success('Arrondissement supprime avec sucess');
        
            },
            (error)=>{
              this.submitted = false;
              this.message = error.message.error.message;
              this.toastr.error('Nous rencontrons une erreur');
            })
            
        
     } 
    saveRegion(){
      this.toastr.success('La region a ete modifier avec sucess', 'Success');
      this.visibleSidebar2 = false;
    }
      

    
  ngOnInit(){
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
    this.id= this.route.snapshot.paramMap.get('id');
    this.get();
    this.communeForm = new FormGroup({
      "id": new FormControl(""),
      "departementId": new FormControl("", [Validators.required]),
      "libelle": new FormControl("", [Validators.required]),
      "commune": new FormControl("",[Validators.required]),
      "code": new FormControl("", [Validators.required,Validators.minLength(2)])
    })
   

}
}
