import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-statut-professionel',
  templateUrl: './statut-professionel.component.html',
  styleUrls: ['./statut-professionel.component.scss'],
  providers: [ConfirmationService]
})
export class StatutProfessionelComponent {
  value: boolean = false;
  submitted: boolean = false;
  statutpros: any[] | undefined;
  statutpro: any;
  visibleSidebar1: boolean = false;
  selectedstatutpro: any;
  title : string ="";
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
    statutForm: any;
      get name(){return this.statutForm.get('name'); }
      get sigle(){return this.statutForm.get('sigle'); }
      get description(){return this.statutForm.get('description'); }
    constructor(private confirmationService: ConfirmationService,private location: Location,private toastr: ToastrService) {}
    back(){
      this.location.back();
    }
    confirm(item,content) {
        this.confirmationService.confirm({
          message: "Voulez vraiment supprimer ce statut proffessionelle?",
          accept: () => {
            this.toastr.error("le statut proffessionelle as ete supprimer avec success', 'Success");
            content.close();
            this.ngOnInit();
           
        }
        });
    }
    open1() {
      this.statutpro= {};
      this.submitted = false;
      this.title = "Ajouter un statut proffesionelle";
      this.visibleSidebar1 = true;
     }
     edit1(item: any){
      this.statutpro = {...item};
      this.title = "Modifier un statut proffesionelle";
      this.visibleSidebar1 = true;
    }
  
    
    saveRegion(){
      this.toastr.success('Le tatut proffesionelle a ete ajouter avec success', 'Success');
      this.visibleSidebar1 = false;
    }
      

    
  ngOnInit(){
    this.statutForm = new FormGroup({
      "name": new FormControl("", [Validators.required]),
      "sigle": new FormControl("", [Validators.required,Validators.minLength(2)]),
      "description": new FormControl(""),
    })
    this.item2 = [
      {label:"statut proffesionelle"},
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
   this.statutpros  = [
     { 'index': 1 ,'name': 'Baccalaureat Scientique', 'code':'Bac S','description': 'Baccalaureat c ou Baccalaureat d'},
     { 'index': 2 ,'name': 'Probatoire', 'code':'Probatoire','description': 'Baccalaureat c ou Baccalaureat d'},
     
     
 ];



}



}
