import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

import { Parameter } from '../models/parameter';
import { environment } from 'environments/environment';
import { BaseService } from './base.service';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' })
};

@Injectable({
  providedIn: 'root'
})

export class ParametreService extends BaseService<Parameter> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "parametres"
    );
}

}
