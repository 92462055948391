import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TypeQuestionService extends BaseService<any>{

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "questionnaires/typeQuestions"
    );
}
}
