<main class="containe" id="cont">
    <div style="width: 100%;height: 100%;" id="cont1">

        <div class="container" style="
    height: 120px;
    width: 100%;
    top: 40px;

    border-bottom: 3px solid rgb(212, 209, 209);">
            <div class="d-flex align-items-center" style="justify-content:space-between;padding-top: 25px;">
                <img src="../../assets/img/drapeau.png" alt="" height="70" width="70" style="border-radius: 10px;">
                <h4 style="color: rgb(212, 209, 209);">MINISTERE DE l'EMPLOI ET DE LA FORMATION PROFESSIONNELLE</h4>
                <img src="../../assets/img/minefop2.jpeg" alt="" height="70" width="70" style="border-radius: 10px;">
            </div>
        </div>


        <div class="container-fluid" style="margin-top: 20px;">


            <div style="width: 100%;height: 100%;">

                <div style="width: 100%;height: 100%;display: flex; justify-content: center;align-items: center;">

                    <div style="align-items: center;justify-content: center;display: flex;">

                        <div class="card col-md-7 col-lg-7" style="box-shadow: 0 0 2em rgb(0, 0, 0);background-color: white;padding-left: 10px;
          ">
                            <div class="card-body ">
                                <div class="col-12 d-flex">
                                    <div class="col">
                                        <div>
                                            <h3 class=" text-Start fs-3 size1" style="color: black;">Connexion</h3>

                                        </div>
                                        <div>
                                            <p class="text-Start small"> <i>Entrez votre identifiants!</i></p>

                                        </div>
                                    </div>
                                    <div class="col" style="justify-content: end;display: flex;">
                                        <app-language></app-language>
                                    </div>
                                </div>

                                <form class="row needs-validation" [formGroup]="userInfo">
                                    <div class="col-12 field">

                                        <div class="input-field" [class.error]="username.invalid && username.hasError('required','type')">
                                            <label class="form-label" for="form3Example3">Email<span class="text-danger me-1">*</span></label>
                                            <div class="input-group">
                                                <span class=" input-group-prepend ">
                                              <div class="input-group-text bg-transparent bord"><i class="fa fa-user "></i></div>
                                                </span>
                                                <input type="email" formControlName="email" placeholder="mincontrol@gmail.com " required id="form3Example3" class="form-control" />
                                            </div>
                                            <div *ngIf="username.invalid && (username.touched || username.dirty) ">
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="username.errors?.required ">*l'Email est obligatoire</span>
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="username.errors?.email ">*L'email n'est pas valide</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 field ">
                                        <div>
                                            <div class="input-field " [class.error]="password.invalid && password.hasError( 'required', 'type') ">
                                                <label for="pass " class="form-label ">Mots de passe<span class="text-danger me-1 ">*</span></label>
                                                <div class="input-group">
                                                    <span class=" input-group-prepend ">
                                                    <div class="input-group-text bg-transparent bord"><i class="fa fa-lock "></i></div>
                                                   </span>
                                                    <input type="password" formControlName="password" class="form-control " [ngClass]="{ 'ng-dirty': (password.invalid && submitted) || (password.dirty && password.invalid)} " id="pass ">
                                                </div>
                                                <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                                <div *ngIf="password.invalid && (password.touched || password.dirty) ">
                                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="password.errors?.required ">*le mots de passe est obligatoire</span>
                                                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="password.errors?.minlength ">*le mots de passe doit avoir au moins 8 charactéres</span>

                                                </div>
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 field"> <button class="btn btn-primary w-100 " (click)="login() " routerLink="/selection">Connexion <div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div>
                    </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


        </div>
    </div>
</main>