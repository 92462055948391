<main class="containe">

    <div class="container-fluid" id="cont" style="height: 100%;">
        <div style="width: 100%;height: 100%;">
            <img src="../../../assets/svg/hero-bg-shape.svg" style="position: absolute;bottom: 0px;right: 0px;" alt="" srcset="">
            <div style="width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;">
                <div class="cont">
                    <div class="left" style="align-items: center;justify-content: center;display: flex;">
                        <div class="pt-4 pb-2">
                            <h1 class="card-title text-Start pb-0 fs-1 size2" style="color: white;">WELCOME BACK !</h1>
                            <p class="text-Start small size1" style="color: white;font-size: 1rem;">We are delighted to meet you</p>
                        </div>
                        <br>


                    </div>
                    <div class="rigth" style="align-items: center;justify-content: center;display: flex;">

                        <div class="card col-md-7 col-lg-7" style="box-shadow: 0 0 2em rgb(0, 0, 0);background-color: white;">
                            <div class="pb-2">
                                <h3 class="card-title text-Start  fs-3 size1" style="color: rgb(0, 0, 0);padding: 10px;">Register</h3><br>
                                <p class="small"><i>Let's start this adventure together.</i></p>
                            </div>
                            <div class="card-body " style="padding-top: 0px;padding-bottom: 0;">

                                <form [formGroup]="userInformation">
                                    <div class="container-fluid">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="field">
                                                    <div class="input-field field" [class.error]="name.invalid && name.hasError('required','type')">
                                                        <label for="name" class="form-label">Your Name<span class="text-danger me-1">*</span></label>
                                                        <div class="input-group">
                                                            <span class=" input-group-prepend bord">
                                                            <div class="input-group-text bg-transparent bord"><i class="fa fa-user "></i></div>
                                                           </span>
                                                            <input type="text" formControlName="name" class="form-control" [ngClass]="{'ng-dirty': (name.invalid && submitted) || (name.dirty && name.invalid)}" id="name">
                                                            <!--<small class="p-error" *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid)">Le pays est obligatoire.</small>-->
                                                        </div>
                                                        <div *ngIf="name.invalid && (name.touched || name.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="name.errors?.required">*The name is required</span>
                                                        </div>
                                                    </div>
                                                    <div class="input-field field" [class.error]="email.invalid && email.hasError('required','type')">

                                                        <label for="email" class="form-label">Email Address<span class="text-danger me-1">*</span></label>
                                                        <div class="input-group">
                                                            <span class=" input-group-prepend bord">
                                                            <div class="input-group-text bg-transparent bord"><i class="fa fa-envelope "></i></div>
                                                           </span>
                                                            <input type="text" formControlName="email" class="form-control" [ngClass]="{'ng-dirty': (email.invalid && submitted) || (email.dirty && email.invalid)}" id="email">
                                                        </div>
                                                        <!--<small class="p-error" *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid)">Le pays est obligatoire.</small>-->
                                                        <div *ngIf="email.invalid && (email.touched || email.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="email.errors?.required">*The Email is Required</span>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="">

                                                    <div class="input-field field" [class.error]="password.invalid && password.hasError('required','type')">
                                                        <label for="password" class="form-label">Password<span class="text-danger me-1">*</span></label>
                                                        <div class="input-group">
                                                            <span class=" input-group-prepend bord">
                                                            <div class="input-group-text bg-transparent bord"><i class="fa fa-lock "></i></div>
                                                           </span>
                                                            <input type="password" formControlName="password" class="form-control" [ngClass]="{'ng-dirty': (password.invalid && submitted) || (password.dirty && password.invalid)}" id="password">
                                                        </div>
                                                        <!--<small class="p-error" *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid)">Le pays est obligatoire.</small>-->
                                                        <div *ngIf="password.invalid && (password.touched || password.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="password.errors?.required">*The Password is Required</span>
                                                        </div>
                                                    </div>
                                                    <div class="input-field field" [class.error]="confirmPassword.invalid && confirmPassword.hasError('required','type')">
                                                        <label for="confirmPassword" class="form-label">Password Confirmation<span class="text-danger me-1">*</span></label>
                                                        <div class="input-group">
                                                            <span class=" input-group-prepend bord">
                                                            <div class="input-group-text bg-transparent bord"><i class="fa fa-lock "></i></div>
                                                           </span>
                                                            <input type="password" formControlName="confirmPassword" class="form-control" [ngClass]="{'ng-dirty': (confirmPassword.invalid && submitted) || (confirmPassword.dirty && confirmPassword.invalid)}" id="confirmPassword">
                                                        </div>
                                                        <!--<small class="p-error" *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid)">Le pays est obligatoire.</small>-->
                                                        <div *ngIf="confirmPassword.invalid && (confirmPassword.touched || confirmPassword.dirty)">
                                                            <span class="error fs-6 text-danger mx-2 my-2" *ngIf="confirmPassword.errors?.required">*The Confirm Password is Required</span>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                            <p class="text-danger">{{err?.message}}</p>

                                            <div class="col-md-8 d-flex">

                                                <p style="padding: 5px;">I A Member <a routerLink="/login" rel="noopener noreferrer"> Sign Up</a>
                                                </p>

                                            </div>
                                            <div class="col-md-4 align-items-end justify-content-end d-flex">
                                                <div class="mb-3">

                                                    <button [disabled]="submitted" class="btn btn-primary w-100" (click)="adduser()" label="Register">Register<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</main>