<app-header  *ngIf="!(_router.url === '/connexion' || _router.url === '/inscription' || _router.url === '/emailActivateAccount'|| _router.url === '/email-reset-password'|| _router.url === '/reset-password' || _router.url === '/selection')" ></app-header>

<app-menu-sidebar *ngIf="!(_router.url === '/connexion' || _router.url === '/inscription' || _router.url === '/emailActivateAccount'|| _router.url === '/email-reset-password'|| _router.url === '/reset-password' || _router.url === '/selection')"  class="sidebar"></app-menu-sidebar>


    <router-outlet></router-outlet>


<app-footer *ngIf="!(_router.url === '/connexion' || _router.url === '/inscription' || _router.url === '/emailActivateAccount'|| _router.url === '/email-reset-password'|| _router.url === '/reset-password' || _router.url === '/selection')"></app-footer>
<app-control-sidebar *ngIf="!(_router.url === '/connexion' || _router.url === '/inscription' || _router.url === '/emailActivateAccount'|| _router.url === '/email-reset-password'|| _router.url === '/reset-password' || _router.url === '/selection')"></app-control-sidebar>
<div  id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>
