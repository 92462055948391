import { Component } from '@angular/core';

@Component({
  selector: 'app-infos-orientation',
  templateUrl: './infos-orientation.component.html',
  styleUrls: ['./infos-orientation.component.scss']
})
export class InfosOrientationComponent {

}
