import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { AutorisationService } from '@services/autorisation.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RolesService } from '@services/roles.service';

@Component({
  selector: 'app-detail-autorisation',
  templateUrl: './detail-autorisation.component.html',
  styleUrls: ['./detail-autorisation.component.scss']
})
export class DetailAutorisationComponent implements OnInit{
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
  id:any;

  items: any[];
  Form: any;
  data: any;
  droitToPublishs:any[];
home: any;
constructor(private location: Location,private router: Router,private route:ActivatedRoute, private service: RolesService, private toastr: ToastrService, private services: AutorisationService){}
ngOnInit(){

  this.id= this.route.snapshot.paramMap.get('id');
  console.log(this.id);
  
  this.items = [

    {label:'Affectation des autorisations'}
];

this.home = {icon: 'pi pi-home', routerLink: '/'};
this.get();

}
 
get(){
  this.datas$ = this.services.getById(this.id).pipe(
    map(data =>{
      this.data = data;
      console.log(this.data);
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );
}
update(id:any,event:any){
  this.Form = new FormGroup({
    "permissionId": new FormControl(id),
    "hasPermission": new FormControl(event.checked, [Validators.required]),
  })
  console.log(this.Form.value)
    this.service.updatePersmissions(this.Form.value).subscribe(data => {

      this.ngOnInit();
      this.toastr.success('Autorisation modifiée avec succés', 'Success');

    },
    (error)=>{
      this.toastr.error('Nous rencontrons une erreur', 'Error');
    })
}
}
