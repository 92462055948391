import { Component, OnInit } from '@angular/core';
import { ServicesService } from '@services/service.service';
import { ConfirmationService } from 'primeng/api';
 import { ToastrService } from 'ngx-toastr';
 import { AppDataState, DataStateEnum } from '@/State/load.state';
 import { catchError, map, Observable, of, startWith } from 'rxjs';
 import { FormControl, FormGroup, Validators } from '@angular/forms';
 import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DivisionService } from '@services/division.service';
import { RegionsService } from '@services/regions.service';
import { DepartementService } from '@services/departements.service';
import { ArrondissementService } from '@services/arrondissement.service';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
  providers: [ConfirmationService]

})
export class ServicesComponent implements OnInit{
   data$: Observable<AppDataState<any[]>> |null = null;
   readonly DataStateEnum=DataStateEnum;
   value: boolean = false;
   srviceform: any
   submitted: boolean = false;
   departements: any[] | undefined;
 
    visibleSidebar: boolean = false;
    visibleSidebar1: boolean = false;
    visibleSidebar2: boolean = false;
     selectedRegions: any[];
      data: any;
     representatives: any[];
   val:any;
   err: any;
   departe: any;
     statuses: any[];
     item2: any[];
     home: any;
     loading: boolean = true;
     specialite: any;
     prepare: any;
     title: string = "";
     activityValues: number[] = [0, 100];
     items: [] | undefined;
     invalid= false;
     subscribError: any;
   nameInvalidError: any;
   vas: any;
   codeInvalidError: any;
   divisions: any;
   statut: any;
   regions: any;
   localite: any;
   arrondState: any;
   arrondValue: any;
     get libelle(){return this.srviceform.get('libelle'); }
     get code(){return this.srviceform.get('code'); }
     get description(){return this.srviceform.get('description'); }
     get division(){return this.srviceform.get('division'); }
     get minefopId(){return this.srviceform.get('minefopId'); }
     get regionId(){return this.srviceform.get('regionId'); }
     get departementId(){return this.srviceform.get('departementId'); }
     get arrondissementId(){return this.srviceform.get('arrondissementId'); }


     constructor(private modalService: NgbModal,private confirmationService: ConfirmationService,private toastr: ToastrService, private service: ServicesService, private divisio: DivisionService, private region: RegionsService, private depart: DepartementService) {}

     ngOnInit(){
      this.srviceform= new FormGroup({
        "id": new FormControl(""),
        "libelle": new FormControl("", [Validators.required]),
        "code": new FormControl("", [Validators.required]),
        "description": new FormControl("", [Validators.required]),
        "division": new FormControl("", [Validators.required]),
        "minefopId": new FormControl(""),
        "regionId": new FormControl(""),
        "departementId": new FormControl(""),
        "arrondissementId": new FormControl(""),

      })
      this.get();
      this.getdivision();
      this.getregion();
       this.item2 = [
         {label:'Services',},
        
         
     ];
     this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
 
   }
  get(){
   this.data$ = this.service.getAll().pipe(
     map(data =>{
       this.data=data;
       console.log(this.data);
       return ({dataState:DataStateEnum.LOADED})
     }),
     startWith({dataState:DataStateEnum.LOADING}),
     catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
   );
  }
  getdivision(){
    this.statut= 'load';
    this.divisio.getAll().subscribe((data) =>{
      this.divisions=data;
    },
    (err) =>{ this.statut= 'error';},
    ()=>{this.statut = 'complete';}
    )
  }
  getregion(){
  
    this.region.getAll().subscribe((data) =>{
      this.regions=data;
      console.log(this.regions);
    },
    (err) =>{ this.statut= 'error';},
    ()=>{this.statut = 'complete';}
    )
  }
  delete(){
   this.err='';
   this.submitted = true;
     this.service.delete(this.val).subscribe(data => {
       this.submitted = false;
       this.modalService.dismissAll();
       this.ngOnInit();
       this.toastr.success('service supprimé avec succés', 'Success');
 
     },
     (error)=>{
       this.err= error;
       this.submitted = false;
       this.toastr.error('Nous rencontrons une erreur', 'Error');
     })
  }
  update(){
   this.submitted = true;
   console.log(this.srviceform.value)
     this.service.update(this.srviceform.value).subscribe(data => {
       this.submitted = false;
       this.visibleSidebar = false;
       this.ngOnInit();
       this.toastr.success('service modifié avec succés', 'Success');
 
     },
     (error)=>{
       this.submitted = false;
       this.toastr.error('Nous renvontrons une erreur', 'Error');
     })
  }
  add(){
   this.submitted = true;
   console.log(this.srviceform.value);
   if( this.srviceform.valid){
    this.service.create(this.srviceform.value).subscribe((result)=>{
     this.submitted = false;
     this.visibleSidebar = false;

      this.ngOnInit();
      this.toastr.success('Service ajouté avec succées', 'Success');

    },
    (error)=>{
     this.submitted = false;
     this.toastr.error('Nous renvontrons une erreur', 'Error');

    }
    )
  }

  }
  confirm(content) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the selected region?',
      accept: () => {
        this.toastr.error('La region a ete supprimer avec sucess', 'Success');
        content.close();
        this.ngOnInit();

    }

    });
}
open() {
  this.prepare = {};
  this.submitted = false;
  this.title='Ajouter un service';
  this.visibleSidebar = true;


 }
 edit(item: any){
  this.prepare = {...item};
  this.title='Modifier le service'
  this.visibleSidebar = true;
}
 opens(content,value:any) {
  this.modalService.open(content, { centered: true })
  this.val= {...value};


}
close(){
  this.err= "";
  this.modalService.dismissAll()      }

editRegion(){
  this.toastr.success('Le service a ete modifier avec sucess', 'Success');
        this.visibleSidebar = false;

}

getdiv(value){
    this.localite= value;

 }
 getDepartement(value){
  this.departe= 'load'

  this.region.getById(value).subscribe((data:any) =>{
    this.vas=data.departements;
  },
  (err) =>{ this.departe= 'error';},
  ()=>{this.departe = 'complete';}
  )
 
 }
 getArrondissement(value){
  this.arrondState= 'load'

  this.depart.getById(value).subscribe((data:any) =>{
    this.arrondValue=data.arrondissements;
    console.log(this.arrondValue)
  },
  (err) =>{ this.arrondState= 'error';},
  ()=>{this.arrondState = 'complete';}
  )

 }
 
}
