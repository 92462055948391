<a
    (click)="handleMainMenuAction()"
    class="nav-link"
    [ngClass]="{active: isMainActive || isOneOfChildrenActive}"
>

    <img src="../../assets/icon/{{ menuItem.iconClasses }}.svg" style="margin-right: 15px;margin-left:10px" alt="icon">
    <p style="font-size: 14px">{{ menuItem.name }}
        <i
            *ngIf="isExpandable && menuItem.children.length > 0"
            class="right fas fa-angle-right"
            [@rotate]="isMenuExtended"
        ></i>
    </p>
</a>
<ul class="nav nav-treeview" *ngFor="let item of menuItem.children" [@openClose]="isMenuExtended">
    <li class="nav-item">
        <a
            [routerLink]="item.path"
            [routerLinkActive]="'active'"
            class="nav-link"
        >
            <i style="font-size:3px" class="nav-icon  {{ item.iconClasses }}"></i>
            <p style="font-size: 14px">{{ item.name }}</p>

        </a>
    </li>
</ul>
