import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-email-reset-password',
  templateUrl: './email-reset-password.component.html',
  styleUrls: ['./email-reset-password.component.css']
})
export class EmailResetPasswordComponent implements OnInit{
  emailResetPassword!: any;
  submitted: boolean = false;
  err!:any;
  get email(){return this.emailResetPassword.get('email'); }
  constructor(private router: Router){

  }
  ngOnInit(): void {
    this.emailResetPassword= new FormGroup({
      "email": new FormControl("", [Validators.required,Validators.email]),
    })
  }
  resendlink(){

  }
  private validateform(form: FormGroup ){
    Object.keys(form.controls).forEach(field =>{
      const controls =  form.get(field);
      if(controls instanceof FormControl){
        controls.markAsDirty({onlySelf: true});

      }else if(controls instanceof FormGroup){
        this.validateform(controls)
      }
    })

  }
}
