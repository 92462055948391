<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <div class="col-12 d-flex justify-content-between align-items-center pt-0">
            <div class="p-0">
                <h2>
                    Utilisateurs
                </h2>
            </div>
            <div>
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>
        <div class="col-12 d-flex justify-content-between align-items-center pt-0">
            <div></div>
            <div>
                <button pButton pRipple (click)="visibleSidebar = true" type="button" label="Ajouter" icon="pi pi-plus" class="p-button p-button-sm"></button>

            </div>

        </div>
        <main class="main-content border-radius-lg">

            <div class="card" *ngIf="statusData==='complete'">
                <p-table styleClass="p-datatable-striped" #dt [value]="data" [(selection)]="selectedCustomers" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                    [filterDelay]="0" [globalFilterFields]="[
                  'name',
                  'country.name',
                  'representative.name',
                  'status'
              ]" [tableStyle]="{'min-width': '60rem'}">
                    <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                        <div class="table-header col-12 d-flex">
                            <div class="col">
                                <div>
                                    <span class="p-input-icon-left mr-4">
                             <i class="pi pi-search"></i>
                             <input
                                 pInputText
                                 type="text"
                                 (input)="
                                     dt.filterGlobal(
                                         $event.target.value,
                                         'contains'
                                     )
                                 "
                                 placeholder="Rechercher"
                             />
                         </span>
                                </div>
                            </div>
                            <div class="sizes col d-flex justify-content-end align-content-center align-items-center test">
                                <p-button label="Export" (onClick)="generatePdf()" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                <p-button label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                <p-button label="Importer" icon="pi pi-upload" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>

                            </div>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="header" style="background-color: #2E394B !important">
                        <tr>
                            <th>
                                #
                            </th>
                            <th pSortableColumn="name" class="col" style="width: 20% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Noms

                                </div>
                            </th>
                            <th pSortableColumn="country.name" class="col" style="width: 20% !important;">

                                Email

                            </th>
                            <th pSortableColumn="country.name" class="col" style="width: 20% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Numéro

                                </div>
                            </th>
                            <th pSortableColumn="representative.name" class="col" style="width: 10% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Photo


                                </div>
                            </th>
                            <th pSortableColumn="date" style="width: 10% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Role

                                </div>
                            </th>
                            <th pSortableColumn="balance" style="width: 10% !important;">
                                <div class="flex justify-content-between align-items-center">
                                    Quartier

                                </div>
                            </th>

                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-datas let-rowIndex="rowIndex">
                        <tr routerLink="/utilisateur/{{datas.id}}" class="p-selectable-row">
                            <td>
                                {{rowIndex+1}}
                            </td>

                            <td>
                                {{datas.name}}
                            </td>
                            <td>
                                {{datas.email}}
                            </td>

                            <td>
                                {{datas.phone}}
                            </td>
                            <td>
                                <p-avatar shape="circle" size="xlarge" image="../../../../../assets/img/user.png"></p-avatar>

                            </td>

                            <td>
                                {{datas.role}}
                            </td>
                            <td>
                                {{datas.city}}
                            </td>

                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="7" style="text-align: center;">Aucun utilisateur trouvé.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </main>
    </p-scrollPanel>

</section>
<p-sidebar position="right" [style]="{width: '35%'}" [(visible)]="visibleSidebar" [baseZIndex]="10000">

    <ng-template pTemplate="header">
        <h3>Ajouter un utilisateur</h3>
    </ng-template>

    <ng-template pTemplate="content">
        <form [formGroup]="Form">


            <div class="container">
                <div class="row">
                    <div class="input-field" [class.error]="name.invalid && name.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Noms<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <input type="text" formControlName="name" name="name" placeholder="Entrez le noms" class="form-control" required />
                        </div>
                        <div *ngIf="name.invalid && (name.touched || name.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="name.errors?.required ">*le noms est obligatoire</span>
                        </div>
                    </div>
                    <div class="input-field" [class.error]="email.invalid && email.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Email<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <input type="email" formControlName="email" name="name" placeholder="Entrez l'email" class="form-control" required />
                        </div>
                        <div *ngIf="email.invalid && (email.touched || email.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="email.errors?.required ">*l'email est obligatoire</span>
                        </div>
                    </div>
                    <div class="input-field" [class.error]="password.invalid && password.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Mots de passe<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <input type="password" formControlName="password" name="name" placeholder="Entrer le mots de passe" class="form-control" required />
                        </div>
                        <div *ngIf="password.invalid && (password.touched || password.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="password.errors?.required ">*le mots de passe est obligatoire</span>
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="password.errors?.minlength ">*le  mots de passe doit avoir au moins 8 charactéres</span>

                        </div>
                    </div>
                    <div class="input-field" [class.error]="confirmPassword.invalid && confirmPassword.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Confirmez le mots de passe<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <input type="password" formControlName="confirmPassword" name="name" placeholder="Entrer la confirmation" class="form-control" required />
                        </div>
                        <div *ngIf="confirmPassword.invalid && (confirmPassword.touched || confirmPassword.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="confirmPassword.errors?.required ">*la confirmation du mots de passe est obligatoire</span>
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="confirmPassword.errors?.minlength ">*la confirmation du mots de passe doit avoir au moins 8 charactéres</span>

                        </div>
                    </div>
                    <div class="input-field" [class.error]="roleId.invalid && roleId.hasError('required','type')">
                        <label class="form-label" for="form3Example3">role<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <select formControlName="roleId" required class="col-12">
                        <option *ngFor="let role of roles" value="{{role.id}}">{{role.libelle}}</option>
                  
                      </select>
                        </div>
                        <div *ngIf="roleId.invalid && (roleId.touched || roleId.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="roleId.errors?.required ">*le role est obligatoire</span>
                        </div>
                    </div>

                </div>
            </div>



            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="err">*{{err.message}}</span>

        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button pButton pRipple type="button" (click)="clear()" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>
        <button [disabled]="submitted" pButton pRipple type="button" (click)="add()" label="Sauvegarder" class="p-button-raised"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>
    </ng-template>

</p-sidebar>

<app-spinner *ngIf="statusData==='load'"></app-spinner>