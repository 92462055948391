<div class="card">
    <div class="container">
        <div class="container">
            <div class="surface-section">
                <div class="font-medium text-800 mb-3">
                    <h5 style="padding-top: 10px;">INFORMATIONS SUR LE SUIVI POST-FORMATION DES SORTANTS</h5>
                </div>
                <ul class="list-none p-0 m-0">
                    <li class="flex align-items-center py-2 px-2 order-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-6 font-medium" style="margin-right: 30px;">As un suivie post-formation pour les apprenants?</div>
                        <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Heat</div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-6 font-medium">Mechanisms de suivie</div>
                        <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">
                            <ul class="list-unstyled">
                                <li>Avant la formation/Before training</li>
                                <li>Pendant la formation/Before training</li>
                                <li>Apres la formation/After training</li>
                            </ul>
                        </div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-6 font-medium">As une cellule d'appui a l'insertion des sortants ? </div>
                        <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-6 font-medium">Existence d'un outil de gestion de la base de donnees des apprenants et sortant? </div>
                        <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">Michael Mann</div>
                    </li>
                    <li class="flex align-items-center py-2 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                        <div class="text-600 w-6 md:w-6 font-medium">Existence d'un outil d'accompagnement des sortants dans la recherche d'emploi, la creation des groupement d'interet economique (GIE), l'elaboration des plans d'affaires</div>
                        <div class="text-900 w-full md:w-4 md:flex-order-0 flex-order-1">

                        </div>
                    </li>

                </ul>
            </div>
        </div>
    </div>

</div>