import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { BaseService } from './base.service';
import { Region } from '@/models/region';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' })
};

@Injectable({
  providedIn: 'root'
})

export class RegionsService extends BaseService<Region> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "divisions/regions"
    );
}

}
