<section class="content main content-wrapper">


    <main class="main-content position-relative max-height-vh-100 h-50 mt-1 border-radius-lg">
        <div class="d-flex justify-content-between" style="width: 100%;height: 60px;">
            <div>
                <div class="text-secondary pt-3 pl-4 pb-4">
                    <h4>
                        DIVISION ADMINISTRATIF
                    </h4>


                </div>
            </div>
            <div>
                <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

            </div>

        </div>
        <p-tabView>
            <p-tabPanel header="Regions">
                <div class="d-flex justify-content-between" style="width: 100%;height: 60px;padding: 0px;">
                    <div class="col">
                        <h4 class="text-secondary">
                            LISTE DES REGIONS
                        </h4>
                    </div>
                    <div>
                        <button pButton pRipple (click)="open()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin-right: 10px;"></button>

                    </div>


                </div>

                <div class="card">
                    <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
                        <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                            <app-spinner></app-spinner>
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                        </ng-container>
                        <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                            <p-table styleClass="p-datatable-striped" #dt [value]="data" [(selection)]="selectedRegions" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [filterDelay]="0" [globalFilterFields]="[
                      'index',
                      'name',
                      'code'
                  ]">
                                <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                                    <div class="table-header col-12 d-flex">

                                        <div class="col d-flex justify-content-between align-content-center align-items-center">
                                            <div>
                                                <span class="p-input-icon-left mr-4">
                                     <i class="pi pi-search"></i>
                                     <input
                                         pInputText
                                         type="text"
                                         (input)="
                                             dt.filterGlobal(
                                                 $event.target.value,
                                                 'contains'
                                             )
                                         "
                                         placeholder="Rechercher"
                                     />
                                 </span>
                                            </div>
                                            <div class="test">
                                                <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                                                    <p-button ngbDropdownAnchor id="dropdownManual" (click)="myDrop.open()" label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownManual">
                                                        <button ngbDropdownItem>Exporter les regions</button>
                                                        <button ngbDropdownItem>Exporter des departement</button>
                                                        <button ngbDropdownItem>Exporter les commune</button>
                                                        <button ngbDropdownItem>Tout Exporter</button>
                                                    </div>
                                                </div>
                                                <div class="d-inline-block" ngbDropdown #myDrop1="ngbDropdown">
                                                    <p-button ngbDropdownAnchor type="button" id="dropdownManual1" (click)="myDrop1.open()" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownManual1">
                                                        <button ngbDropdownItem>Imprimer les regions</button>
                                                        <button ngbDropdownItem>Imprimer des departement</button>
                                                        <button ngbDropdownItem>Imprimer les commune</button>
                                                        <button ngbDropdownItem>Tout imprimer</button>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </ng-template>
                                <ng-template pTemplate="header" style="background-color: #2E394B !important">
                                    <tr style="width: 10% !important;">
                                        <th pSortableColumn="index" class="col" style="width: 5% !important;">
                                            <div class="flex justify-content-between align-items-center">
                                                #

                                            </div>
                                        </th>
                                        <th style="width: 10% !important;" pSortableColumn="name" class="col">

                                            Region

                                        </th>
                                        <th style="width: 10% !important;" pSortableColumn="code" class="col">
                                            <div class="flex justify-content-between align-items-center">
                                                Code
                                            </div>
                                        </th>
                                        <th style="width: 5% !important;" pSortableColumn="" class="col">
                                            <div class="flex justify-content-between align-items-center">
                                                Departements
                                            </div>
                                        </th>

                                        <th style="width: 5% !important;" pSortableColumn="country.name" class="col">
                                            <div class="flex justify-content-between align-items-center">
                                                Actions
                                            </div>
                                        </th>

                                </ng-template>
                                <ng-template pTemplate="body" let-regions let-rowIndex="rowIndex">
                                    <tr class="p-selectable-row" style="height: 40px;">
                                        <td>
                                            {{rowIndex + 1}}
                                        </td>
                                        <td>
                                            {{regions.libelle}}
                                        </td>
                                        <td>
                                            {{regions.code}}
                                        </td>
                                        <td routerLink="/division/region/{{regions.id}}">
                                            {{regions.departements.length}}
                                            <span style="color: rgb(4, 20, 20);"><i class="pi pi-eye"></i> {{regions.departement}}</span>
                                        </td>
                                        <td>
                                            <div class="row d-flex test">
                                                <div class="col-5">
                                                    <p-button (click)="edit(regions)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                                </div>
                                                <div class="col-5">
                                                    <p-button (click)="opens(content, regions)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-template>

                                <ng-template pTemplate="emptymessage">
                                    <tr>
                                        <td colspan="6" style="text-align: center;">Aucune region trouvée</td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </ng-container>
                    </ng-container>

                </div>


                <p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar" [baseZIndex]="10000">

                    <ng-template pTemplate="header">
                        <h5> {{title}}</h5>
                    </ng-template>

                    <ng-template pTemplate="content needs-validation">
                        <div class="container">
                            <form action="" [formGroup]="Form">
                                <div class="col-12 field">
                                    <input type="text" style="display: none;" formControlName="id" class="form-control" [(ngModel)]="prepare.id" />

                                    <div class="input-field" [class.error]="libelle.invalid && libelle.hasError('required','type')">
                                        <label class="form-label" for="form3Example3">Libelle<span class="text-danger me-1">*</span></label>
                                        <div class="input-group">
                                            <input type="text" formControlName="libelle" name="name" placeholder="Entrer le nom" class="form-control" [(ngModel)]="prepare.libelle" required />
                                        </div>
                                        <div *ngIf="libelle.invalid && (libelle.touched || libelle.dirty) ">
                                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="libelle.errors?.required ">*le libelle obligatoire</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 field ">
                                    <div>
                                        <div class="input-field " [class.error]="code.invalid && code.hasError( 'required', 'type') ">
                                            <label for="pass " class="form-label ">Code<span class="text-danger me-1 ">*</span></label>
                                            <div class="input-group">
                                                <input type="text" formControlName="code" name="code" placeholder="Entrer le code" class="form-control" [(ngModel)]="prepare.code" required />
                                            </div>
                                            <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                            <div *ngIf="code.invalid && (code.touched || code.dirty) ">
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.required ">*le code est obligatoire</span>
                                                <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.minlength ">*le code doit avoir au moins 2 charactéres</span>

                                            </div>
                                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-center" style="margin-top: 100px;">
                                    <button pButton pRipple type="button" [disabled]="submitted" (click)="visibleSidebar=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>

                                    <button *ngIf="title==='Ajouter une region'" [disabled]="submitted" pButton pRipple type="button" (click)="add()" label="Sauvegarder" class="p-button-raised"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>
                                    <button *ngIf="title==='Modifier la region'" [disabled]="submitted" pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="update()"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>


                                </div>
                            </form>

                        </div>

                    </ng-template>




                </p-sidebar>



                <ng-template #content let-modal>
                    <div class="modal-header">
                        <h3 class="modal-title" id="modal-basic-title">Voulez vous supprimer cette region</h3>
                        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
                    </div>
                    <div class="modal-body">
                        <div class="mb-3">
                            Cette action est irreversible
                        </div>
                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="err ">*{{err.message.error.message | json}}.</span>

                    </div>
                    <div class="modal-footer">
                        <button type="button" pButton class="p-button-secondary" (click)="close()" label="Annuler"></button>
                        <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

                    </div>
                </ng-template>


            </p-tabPanel>
            <p-tabPanel header="Departements">
                <app-all-departements></app-all-departements>
            </p-tabPanel>
            <p-tabPanel header="Arrondissement">
                <app-all-arondissement></app-all-arondissement>
            </p-tabPanel>
        </p-tabView>
    </main>
</section>