<section class="content main content-wrapper">


    <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
        <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
            <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.ERROR">
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                <div class="d-flex justify-content-between" style="width: 100%;height: 30px;">
                    <div>
                        <div class="text-secondary pt-4 pl-4 pb-2">
                            <h6>
                                NOM: REGION DU {{values.libelle}}
                            </h6>
                            <h6>
                                CODE: {{values.code}}
                            </h6>
                            <h6>
                                NOMBRE DE DEPARTEMENT: {{values.departements.length}}
                            </h6>


                        </div>
                    </div>
                    <div>
                        <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

                    </div>

                </div>
                <div class="d-flex justify-content-between" style="width: 100%;padding: 20px;">
                    <div></div>
                    <div>
                        <button pButton pRipple (click)="open1()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin-right: 10px;margin-top: 30px;"></button>

                    </div>


                </div>
                <div class="card">



                    <p-table styleClass="p-datatable-striped" #dt [value]="values.departements" [(selection)]="selectedDepartement" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [filterDelay]="0" [globalFilterFields]="[
              'index',
              'name',
              'code',
          ]">
                        <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                            <div class="table-header col-12 d-flex">

                                <div class="col d-flex justify-content-between align-content-center align-items-center">
                                    <div>
                                        <span class="p-input-icon-left mr-4">
                             <i class="pi pi-search"></i>
                             <input
                                 pInputText
                                 type="text"
                                 (input)="
                                     dt.filterGlobal(
                                         $event.target.value,
                                         'contains'
                                     )
                                 "
                                 placeholder="Rechercher"
                             />
                         </span>
                                    </div>
                                    <div class="test">
                                        <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                                            <p-button ngbDropdownAnchor id="dropdownManual" (click)="myDrop.open()" label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownManual">

                                                <button ngbDropdownItem>Exporter des departement</button>
                                                <button ngbDropdownItem>Exporter les commune</button>

                                            </div>
                                        </div>
                                        <div class="d-inline-block" ngbDropdown #myDrop1="ngbDropdown">
                                            <p-button ngbDropdownAnchor type="button" id="dropdownManual1" (click)="myDrop1.open()" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownManual1">

                                                <button ngbDropdownItem>Imprimer des departement</button>
                                                <button ngbDropdownItem>Imprimer les commune</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header" style="background-color: #2E394B !important">
                            <tr style="width: 10% !important;">
                                <th pSortableColumn="index" class="col" style="width: 10% !important;">
                                    <div class="flex justify-content-between align-items-center">
                                        Index

                                    </div>
                                </th>
                                <th style="width: 20% !important;" pSortableColumn="name" class="col">

                                    Departement

                                </th>
                                <th style="width: 20% !important;" pSortableColumn="code" class="col">
                                    <div class="flex justify-content-between align-items-center">
                                        Code
                                    </div>
                                </th>

                                <th style="width: 8% !important;" pSortableColumn="" class="col">
                                    <div class="flex justify-content-between align-items-center">
                                        Actions
                                    </div>
                                </th>

                        </ng-template>
                        <ng-template pTemplate="body" let-data let-rowIndex="rowIndex">
                            <tr class="p-selectable-row" routerLink="/division/departement/{{data.id}}">
                                <td>
                                    {{rowIndex + 1}}
                                </td>
                                <td>
                                    {{data.libelle}}
                                </td>
                                <td>
                                    {{data.code}}
                                </td>


                                <td>
                                    <div class="row d-flex test">
                                        <div class="col-5">
                                            <p-button (click)="edit1(data)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                        </div>
                                        <div class="col-5">
                                            <p-button (click)="opens(content, data)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                                        </div>
                                    </div>

                                </td>

                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="6">Aucune commune trouve</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </ng-container>
        </ng-container>



        <p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar" [baseZIndex]="10000">

            <ng-template pTemplate="header">
                <h4>{{title}}</h4>
            </ng-template>

            <ng-template pTemplate="content">
                <div class="container">
                    <div class="container">
                        <form action="" [formGroup]="Form">
                            <input type="text" style="display: none;" formControlName="id" name="id" [(ngModel)]="departement.id" class="form-control" />

                            <div [ngStyle]="{'display': display}" class="col-12 field">

                                <div class="input-field" [class.error]="region.invalid && region.hasError('required','type')">
                                    <label class="form-label" for="form3Example3">Region<span class="text-danger me-1">*</span></label>
                                    <div class="input-group">
                                        <select name="region" id="" formControlName="regionId" class="form-control" [(ngModel)]="values.id" required>
                                            <option *ngFor="let data of datas" value="{{data.id}}">{{data.libelle}}</option>
                                        </select>
                                    </div>
                                    <div *ngIf="region.invalid && (region.touched || region.dirty) ">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="region.errors?.required ">*la region est obligatoire</span>

                                    </div>
                                </div>
                            </div>
                            <div class="col-12 field">

                                <div class="input-field" [class.error]="libelle.invalid && libelle.hasError('required','type')">
                                    <label class="form-label" for="form3Example3">Libelle du département<span class="text-danger me-1">*</span></label>
                                    <div class="input-group">
                                        <input type="text" formControlName="libelle" name="libelle" placeholder="Entrer le nom" [(ngModel)]="departement.libelle" class="form-control" required />
                                    </div>
                                    <div *ngIf="libelle.invalid && (libelle.touched || libelle.dirty) ">
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="libelle.errors?.required ">*le libelle est obligatoire</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 field ">
                                <div>
                                    <div class="input-field " [class.error]="code.invalid && code.hasError( 'required', 'type') ">
                                        <label for="pass " class="form-label ">Code du département<span class="text-danger me-1 ">*</span></label>
                                        <div class="input-group">
                                            <input type="text" formControlName="code" name="code" placeholder="Entrer le code" class="form-control" [(ngModel)]="departement.code" required />
                                        </div>
                                        <!--<small class="p-error " *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid) ">Le pays est obligatoire.</small>-->
                                        <div *ngIf="code.invalid && (code.touched || code.dirty) ">
                                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.required ">*le code est obligatoire</span>
                                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="code.errors?.minlength ">*le code doit avoir au moins 2 charactéres</span>

                                        </div>
                                        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message}}.</span>

                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-center" style="margin-top: 100px;">
                                <button pButton pRipple type="button" [disabled]="submitted" (click)="visibleSidebar=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"> </button>

                                <button *ngIf="title==='Ajouter un departement'" [disabled]="submitted" pButton pRipple type="button" (click)="add()" label="Sauvegarder" class="p-button-raised"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>
                                <button *ngIf="title==='Modifier un departement'" [disabled]="submitted" pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="update()"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>
                            </div>
                        </form>
                    </div>

                </div>

            </ng-template>




        </p-sidebar>
        <p-confirmDialog #cd2 header="Confirmation" icon="pi pi-exclamation-triangle">
            <ng-template pTemplate="header">
                <h3>Voulez vous supprimer ce departement</h3>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton class="p-button-secondary" (click)="cd2.reject()" label="Annuler"></button>
                <button type="button" pButton class="p-button-danger" label="Supprimer" (click)="cd2.accept()"></button>
            </ng-template>
        </p-confirmDialog>

    </main>
</section>

<ng-template #content let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Voulez vous supprimer ce département?</h3>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            Cette action est irreversible
        </div>
        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="err ">*{{err.message.error.message}}.</span>

    </div>
    <div class="modal-footer">
        <button type="button" pButton class="p-button-secondary" (click)="close()" label="Annuler"></button>
        <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

    </div>
</ng-template>