import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StructureService } from '@services/structure.service';
import { Observable, catchError, map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-infos-generale',
  templateUrl: './infos-generale.component.html',
  styleUrls: ['./infos-generale.component.scss']
})
export class InfosGeneraleComponent implements OnInit{
  @Input() id: any;
  data: any;
  constructor(private service: StructureService,private route: ActivatedRoute,){}
 ngOnInit(): void {
  this.id= this.route.snapshot.paramMap.get('id');
  this.get();
 } getStatus(value: boolean){
    if(value){
      return "Fonctionnel";
    }
    else{
      return "Non Fonctionnel";
    }
   }
 get(){
  this.service.getById(this.id).subscribe(data => {
   this.data = data;
  },
  (error) => {
  });
  }
 
   
}
