import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common'
import { ConfirmationService, MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { UserService } from '@services/user.service';
@Component({
  selector: 'app-details-utilisateurs',
  templateUrl: './details-utilisateurs.component.html',
  styleUrls: ['./details-utilisateurs.component.scss'],
  providers: [ConfirmationService, MessageService],

})
export class DetailsUtilisateursComponent implements OnInit{
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
items: any[];
home: any;
id: any;
data: any;
constructor(private location: Location,private confirmationService: ConfirmationService,private route:ActivatedRoute, private messageService: MessageService,private service: UserService){}
ngOnInit(){


  this.id= this.route.snapshot.paramMap.get('id');
  this.items = [

    {label:'Utilisateurs', routerLink: '/dashboard/bord/administration/utilisateur'},
    {label:'Details utilisateurs'}
];
this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
this.get()
}
back(){
  this.location.back();
}
confirm() {
  this.confirmationService.confirm({
      message: 'Voulez vous supprimer ce centre?',

  });
}

suppression(){
  this.messageService.add({severity:'warn', summary: 'Suppression', detail: 'Utilisateur Supprimé avec succés'});
  this.confirmationService.close()

}
get(){
  this.datas$ = this.service.getUserById(this.id).pipe(
    map(data =>{
      this.data = data;
      console.log(this.data);
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );
}
}
