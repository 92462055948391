import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
 import { ToastrService } from 'ngx-toastr';
 import { AppDataState, DataStateEnum } from '@/State/load.state';
 import { catchError, map, Observable, of, startWith } from 'rxjs';
 import { FormControl, FormGroup, Validators } from '@angular/forms';
 import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TypeFormationService } from '@services/typeFormationService';
@Component({
  selector: 'app-type-formation',
  templateUrl: './type-formation.component.html',
  styleUrls: ['./type-formation.component.scss'],
  providers: [ConfirmationService]
})
export class TypeFormationComponent {
  data: any;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  submitted: boolean = false;
  typeformations: any[] | undefined;
  typeformation: any;
   visibleSidebar1: boolean = false;
   selectedtypeformation: any;
  title : string ="";
  err: any;
  val: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
    Form: any;
      get code(){return this.Form.get('code'); }
      get libelle(){return this.Form.get('libelle'); }
      get description(){return this.Form.get('description'); }
    constructor(private modalService: NgbModal,private toastr: ToastrService,private service: TypeFormationService ) {}
    ngOnInit(){
      this.Form = new FormGroup({
        "id": new FormControl(""),
        "code": new FormControl("", [Validators.required]),
        "libelle": new FormControl("", [Validators.required]),
        "description": new FormControl(""),
      })
      this.item2 = [
       
        {label:"Type Formation"},
    ];
    this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};

  
   this.get()
  
  }
  get(){
    console.log('ok')
    this.data$ = this.service.getAll().pipe(
      map(data =>{
        this.data=data;
        console.log(this.data);
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err}))
    );
   }
  
   delete(){
    this.err='';
    this.submitted = true;
      this.service.delete(this.val).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
        this.toastr.success('type de formation supprimé avec succés', 'Success');
  
      },
      (error)=>{
        this.err= error;
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
   }
   update(){
    this.subscribError = '';
    this.submitted = true;
    console.log(this.Form.value)
      this.service.update(this.Form.value).subscribe(data => {
        this.submitted = false;
        this.visibleSidebar1 = false;
        this.ngOnInit();
        this.toastr.success('niveau d\'etude modifié avec succés', 'Success');
  
      },
      (error)=>{
        this.subscribError =  error;
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
   }
   adds(){
    this.subscribError = "";

    this.submitted = true;
    console.log(this.Form.value);
      this.service.create(this.Form.value).subscribe((result)=>{
       this.submitted = false;
       this.visibleSidebar1 = false;
  
        this.ngOnInit();
        this.toastr.success('type de formation ajouté avec succées', 'Success');
  
      },
      (error)=>{
        this.subscribError = error;

       this.submitted = false;
       this.toastr.error('Nous rencontrons une erreur', 'Error');
  
      }
      )  
    
   }

    open1() {
      this.typeformation= {};
      this.title = "Ajouter un type de formation";
      this.visibleSidebar1 = true;
     }
     edit1(item: any){
      this.typeformation = {...item};
      this.title = "Modifier un type de formation";
      this.visibleSidebar1 = true;
    }
    opens(content,value:any) {
      this.modalService.open(content, { centered: true })
      this.val= {...value};

  
    }
    close(){
      this.err= "";
      this.modalService.dismissAll()      }
    
    saveRegion(){
      this.toastr.success('un type de formation a ete ajouter avec success', 'Success');
      this.visibleSidebar1 = false;
    }
      

    

}
