import { DataStateEnum, AppDataState } from '@/State/load.state';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QualificationFormationService } from '@services/qualification-formation.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, map, startWith, catchError, of } from 'rxjs';

@Component({
  selector: 'app-details-qualification',
  templateUrl: './details-qualification.component.html',
  styleUrls: ['./details-qualification.component.scss']
})
export class DetailsQualificationComponent implements OnInit{
  id: string;
  readonly DataStateEnum=DataStateEnum;
  datas$: Observable<AppDataState<any[]>> |null = null;
items: any[];
home: any;
data: any;
submitted: boolean = false;
err: any;
prepare: any;
title: any;
visible1:any;
Form:any;
 constructor(private route: ActivatedRoute,private service: QualificationFormationService,private modalService: NgbModal,private toastr: ToastrService,){}
  ngOnInit() {
    this.id= this.route.snapshot.paramMap.get('id'); 
    this.Form = new FormGroup({
      "id": new FormControl(""),
      "duree": new FormControl("", [Validators.required]),
      "specialite": new FormControl("", [Validators.required]),
      "diplomeId": new FormControl("", [Validators.required]),
      "typeFormationId": new FormControl("", [Validators.required]),
      "diplomeQualificationId": new FormControl("", [Validators.required]),
      "niveauEtudeId": new FormControl("", [Validators.required]),
    })
    this.items = [

      {label:'Qualifivation Formations', routerLink: '/qualification-formation'},
      {label:'Details'}
  ];
  this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
  this.get()
  }
  delete(){
    this.err='';
    this.submitted = true;
      this.service.delete(this.prepare).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
        this.toastr.success('la qualification pour cette formation a ete supprime');
  
      },
      (error)=>{
        this.err= error;
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
   }
   opens(content,value:any) {
    this.modalService.open(content, { centered: true })
    this.prepare= {...value};


  }
  close(){
    this.err= "";
    this.modalService.dismissAll()      }
    edit1(item: any){
      this.prepare = {...item};
      this.title = "Modifier une qualificaion pour formation";
      this.visible1 = true;
    }
get(){
  this.datas$ = this.service.getById(this.id).pipe(
    map(data =>{
      this.data = data;
      console.log(this.data);
      return ({dataState:DataStateEnum.LOADED})
    }),
    startWith({dataState:DataStateEnum.LOADING}),
    catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
  );

}}
