import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' })
};

@Injectable({
  providedIn: 'root'
})

export class ApprenantBySpecialiteService  extends BaseService<any>{
  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "structures/apprenants/stats/specialiteTypeFormationSexe"
    );
    
} }
