import { Arrondissement } from '@/models/arrondissement';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, retry, catchError, throwError } from 'rxjs';
import { BaseService } from './base.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' })
};

@Injectable({
  providedIn: 'root'
})
export class QuestionParTypeService  extends BaseService<any>{

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "questionnaires/questions/type"
    );
    
}
}
