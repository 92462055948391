import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';

import { LoginComponent } from '@pages/auth/login/login.component';
import { RegisterComponent } from '@pages/auth/register/register.component';
import { EmailActivateAccountComponent } from '@pages/auth/email-activate-account/email-activate-account.component';
import { EmailResetPasswordComponent } from '@pages/auth/email-reset-password/email-reset-password.component';
import { ResetPasswordComponent } from '@pages/auth/reset-password/reset-password.component';
import { SelectionComponent } from '@pages/selection/selection.component';
import { AutorisationsComponent } from '@pages/administration/autorisations/autorisations.component';
import { DetailAutorisationComponent } from '@pages/administration/autorisations/detail-autorisation/detail-autorisation.component';
import { ArrondissementComponent } from '@pages/administration/divisions/arrondissement/arrondissement.component';
import { DepartementComponent } from '@pages/administration/divisions/departement/departement.component';
import { DivisionsComponent } from '@pages/administration/divisions/divisions.component';
import { NotificationComponent } from '@pages/administration/notification/notification.component';
import { ParametreComponent } from '@pages/administration/parametre/parametre.component';
import { DetailsUtilisateursComponent } from '@pages/administration/utilisateurs/details-utilisateurs/details-utilisateurs.component';
import { UtilisateursComponent } from '@pages/administration/utilisateurs/utilisateurs.component';
import { QuestionRecolteDonneeComponent } from '@pages/autres/question-recolte-donnee/question-recolte-donnee.component';
import { TrancheAgeComponent } from '@pages/autres/tranche-age/tranche-age.component';
import { TypeBourseComponent } from '@pages/autres/type-bourse/type-bourse.component';
import { TypeHandicapComponent } from '@pages/autres/type-handicap/type-handicap.component';
import { DiplomeComponent } from '@pages/gestion-formation/diplome/diplome.component';
import { FiliereComponent } from '@pages/gestion-formation/filiere/filiere.component';
import { DetailFormationComponent } from '@pages/gestion-formation/formations/detail-formation/detail-formation.component';
import { FormationsComponent } from '@pages/gestion-formation/formations/formations.component';
import { NiveauEtudeComponent } from '@pages/gestion-formation/niveau-etude/niveau-etude.component';
import { ReferentielComponent } from '@pages/gestion-formation/referentiel/referentiel.component';
import { DetailSecteurPorteurComponent } from '@pages/gestion-formation/secteurs-porteurs/detail-secteur-porteur/detail-secteur-porteur.component';
import { SecteursPorteursComponent } from '@pages/gestion-formation/secteurs-porteurs/secteurs-porteurs.component';
import { StatutProfessionelComponent } from '@pages/gestion-formation/statut-professionel/statut-professionel.component';
import { QualificationFormationComponent } from '@pages/gestion-formation/qualification-formation/qualification-formation.component';
import { TypeDiplomeQualificationComponent } from '@pages/gestion-formation/type-diplome-qualification/type-diplome-qualification.component';
import { TypeFormationComponent } from '@pages/gestion-formation/type-formation/type-formation.component';
import { FonctionsComponent } from '@pages/gestion-personnel/fonctions/fonctions.component';
import { PersonnelComponent } from '@pages/gestion-personnel/personnel/personnel.component';
import { ServicesComponent } from '@pages/gestion-personnel/services/services.component';
import { QualitePersonnelComponent } from '@pages/gestion-structure/qualite-personnel/qualite-personnel.component';
import { RessourcesFinanciereComponent } from '@pages/gestion-structure/ressources-financiere/ressources-financiere.component';
import { StructuresComponent } from '@pages/gestion-structure/structures/structures.component';
import { TypePromoteurComponent } from '@pages/gestion-structure/type-promoteur/type-promoteur.component';
import { TypeStructuresComponent } from '@pages/gestion-structure/type-structures/type-structures.component';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { DetailsQualificationComponent } from '@pages/gestion-formation/qualification-formation/details-qualification/details-qualification.component';
import { ApprenantsComponent } from '@pages/gestion-structure/apprenants/apprenants.component';
import { FormateursComponent } from '@pages/gestion-structure/formateurs/formateurs.component';
import { DashboardStructureComponent } from '@pages/gestion-structure/structures/dashboard-structure/dashboard-structure.component';


const routes: Routes = [
    {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'utilisateur',
        component: UtilisateursComponent
      },
      {
        path: 'utilisateur/:id',
        component: DetailsUtilisateursComponent
      },
      {
        path: 'division',
        component: DivisionsComponent
      },
      {
        path: 'division/region/:id',
        component: DepartementComponent
      },
      {
        path: 'division/departement/:id',
        component: ArrondissementComponent
      },
      {
        path: 'autorisation/detail-autorisation/:id',
        component: DetailAutorisationComponent
      },
      {
        path: 'autorisation',
        component: AutorisationsComponent
      },
      {
        path: 'details-qualification/:id',
        component: DetailsQualificationComponent
      },
      {
        path: 'notification',
        component: NotificationComponent
      },
      {
        path: 'parametre',
        component: ParametreComponent
      },
      {
        path: 'typeHandicap',
        component: TypeHandicapComponent
      },
      {
        path: 'typeBourse',
        component: TypeBourseComponent
      },
      {
        path: 'trancheAge',
        component: TrancheAgeComponent
      },
      {
        path: 'questionnaire',
        component: QuestionRecolteDonneeComponent
      },
      {
        path: 'formation',
        component: FormationsComponent
      },
    
      {
        path: 'typeDiplomeQualification',
        component: TypeDiplomeQualificationComponent
      },
      {
        path: 'qualification-formation',
        component: QualificationFormationComponent
      },
      {
        path: 'typeFormation',
        component: TypeFormationComponent
      },
      {
        path: 'referentiel',
        component: ReferentielComponent
      },
      {
        path: 'detail-formation/:id',
        component: DetailFormationComponent
      },
      
      {
        path: 'secteurPorteur',
        component: SecteursPorteursComponent
      },
      {
        path: 'niveauxEtude',
        component: NiveauEtudeComponent
      },
      {
        path: 'diplome',
        component: DiplomeComponent
      },
      {
        path: 'filiere',
        component: FiliereComponent
      },
      {
        path: 'detail-secteur-porteur/:id',
        component: DetailSecteurPorteurComponent
      },
      {
        path: 'statutFormation',
        component: StatutProfessionelComponent
      },
      {
        path: 'personnel',
        component: PersonnelComponent
      },
      {
        path: 'services',
        component: ServicesComponent
      },
      {
        path: 'fonctions',
        component: FonctionsComponent
      },
      {
        path: 'structures',
        component: StructuresComponent
      },
      {
        path: 'dashboard-structure/:id',
        component: DashboardStructureComponent
      },
      {
        path: 'apprenants',
        component: ApprenantsComponent
      },
      {
        path: 'formateurs',
        component: FormateursComponent
      },
      {
        path: 'typeStructure',
        component: TypeStructuresComponent
      },
      {
        path: 'typePromoteurs',
        component: TypePromoteurComponent
      },
      {
        path: 'qualite-personnel-structure',
        component: QualitePersonnelComponent
      },
      {
        path: 'ressources-financiere',
        component: RessourcesFinanciereComponent
      },
    {path: 'connexion', component: LoginComponent},
    {path: 'inscription', component: RegisterComponent},
    {path: 'emailActivateAccount', component: EmailActivateAccountComponent},
    {path: 'email-reset-password', component: EmailResetPasswordComponent},
    {path: 'reset-password', component: ResetPasswordComponent},
    {path: '' , redirectTo: 'connexion', pathMatch: 'full'},
    {path:'selection', component: SelectionComponent},
    {path: '**', redirectTo: ''},
   
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
