<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <div class="col-12 d-flex">
            <div class="col text-align-start justify-content-start align-item-start d-flex">
                <p-button label="Retour" icon="pi pi-arrow-left" (click)="back()" styleClass="p-button-sm p-button-secondary"></p-button>

            </div>
            <div class="col text-align-end justify-content-end align-item-end d-flex">
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
        </div>




        <!-- ===== ===== User Main-Profile ===== ===== -->






        <!-- ===== ===== User Details Sections ===== ===== -->

        <ng-container *ngIf="datas$ | async as result" [ngSwitch]="result.dataState">
            <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                <app-spinner></app-spinner>
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.ERROR">
            </ng-container>
            <ng-container *ngSwitchCase="DataStateEnum.LOADED">
                <div class="container d-flex col-12 p-4 pb-0 pt-0">
                    <div class="card col-12 col-md-4 mr-2" style="min-height: 100px">
                        <img src="../../../../../assets/img/use.png" class="rounded" alt="profile" width="250px" height="250px">
                        <div class="col-12 d-flex justify-content-center p-3">
                            <p-button label="Modifier" icon="pi pi-pencil" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                            <p-button label="Supprimer" icon="pi pi-trash" (click)="confirm()" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>

                        </div>

                    </div>
                    <section class="userDetails card col-12 col-md-8">
                        <div class=" surface-section">
                            <div class="font-medium text-3xl text-900 mb-3">Details de l'utilisateur</div>
                            <ul class="list-none p-0 m-0">
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Noms</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.name}}</div>

                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Rôle</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
                                        <p-chip label="{{data.role}}" class="mr-2"></p-chip>

                                    </div>

                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Email</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.email}}</div>

                                </li>
                                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                    <div class="text-500 w-6 md:w-2 font-medium">Quartier</div>
                                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.city}}</div>

                                    <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
                                        <div class="text-500 w-6 md:w-2 font-medium">Téléphone</div>
                                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.phone}}</div>

                                    </li>
                                    <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
                                        <div class="text-500 w-6 md:w-2 font-medium">Date Naissance</div>
                                        <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
                                            {{data.birthday}}</div>

                                    </li>
                            </ul>
                        </div>
                    </section>
                </div>
            </ng-container>
        </ng-container>






    </p-scrollPanel>

</section>
<p-confirmDialog #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <ng-template pTemplate="header">
        <h3>Voulez vous supprimer cet utilisateurs?</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton class="p-button-secondary" (click)="cd.reject()" label="Annuler"></button>
        <button type="button" pButton class="p-button-danger" (click)="suppression()" label="Supprimer"></button>
    </ng-template>
</p-confirmDialog>
<p-toast></p-toast>