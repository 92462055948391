import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { RessourceFinancier } from '@/models/ressourceFinancier';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' })
};
@Injectable({
  providedIn: 'root'
})
export class RessourceFinancierService extends BaseService<RessourceFinancier>{

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "structures/ressourceFinancieres"
    );
    }}
