<section class="content main">


    <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
        <div class="d-flex justify-content-between" style="width: 100%;height: 20px;">
            <div>
                <div class="text-secondary pt-4 pl-4 pb-2">
                    <h6>
                        NOM: SECTEURS PRIMAIRE
                    </h6>
                    <h6>
                        CODE: CENFDI
                    </h6>


                </div>
            </div>
            <div>
                <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

            </div>

        </div>
        <div class="d-flex justify-content-between">
            <div></div>
            <div>
                <button pButton pRipple (click)="open()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin:30px"></button>

            </div>


        </div>

        <div class="card">


            <p-table styleClass="p-datatable-striped" #dt [value]="specialites" [(selection)]="selectedspecialite" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50]" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                [filterDelay]="0" [globalFilterFields]="[
          'index',
          'name',
          'code',
      ]">
                <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                    <div class="table-header col-12 d-flex">

                        <div class="col d-flex justify-content-between align-content-center align-items-center">
                            <div>
                                <span class="p-input-icon-left mr-4">
                         <i class="pi pi-search"></i>
                         <input
                             pInputText
                             type="text"
                             (input)="
                                 dt.filterGlobal(
                                     $event.target.value,
                                     'contains'
                                 )
                             "
                             placeholder="Rechercher"
                         />
                     </span>
                            </div>
                            <div>
                                <p-button id="dropdownManual" label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                <p-button type="button" id="dropdownManual1" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                            </div>

                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header" style="background-color: #2E394B !important">
                    <tr style="width: 10% !important;">
                        <th pSortableColumn="index" class="col" style="width:5% !important;">
                            <div class="flex justify-content-between align-items-center">
                                Index

                            </div>
                        </th>
                        <th style="width: 30% !important;" pSortableColumn="name" class="col">

                            Specialite

                        </th>
                        <th style="width: 30% !important;" pSortableColumn="name" class="col">

                            sigle

                        </th>
                        <th style="width: 20% !important;" pSortableColumn="code" class="col">
                            <div class="flex justify-content-between align-items-center">
                                filiere
                            </div>
                        </th>
                        <th style="width: 20% !important;" pSortableColumn="country.name" class="col">
                            <div class="flex justify-content-between align-items-center">
                                Actions
                            </div>
                        </th>

                </ng-template>
                <ng-template pTemplate="body" let-specialite>
                    <tr class="p-selectable-row">
                        <td>
                            {{specialite.index}}
                        </td>
                        <td>
                            {{specialite.name}}
                        </td>
                        <td>
                            {{specialite.name}}
                        </td>
                        <td>
                            {{specialite.code}}
                        </td>

                        <td>
                            <div class="row d-flex">
                                <div class="col-5">
                                    <p-button (click)="edit(specialite)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                </div>
                                <div class="col-5">
                                    <p-button (onClick)="confirm(specialite,cd3)" icon="pi pi-trash" styleClass="p-button-danger p-button-sm"></p-button>
                                </div>
                            </div>

                        </td>

                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">Aucune commune trouve</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>


        <p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar2" [baseZIndex]="10000">

            <ng-template pTemplate="header">
                <h4>{{title}}</h4>
            </ng-template>

            <ng-template pTemplate="content">
                <div class="container">
                    <form action="">
                        <div class="flex flex-column gap-2">
                            <label htmlFor="Dname">Secteur</label>
                            <select name="" id="" class="form-control">
                                <option value="0">Primaire</option>
                                <option value="1">Numerique</option>
                                <option value="2">Energie</option>
                                <option value="3">BTP</option>
                            </select>
                            <small class="p-error" *ngIf="submitted && !specialite.secteurPorteur">Name is required.</small>
                        </div>
                        <div class="flex flex-column gap-2">
                            <label htmlFor="Dname">Referentiel</label>
                            <select name="" id="" class="form-control">
                                <option value="0">Select One</option>
                                <option value="1">Referentiel du DA</option>
                                <option value="2">Referentiel du GP</option>
                            </select>
                            <small class="p-error" *ngIf="submitted && !specialite.referentiel">Name is required.</small>
                        </div>
                        <div class="flex flex-column gap-2">
                            <label htmlFor="Dname">Noms</label>
                            <input pInputText id="Rname" [(ngModel)]="specialite.name" required autofocus value={{specialite.name}} />
                            <small class="p-error" *ngIf="submitted && !specialite.name">Name is required.</small>
                        </div>
                        <div class="flex flex-column gap-2">
                            <label htmlFor="Rcode">Code</label>
                            <input pInputText id="Rcode" [(ngModel)]="specialite.code" value={{specialite.code}} required autofocus />
                            <small class="p-error" *ngIf="submitted && !specialite.code">Code is required.</small>
                        </div>




                    </form>



                    <div class="d-flex align-items-center justify-content-center" style="margin-top: 200px;">
                        <button pButton pRipple type="button" (click)="visibleSidebar2=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>

                        <button pButton pRipple type="button" label="Sauvegarder" class="p-button-raised" (click)="saveRegion()"></button>
                    </div>
                </div>

            </ng-template>




        </p-sidebar>
        <p-confirmDialog #cd3 header="Confirmation" icon="pi pi-exclamation-triangle">
            <ng-template pTemplate="header">
                <h3>Voulez vous supprimer cet arrondissement</h3>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton class="p-button-secondary" (click)="cd3.reject()" label="Annuler"></button>
                <button type="button" pButton class="p-button-danger" label="Supprimer" (click)="cd3.accept()"></button>
            </ng-template>
        </p-confirmDialog>

    </main>
</section>