<main class="containe">

    <div class="container-fluid" id="cont" style="height: 100%;">
        <div style="width: 100%;height: 100%;">
            <img src="../../../assets/svg/hero-bg-shape.svg" style="position: absolute;bottom: 0px;right: 0px;" alt="" srcset="">
            <div style="width: 100%;height: 100%;display: flex;align-items: center;justify-content: center">
                <div class="cont">
                    <div class="left" style="align-items: center;justify-content: center;display: flex;">
                        <div class="pt-4 pb-2">
                            <h1 class="card-title text-Start pb-0 fs-1 size2" style="color: white;">MoobilPay Reset !</h1>
                            <p class="text-Start small size1" style="color: white;">Thank's</p>
                        </div>
                    </div>
                    <div class="rigth" style="align-items: center;justify-content: center;display: flex;">

                        <div class="card col bg-white shadow-md p-5" style="min-width: 318px;max-width: 640px;">
                            <div class="mb-4 text-center">
                                <img src="../../../assets/images/email.jpg" width="65px" height="65px" alt="" srcset="">

                            </div>
                            <div class="text-center">
                                <h1>Enter Your E-mail !</h1>
                                <p><b><i> An email will be sent to you</i></b> </p>
                                <div class="col-12 ">
                                    <form [formGroup]="emailResetPassword">
                                        <div class="col-12 d-flex">
                                            <div class="input-field col-10 p-0" [class.error]="email.invalid && email.hasError('required','type')">
                                                <input type="text" formControlName="email" class="form-control" [ngClass]="{'ng-dirty': (email.invalid && submitted) || (email.dirty && email.invalid)}" id="email">
                                                <!--<small class="p-error" *ngIf="(country.invalid && submitted )|| (country.dirty && country.invalid)">Le pays est obligatoire.</small>-->
                                                <div *ngIf="email.invalid && (email.touched || email.dirty)">
                                                    <span class="error fs-6 text-danger mx-2 my-2" *ngIf="email.errors?.required">*The E-mail is required</span>
                                                </div>
                                            </div>
                                            <div class="col-2 p-0">
                                                <button class="btn btn-primary w-100" [disabled]="submitted" (click)="resendlink()" label="Send">Send<div *ngIf="submitted" class="spinner-border spinner-border-sm" role="status"></div></button>

                                            </div>
                                        </div>

                                    </form>
                                    <p class="text-danger">{{err?.message}}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</main>