import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ConfirmationService } from 'primeng/api';
 import { ToastrService } from 'ngx-toastr';
 import { AppDataState, DataStateEnum } from '@/State/load.state';
 import { catchError, map, Observable, of, startWith } from 'rxjs';
 import { FormControl, FormGroup, Validators } from '@angular/forms';
 import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DiplomeQualificationService } from '@services/diplomeQualification.service';
import { FiliereService } from '@services/filiere.service';
import { SecteurService } from '@services/secteur.service';
import { SpecialiteService } from '@services/specialite.service';
import { DiplomeService } from '@services/diplome.service';
import { TypeFormationService } from '@services/typeFormationService';
import { NiveauEtudeService } from '@services/niveauEtude.service';
import { QualificationFormationService } from '@services/qualification-formation.service';


@Component({
  selector: 'app-qualification-formation',
  templateUrl: './qualification-formation.component.html',
  styleUrls: ['./qualification-formation.component.scss'],
  providers: [ConfirmationService],
})
export class QualificationFormationComponent {
  data: any;
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  submitted: boolean = false;
   visibleSidebar1: boolean = false;
   selectedtypeformation: any;
  title : string ="";
  err: any;
  val: any;
    statuses: any[];
    item2: any[];
    home: any;
    loading: boolean = true;
    activityValues: number[] = [0, 100];
    items: [] | undefined;
    invalid= false;
    subscribError: any;
    nameInvalidError: any;
    codeInvalidError: any;
  specialites: any;
  diplomes: any;
  niveaux:any;
  diplomeQualifcations: any
  typesformations:any;
  prepare: any;
    Form: any;
      get id(){return this.Form.get('id'); }
      get duree(){return this.Form.get('duree'); }
      get specialite(){return this.Form.get('specialite'); }
      get diplome(){return this.Form.get('diplomeId'); }
      get typeformation(){return this.Form.get('typeFormationId'); }
      get diplomeQualifcation(){return this.Form.get('diplomeQualificationId'); }
      get niveau(){return this.Form.get('niveauEtudeId'); }
    constructor(private modalService: NgbModal,private toastr: ToastrService,private serviceSpecialite: SpecialiteService,private serviceDiplome: DiplomeService,private serviceTypeformation: TypeFormationService,private seviceQualification: DiplomeQualificationService, private serviceNiveau: NiveauEtudeService,private service: QualificationFormationService) {}
    ngOnInit(){
      this.Form = new FormGroup({
        "id": new FormControl(""),
        "duree": new FormControl("", [Validators.required]),
        "specialite": new FormControl("", [Validators.required]),
        "diplomeId": new FormControl("", [Validators.required]),
        "typeFormationId": new FormControl("", [Validators.required]),
        "diplomeQualificationId": new FormControl("", [Validators.required]),
        "niveauEtudeId": new FormControl("", [Validators.required]),
      })
      this.item2 = [
       
        {label:"Qualification pour formation"},
    ];
    this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
    
  
   this.get();
   this.getSpecialite();
  this.getDiplome();
  this.gettypeformation();
  this.getDiplomeQualification();
  this.getNiveau();
  }
  get(){
    console.log('ok')
    this.data$ = this.service.getAll().pipe(
      map(data =>{
        this.data=data;
        console.log(this.data);
        return ({dataState:DataStateEnum.LOADED})
      }),
      startWith({dataState:DataStateEnum.LOADING}),
      catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err}))
    );
   }
  getSpecialite(){
    this.serviceSpecialite.getAll().subscribe(data => {
     this.specialites = data;
    },
    (error) => {
    });
    }
  getDiplome(){
      this.serviceDiplome.getAll().subscribe(data => {
       this.diplomes = data;
      },
      (error) => {
      });
      }
  gettypeformation(){
        this.serviceTypeformation.getAll().subscribe(data => {
         this.typesformations = data;
        },
        (error) => {
        });
        }
  getDiplomeQualification(){
          this.seviceQualification.getAll().subscribe(data => {
           this.diplomeQualifcations = data;
          },
          (error) => {
          });
          }
  getNiveau(){
            this.serviceNiveau.getAll().subscribe(data => {
             this.niveaux = data;
            },
            (error) => {
            });
            }
    
   delete(){
    this.err='';
    this.submitted = true;
      this.service.delete(this.prepare).subscribe(data => {
        this.submitted = false;
        this.modalService.dismissAll();
        this.ngOnInit();
        this.toastr.success('la qualification pour cette formation a ete supprime');
  
      },
      (error)=>{
        this.err= error;
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
   }
   update(){
    this.subscribError = '';
    this.submitted = true;
    console.log(this.Form.value)
      this.service.update(this.Form.value).subscribe(data => {
        this.submitted = false;
        this.visibleSidebar1 = false;
        this.ngOnInit();
        this.toastr.success('la qualification pour formation a ete modifier');
  
      },
      (error)=>{
        this.subscribError =  error;
        this.submitted = false;
        this.toastr.error('Nous rencontrons une erreur', 'Error');
      })
   }
   adds(){
    this.subscribError = "";

    this.submitted = true;

      this.service.create(this.Form.value).subscribe((result)=>{
       this.submitted = false;
       this.visibleSidebar1 = false;
  
        this.ngOnInit();
        this.toastr.success('la qualification pour formation a ete ajouter');
  
      },
      (error)=>{
        this.subscribError = error;

       this.submitted = false;
       this.toastr.error('Nous rencontrons une erreur', 'Error');
  
      }
      )  
    
   }

    open1() {
      this.prepare= {};
      this.title = "Ajouter une qualification pour formation";
      this.visibleSidebar1 = true;
     }
     edit1(item: any){
      this.prepare = {...item};
      this.title = "Modifier une qualificaion pour formation";
      this.visibleSidebar1 = true;
    }
    opens(content,value:any) {
      this.modalService.open(content, { centered: true })
      this.prepare= {...value};

  
    }
    close(){
      this.err= "";
      this.modalService.dismissAll()      }
      }
      
