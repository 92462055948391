import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Parameter } from '../models/parameter';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class TypeBourseService extends BaseService<Parameter> {

  constructor(private httpCient: HttpClient) {
    super(
      httpCient,
      "autres/typeBourses"
    );
}


}
