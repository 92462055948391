<ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
        <app-spinner></app-spinner>
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.ERROR">
        <div class="alert alert-danger" role="alert">
            code : {{ result.errorMessage.code }} Nous rencontrons une erreur
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.LOADED">
        <div class="card">
            <div class="container">
                <div class="surface-section">
                    <div class="font-medium text-800 mb-3">
                        <h5 style="padding-top: 10px;">INFORMATIONS SUR LES APPRENANTS</h5>
                    </div>
                    <p-accordion [activeIndex]="0">
                        <p-accordionTab header="Effectif des apprenants par specialite et formation et type de formation">
                            <table class="table table-condensed table-bordered">
                                <thead>

                                    <tr>
                                        <th rowspan="2">Specialites</th>
                                        <th colspan="2" scope="colgroup" *ngFor="let item of types">{{item.libelle}} ({{item.code}})</th>
                                    </tr>
                                    <tr>
                                        <th scope="col" *ngFor="let item of sexes">{{item}}</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of data let i = index">

                                        <td>{{item.specialite.libelle}}</td>
                                        <td *ngFor="let item of table[i]">
                                            {{item}}
                                        </td>
                                        <!-- <app-effectifs *ngFor="let value of types  let i = index" [m]="item.effectifs[i].m" [f]="item.effectifs[i].f"></app-effectifs> -->
                                        <!--                                        <td *ngIf="item.effectifs[0].typeFormation">{{item.effectifs[0].m}}</td>
                                        <td *ngIf="item.effectifs[0].typeFormation">{{item.effectifs[0].f}}</td>
                                        <td *ngIf="item.effectifs[1].typeFormation">{{item.effectifs[1].m}}</td>
                                        <td *ngIf="item.effectifs[1].typeFormation">{{item.effectifs[1].f}}</td>
                                        <td *ngIf="item.effectifs[2].typeFormation">{{item.effectifs[2].m}}</td>
                                        <td *ngIf="item.effectifs[2].typeFormation">{{item.effectifs[2].f}}</td>*/ -->
                                    </tr>
                                </tbody>
                            </table>
                        </p-accordionTab>

                        <p-accordionTab header="Effectifs des apprenants par tranche d'age et par sexe">
                            <app-apprenant-tranche-age [id]="id"></app-apprenant-tranche-age>
                        </p-accordionTab>
                        <p-accordionTab header="Effectifs des entrants, des sortants et Abandons par niveau pour l'annee anterieure">
                            <app-apprenant-niveau-etude [id]="id"></app-apprenant-niveau-etude>
                        </p-accordionTab>
                        <p-accordionTab header="Effectifs des apprenants par annee d'etude (pour la formation initiale) et par sexe">
                            <app-apprenant-niveau-etude [id]="id"></app-apprenant-niveau-etude>

                        </p-accordionTab>

                        <p-accordionTab header="Effectifs des apprenants par type de handicape">
                            <table class="table table-condensed table-bordered">
                                <thead>


                                    <tr>
                                        <th rowspan="3">Type d'handicap</th>
                                        <th colspan="3" scope="colgroup">Entrants</th>
                                        <th colspan="3" scope="colgroup">Sortants</th>
                                        <th colspan="3" scope="colgroup">Abandons</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Moteur (ou physique)/Mobility (physical) impairement</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Visuel/Visual impairment</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Auditif/Hearing impairement</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Polyhandicarpes/Polyhandicapped</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Autres/Others</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </p-accordionTab>

                        <p-accordionTab header="Effectifs des apprenants par nature de vulnerabilite">
                            <table class="table table-condensed table-bordered">
                                <thead>


                                    <tr>
                                        <th rowspan="3">Nature de vulnerabilite</th>
                                        <th colspan="3" scope="colgroup">Entrants</th>
                                        <th colspan="3" scope="colgroup">Sortants</th>
                                        <th colspan="3" scope="colgroup">Abandons</th>
                                    </tr>
                                    <tr>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>

                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Refugiees/Refugees</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Enfants Orphelins vulnerables/Vulnerables orphande childs</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Deplaces internes/Internally displaced people</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </p-accordionTab>
                        <p-accordionTab header="Effectifs des entrants et sortants par secteur d'activite">
                            <table class="table table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th rowspan="2">Secteurs d'activite</th>
                                        <th colspan="2" scope="colgroup">Entrants</th>
                                        <th colspan="2" scope="colgroup">Sortants</th>

                                    </tr>
                                    <tr>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>

                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Primaire/Primary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Secondaire/Secondary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Tetiaire/Tetiary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </p-accordionTab>
                        <p-accordionTab header="Effectifs des sortants par specialite">
                            <table class="table table-condensed table-bordered">
                                <thead>
                                    <tr>
                                        <th>Specialite</th>
                                        <th>Homme</th>
                                        <th>Femme</th>
                                        <th>Total</th>
                                    </tr>
                                    <tr>

                                </thead>
                                <tbody>
                                    <tr>

                                    </tr>
                                </tbody>
                            </table>
                        </p-accordionTab>
                        <p-accordionTab header="Effectifs des apprenants par types de bourse de formation octroyees et effectivement beneficies">
                            <table class="table table-condensed table-bordered">
                                <thead>


                                    <tr>
                                        <th rowspan="3">Type de bourse</th>
                                        <th colspan="3" scope="colgroup">Octroyee</th>
                                        <th colspan="3" scope="colgroup">Beneficiee</th>

                                    </tr>
                                    <tr>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                        <th scope="col">Total</th>


                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Bourse offertes par le MINEFOP/Scholarships offered by the MINEFOP</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Bourse offertes par autres administrations/Scholarships offered by other administration</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Bourse offertes international/Internationales scholarships </td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Autres/Other</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Total</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </p-accordionTab>

                        <p-accordionTab header="Effectifs des entrants et sortants par secteur d'activite pour l'annee anterieure">

                            <table class="table table-condensed table-bordered">
                                <thead>


                                    <tr>
                                        <th rowspan="2">Secteur d'activite</th>
                                        <th colspan="2" scope="colgroup">Entrants</th>
                                        <th colspan="2" scope="colgroup">Sortants</th>

                                    </tr>
                                    <tr>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>

                                        <th scope="col">H</th>
                                        <th scope="col">F</th>



                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Primaire/Primary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Secondaire/Secondary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Tertiare/Tertiary</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td>Totale</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                        </p-accordionTab>
                        <p-accordionTab header="Effectifs des entrants et sortants par filiere pour l'annee anterieure">

                            <table class="table table-condensed table-bordered">
                                <thead>


                                    <tr>
                                        <th rowspan="2">Filiere</th>
                                        <th colspan="2" scope="colgroup">Entrants</th>
                                        <th colspan="2" scope="colgroup">Sortants</th>

                                    </tr>
                                    <tr>
                                        <th scope="col">H</th>
                                        <th scope="col">F</th>

                                        <th scope="col">H</th>
                                        <th scope="col">F</th>
                                </thead>
                                <tbody>
                                    <tr>

                                    </tr>
                                </tbody>
                            </table>
                        </p-accordionTab>

                    </p-accordion>
                </div>
            </div>

        </div>
    </ng-container>
</ng-container>