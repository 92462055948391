<div class="card">

    <div class="container">
        <div class="surface-section">
            <div class="font-medium text-800 mb-3">
                <h5 style="padding-top: 10px;">INFORMATIONS GENERALES</h5>
            </div>
            <ul class="list-none p-0 m-0">
                <li class="flex align-items-center py-3 px-2 order-top-1 surface-border flex-wrap justify-content-between">
                    <div class="text-600 w-6 md:w-2 font-medium" style="margin-right: 30px;">Nom du Centre</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.name}}</div>
                </li>
                <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                    <div class="text-600 w-6 md:w-2 font-medium">Situation du CFP</div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{getStatus(data.estFonctionnel)}}</div>
                </li>
                <li *ngIf="data.estFonctionnel == false" class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap justify-content-between">
                    <div class="text-600 w-6 md:w-2 font-medium">Raison </div>
                    <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">{{data.situationStructure.libelleFR}}</div>
                </li>

            </ul>
        </div>
    </div>
    <div class="mapouter">
        <div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=ktc-center&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a><br>
            <style>
                .mapouter {
                    position: relative;
                    text-align: right;
                    height: 510px;
                    width: 770px;
                }
            </style><a href="https://embedgooglemap.2yu.co">html embed google map</a>
            <style>
                .gmap_canvas {
                    overflow: hidden;
                    background: none!important;
                    height: 510px;
                    width: 770px;
                }
            </style>
        </div>
    </div>
</div>