import { Component, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { UserService } from '@services/user.service';
import { ToastrService } from 'ngx-toastr';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegionsService } from '@services/regions.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AutorisationService } from '@services/autorisation.service';

@Component({
  selector: 'app-utilisateurs',
  templateUrl: './utilisateurs.component.html',
  styleUrls: ['./utilisateurs.component.scss'],
  providers: [ConfirmationService],

})
export class UtilisateursComponent implements OnInit{
  data$: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
  Form: FormGroup
  statusData: any;
  customers: any[];
  err:any;
  visibleSidebar: boolean = false;
   selectedCustomers: any[];

   representatives: any[];
   submitted: boolean = false;
   val:any;
  roles: any;
   statuses: any[];
   items: any[];
   home: any;
   loading: boolean = true;
   data: any;
   activityValues: number[] = [0, 100];
   get name(){return this.Form.get('name'); }
   get email(){return this.Form.get('email'); }
   get password(){return this.Form.get('password'); }
   get confirmPassword(){return this.Form.get('confirmPassword'); }
   get roleId(){return this.Form.get('roleId'); }

   constructor(private confirmationService: ConfirmationService, private service: UserService,private toastr: ToastrService, private services: AutorisationService) {}


     ngOnInit(){
      this.items = [

        {label:'Utilisateurs'},
    
    ];
    this.home = {icon: 'pi pi-home', routerLink: '/dashboard'};
    this.Form = new FormGroup({
      "id": new FormControl(""),
      "name": new FormControl("", [Validators.required]),
      "email": new FormControl("", [Validators.required]),
      "password": new FormControl("", [Validators.required,Validators.minLength(8)]),
      "confirmPassword": new FormControl("", [Validators.required,Validators.minLength(8)]),
      "roleId": new FormControl("", [Validators.required]),


    })
  this.get();
  this.getRole();
    }


generatePdf(){
  const doc = new jsPDF();
  autoTable(doc, {
    body: [
      [
        {
          content: 'Mincontrol',
          styles: {
            halign: 'left',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        },
        {
          content: 'KTC-MANAGER' ,
          styles: {
            halign: 'right',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
    styles: {
      fillColor: '#3366ff'
    }
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
      ]
    ]
  });
  autoTable(doc, { html: '#my-table' })

  doc.save('table.pdf')

}
printPdf(){
  const doc = new jsPDF();
  autoTable(doc, {
    body: [
      [
        {
          content: 'Mincontrol',
          styles: {
            halign: 'left',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        },
        {
          content: 'KTC-MANAGER' ,
          styles: {
            halign: 'right',
            fontSize: 20,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
    styles: {
      fillColor: '#3366ff'
    }
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#000000'
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'left',
            fontSize: 18,
            textColor: '#FFFFFF'
          }
        }
      ],
    ],
    theme: 'plain',
  });
  autoTable(doc, {
    body: [
      [
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
        {
          content: 'Reference: #152dsf5'
          +'\nDate: 2023-12-06'
          +'\nID Facture: 202306'
          ,
          styles: {
            halign: 'right',
            fontSize: 18,
          }
        },
      ]
    ]
  });
  autoTable(doc, { html: '#my-table' })

}
get(){
  this.statusData = 'load'

  this.val= this.service.getAll().subscribe((data) => {
     this.data = data;
     this.statusData = 'load'
     console.log(this.data)

   },
   (error) => {
     this.statusData = 'error'

  },
  () => {
   this.statusData = 'complete'

   });
 }
 getRole(){
 this.val= this.services.getAll().subscribe((data) => {
    this.roles = data;
    console.log(this.roles)
  },
  (error) => {
 },
 () => {
  });
 }
 delete(){
  this.submitted = true;
    this.service.delete(this.val).subscribe(data => {
      this.submitted = false;
      this.confirmationService.close();
      this.ngOnInit();
      this.toastr.success('parametre supprimé avec succés', 'Success');

    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Nous rencontrons une erreur', 'Error');
    })
 }
 update(){
  this.submitted = true;
  console.log(this.Form.value)
    this.service.update(this.Form.value).subscribe(data => {
      this.submitted = false;
      this.visibleSidebar = false;
      this.ngOnInit();
      this.toastr.success('parametre modifié avec succés', 'Success');

    },
    (error)=>{
      this.submitted = false;
      this.toastr.error('Nous renvontrons une erreur', 'Error');
    })
 }
 clear(){
  this.visibleSidebar=false;
  this.Form.reset();
 }
 add(){
  this.submitted = true;
  console.log(this.Form);
  if( this.Form.valid){
    this.service.create(this.Form.value).subscribe((result)=>{
     this.submitted = false;
     this.visibleSidebar = false;

      this.ngOnInit();
      this.toastr.success('utilisateur ajouté avec succées', 'Success');

    },
    (error)=>{
     this.submitted = false;
     this.err = error;
     this.toastr.error('Nous rencontrons une erreur', 'Error');

    }
    )
  }
 }
}
