<section class="content main content-wrapper">
    <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
        <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg">
            <div class="d-flex justify-content-between" style="width: 100%;height: 30px;padding: 20px;">
                <div></div>
                <div>
                    <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

                </div>

            </div>
            <div class="d-flex justify-content-between" style="width: 100%;height: 110px;padding: 20px;">
                <div class="text-secondary pt-4 pl-4 pb-2">
                    <h4>
                        LISTE DES QUALIFICATIONS POUR FORMATION
                    </h4>


                </div>
                <div>
                    <button pButton pRipple (click)="open1()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button" style="margin-right: 10px;margin-top: 30px;"></button>

                </div>


            </div>

            <div class="card">
                <ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
                    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
                        <app-spinner></app-spinner>
                    </ng-container>
                    <ng-container *ngSwitchCase="DataStateEnum.ERROR">
                        <div class="alert alert-danger" role="alert">
                            code : {{ result.errorMessage.code }} Nous rencontrons une erreur
                        </div>
                    </ng-container>
                    <ng-container *ngSwitchCase="DataStateEnum.LOADED">

                        <p-table styleClass="p-datatable-striped" #dt [value]="data" [(selection)]="selectedtypeformation" dataKey="id" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [filterDelay]="0" [globalFilterFields]="[
            'index',
            'name',
            
            ]">
                            <ng-template pTemplate="caption" style="background-color: #2E394B !important">
                                <div class="table-header col-12 d-flex">

                                    <div class="col d-flex justify-content-between align-content-center align-items-center">
                                        <div>
                                            <span class="p-input-icon-left mr-4">
                         <i class="pi pi-search"></i>
                         <input
                             pInputText
                             type="text"
                             (input)="
                                 dt.filterGlobal(
                                     $event.target.value,
                                     'contains'
                                 )
                             "
                             placeholder="Rechercher"
                         />
                     </span>
                                        </div>
                                        <div class="test">

                                            <p-button label="Exporter" icon="pi pi-file-pdf" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                            <p-button type="button" id="dropdownManual1" label="Imprimer" icon="pi pi-print" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                                        </div>


                                    </div>

                                </div>



                            </ng-template>
                            <ng-template pTemplate="header" style="background-color: #2E394B !important">
                                <tr style="width: 10% !important;">
                                    <th pSortableColumn="index" class="col" style="width: 10% !important;">
                                        <div class="flex justify-content-between align-items-center">
                                            #

                                        </div>
                                    </th>
                                    <th style="width: 20% !important;" pSortableColumn="name" class="col">

                                        SPECIALITE

                                    </th>
                                    <th style="width: 20% !important;" pSortableColumn="code" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            DIPLOME OBTENUE
                                        </div>
                                    </th>

                                    <th style="width: 20% !important;" pSortableColumn="code" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            DIPLOME REQUIS
                                        </div>
                                    </th>



                                    <th style="width: 13% !important;" pSortableColumn="" class="col">
                                        <div class="flex justify-content-between align-items-center">
                                            ACTIONS
                                        </div>
                                    </th>

                            </ng-template>
                            <ng-template pTemplate="body" let-type let-rowIndex="rowIndex">
                                <tr class="p-selectable-row">
                                    <td>
                                        {{rowIndex + 1}}
                                    </td>
                                    <td>
                                        {{type.specialite}}
                                    </td>
                                    <td>
                                        {{type.diplomeQualification.libelle}}
                                    </td>
                                    <td>
                                        {{type.diplome.libelle}}
                                    </td>


                                    <td>
                                        <div class="row d-flex test">
                                            <div class="col-5"><span style="color: rgb(4, 20, 20);"><i class="pi pi-eye" routerLink="/details-qualification/{{type.id}}"></i></span></div>

                                            <div class="col-5">

                                                <p-button (click)="edit1(type)" icon="pi pi-pencil" styleClass="p-button-warning p-button-sm"></p-button>
                                            </div>

                                        </div>

                                    </td>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5" style="text-align: center;">Aucun diplome trouvé    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </ng-container>
                </ng-container>



            </div>



        </main>
    </p-scrollPanel>


</section>
<p-sidebar position="right" [style]="{width: '40%'}" [(visible)]="visibleSidebar1" [baseZIndex]="10000">

    <ng-template pTemplate="header">
        <h4>{{title}}</h4>
    </ng-template>

    <ng-template pTemplate="content">
        <div class="container">
            <div class="container">
                <form action="" [formGroup]="Form">

                    <div class="col-12 field">
                        <input type="text" formControlName="id" style="display: none;" class="form-control" required />
                    </div>
                    <div class="input-field" [class.error]="specialite.invalid && specialite.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Specialite<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <select name="specialite" id="specialite" formControlName="specialite" class="form-control" required>
                                <option *ngFor="let data of specialites" value="{{data.id}}">{{data.libelle}}</option>
                            </select>
                        </div>
                        <div *ngIf="specialite.invalid && (specialite.touched || specialite.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="specialite.errors?.required ">*la specialite est obligatoire</span>

                        </div>
                    </div>
                    <div class="input-field" [class.error]="typeformation.invalid && typeformation.hasError('required','type')">
                        <label class="form-label" for="form3Examtypeformationple3">Type de formation<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <select name="typeFormationId" id="typeFormationId" formControlName="typeFormationId" class="form-control" required>
                                <option *ngFor="let data of typesformations" value="{{data.id}}">{{data.libelle}}</option>
                            </select>

                        </div>
                        <div *ngIf="diplome.invalid && (diplome.touched || diplome.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="diplome.errors?.required ">*le type de formation est obligatoire</span>

                        </div>
                    </div>
                    <div class="input-field" [class.error]="niveau.invalid && niveau.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Diplome requis<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <select name="diplome" id="diplomeId" formControlName="diplomeId" class="form-control" required>
                                <option *ngFor="let data of diplomes" value="{{data.id}}">{{data.libelle}}</option>
                            </select>
                        </div>
                        <div *ngIf="diplome.invalid && (diplome.touched || diplome.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="diplome.errors?.required ">*le diplome est obligatoire</span>

                        </div>
                    </div>
                    <div class="input-field" [class.error]="niveau.invalid && niveau.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Niveau D'etude<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <select name="niveau" id="niveauId" formControlName="niveauEtudeId" class="form-control" required>
                                <option *ngFor="let data of niveaux" value="{{data.id}}">{{data.libelle}}</option>
                            </select>
                        </div>
                        <div *ngIf="niveau.invalid && (niveau.touched || niveau.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="niveau.errors?.required ">*le niveau d'etude est obligatoire</span>

                        </div>
                    </div>
                    <div class="input-field" [class.error]="diplomeQualifcation.invalid && diplomeQualifcation.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Diplome de qualifications<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <select name="diplomeQualifcation" id="diplomeQualifcationId" formControlName="diplomeQualificationId" class="form-control" required>
                                <option *ngFor="let data of diplomeQualifcations" value="{{data.id}}">{{data.libelle}}</option>
                            </select>

                        </div>
                        <div *ngIf="diplomeQualifcation.invalid && (diplomeQualifcation.touched || diplomeQualifcation.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="diplomeQualifcation.errors?.required ">*le diplome de qualification est obligatoire</span>

                        </div>
                    </div>


                    <div class="input-field" [class.error]="duree.invalid && duree.hasError('required','type')">
                        <label class="form-label" for="form3Example3">Duree<span class="text-danger me-1">*</span></label>
                        <div class="input-group">
                            <input type="text" formControlName="duree" name="duree" placeholder="Entrer le libelle" class="form-control" required />
                        </div>
                        <div *ngIf="duree.invalid && (duree.touched || duree.dirty) ">
                            <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="duree.errors?.required ">*la duree est obligatoire</span>
                        </div>
                    </div>
                    <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="subscribError ">*{{subscribError?.message.error.message}}.</span>

                    <div class="d-flex align-items-center justify-content-center" style="margin-top: 100px;">
                        <button pButton pRipple type="button" [disabled]="submitted" (click)="visibleSidebar1=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>

                        <button *ngIf="title==='Ajouter une qualification pour formation'" [disabled]="submitted" pButton pRipple type="button" (click)="adds()" label="Sauvegarder" class="p-button-raised"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>
                        <button *ngIf="title==='Modifier une qualificaion pour formation'" [disabled]="submitted" pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="update()"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

                    </div>
                </form>
            </div>

        </div>

    </ng-template>




</p-sidebar>


<ng-template #content let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title">Voulez vous supprimer ce diplome?</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
        <div class="mb-3">
            Cette action est irreversible
        </div>
        <span class="error fs-6 text-danger mx-2 my-2 " *ngIf="err ">*{{err?.message.error.message}}.</span>

    </div>
    <div class="modal-footer">
        <button type="button" pButton class="p-button-secondary" (click)="close()" label="Annuler"></button>
        <button type="button" [disabled]="submitted" (click)="delete()" pButton class="p-button-danger" label="Supprimer"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>

    </div>
</ng-template>