<section class="content main content-wrapper">


    <main class="main-content position-relative max-height-vh-100 h-50 mt-1 border-radius-lg">
        <div class="d-flex justify-content-between" style="width: 100%;height: 60px;">
            <div>
                <div class="text-secondary pt-3 pl-4 pb-4">
                    <h4>
                        QUESTIONAIRE DE DONNEES
                    </h4>


                </div>
            </div>
            <div>
                <p-breadcrumb [model]="item2" [home]="home" class="bg-transparent"></p-breadcrumb>

            </div>

        </div>
        <p-tabView>
            <p-tabPanel header="Type Question">
                <app-types-question></app-types-question>
            </p-tabPanel>
            <p-tabPanel header="Question">
                <app-question></app-question>
            </p-tabPanel>
            <p-tabPanel header="Proposition Reponse">
                <app-proposition-reponse></app-proposition-reponse>
            </p-tabPanel>
            <p-tabPanel header="Reponse">
                <app-reponse></app-reponse>
            </p-tabPanel>
        </p-tabView>
    </main>
</section>