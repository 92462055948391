

<p-tabView>
        
   
    <section class="content main content-wrapper">
        
        <p-scrollPanel [style]="{width: '100%', height: '100%'}" styleClass="custombar1">
            <div class="col-12 text-align-end justify-content-end align-item-end d-flex">
                <p-breadcrumb [model]="items" [home]="home"></p-breadcrumb>
            </div>
            <div class="col-12 d-flex justify-content-between pt-0">
                <div class="p-0">
                    <h2>
                        Informations sur les formateurs
                    </h2>
                </div>
                
            </div>
            <!--
            <main class="main-content border-radius-lg">

                <div class="container">
                    <div class="row">
                      <div class="col-sm">
                        <div class="row">
                            <p-button label="Formateurs Diplomes" (onClick)="getData()" icon="pi pi-user" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                        </div>
                        <div class="row">
                            <p-button label="Formateurs Diplomes" (onClick)="getData()" icon="pi pi-user" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                        </div>
                        <div class="row">
                            <p-button label="Formateurs Diplomes" (onClick)="getData()" icon="pi pi-user" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                        </div>
                        <div class="row">
                            <p-button label="Formateurs Diplomes" (onClick)="getData()" icon="pi pi-user" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                        </div>
                        <div class="row">
                            <p-button label="Formateurs Diplomes" (onClick)="getData()" icon="pi pi-user" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                        </div>
                        <div class="row">
                            <p-button label="Formateurs Diplomes" (onClick)="getData()" icon="pi pi-check" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                        </div>
        
                      </div>
                      <div class="col-sm">
                        <p-table styleClass="p-datatable-striped" [value]="formateurs" [tableStyle]="{ 'min-width': '50rem' }">
                            <ng-template pTemplate="header" style="background-color: #2E394B !important">
                                
                                <tr>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Country</th>
                                    <th>Company</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-formateur>
                                <tr>
                                    <td>{{ formateur.name }}</td>
                                    <td>{{ formateur.status }}</td>
                                    <td>{{ formateur.country.name }}</td>
                                    <td>{{ formateur.company }}</td>
                                </tr>
                            </ng-template>
                        </p-table>
                      </div>
                    </div>
                  </div>
    
                
            </main>
        -->
        <div class="card">
            <div class="row">
                <div class="col-sm">
                    <p-button label="Regions" (click)="loadRegions()" icon="pi pi-check" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                </div>
                <div class="col-sm">
                    <p-button label="Deppartements" (click)="loadDepartements()" icon="pi pi-check" styleClass="p-button-sm p-button-secondary p-button-text"></p-button>
                </div>
            </div>
            <div class="row">
                <div class="col-sm"><div class="col-sm"  ><p-dropdown placeholder="Division" *ngIf="division==='Regions'"  [disabled]="displayRegions" [options]="Regions"  styleClass="p-button-sm p-button-secondary p-button-text"></p-dropdown></div></div>
                <div class="col-sm"><div class="col-sm"  ><p-dropdown placeholder="Division" *ngIf="division==='Departements'"  [options]="Departements"  styleClass="p-button-sm p-button-secondary p-button-text"></p-dropdown></div></div>
            </div>
            
        </div>
        <p-accordion>
            <p-accordionTab header="Effectifs des formateurs par diplôme academique de l année de formation">
                <p-table styleClass="p-datatable-striped" [value]="formateurs" [tableStyle]="{ 'min-width': '50rem' }">
                    <ng-template pTemplate="header" style="background-color: #2E394B !important">
                        
                        <tr>
                            <th>Name</th>
                            <th>Status</th>
                            <th>Country</th>
                            <th>Company</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-formateur>
                        <tr>
                            <td>{{ formateur.name }}</td>
                            <td>{{ formateur.status }}</td>
                            <td>{{ formateur.country.name }}</td>
                            <td>{{ formateur.company }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab header="Effectifs des formateurs par diplôme professionnel de l année de formation">
                Content 2
            </p-accordionTab>
            <p-accordionTab header="Formateurs par spécialités de formation pour l'année de formation">
                Content 3    
            </p-accordionTab>
            <p-accordionTab header="Efectifs des formateurs par statut professionnel pour l'année de formation">
                Content 3    
            </p-accordionTab>
            <p-accordionTab header="Efectifs des formateurs par type de handicap pour l'année de formation">
                Content 3    
            </p-accordionTab>
        </p-accordion>
        
        </p-scrollPanel>
    
    </section>


    
</p-tabView>
