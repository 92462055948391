import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';

import {StoreModule} from '@ngrx/store';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {defineCustomElements} from '@profabric/web-components/loader';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartModule } from 'primeng/chart';
import {TimelineModule} from 'primeng/timeline';
import {TableModule} from 'primeng/table';
import {CalendarModule} from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import {MultiSelectModule} from 'primeng/multiselect';
import {DropdownModule} from 'primeng/dropdown';
import {SliderModule} from 'primeng/slider';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {TabViewModule} from 'primeng/tabview';
import {SidebarModule} from 'primeng/sidebar';
import {SplitButtonModule} from 'primeng/splitbutton';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {BadgeModule} from 'primeng/badge';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { LoginComponent } from '@pages/auth/login/login.component';
import { RegisterComponent } from '@pages/auth/register/register.component';
import { EmailActivateAccountComponent } from '@pages/auth/email-activate-account/email-activate-account.component';
import { EmailResetPasswordComponent } from '@pages/auth/email-reset-password/email-reset-password.component';
import { ResetPasswordComponent } from '@pages/auth/reset-password/reset-password.component';
import { SelectionComponent } from './pages/selection/selection.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { MenuItemComponent } from '@components/menu-item/menu-item.component';
import { SidebarSearchComponent } from '@components/sidebar-search/sidebar-search.component';
import { ControlSidebarComponent } from '@modules/main/control-sidebar/control-sidebar.component';
import { FooterComponent } from '@modules/main/footer/footer.component';
import { HeaderComponent } from '@modules/main/header/header.component';
import { LanguageComponent } from '@modules/main/header/language/language.component';
import { MessagesComponent } from '@modules/main/header/messages/messages.component';
import { NotificationsComponent } from '@modules/main/header/notifications/notifications.component';
import { UserComponent } from '@modules/main/header/user/user.component';
import { MenuSidebarComponent } from '@modules/main/menu-sidebar/menu-sidebar.component';
import { MainMenuComponent } from '@pages/main-menu/main-menu.component';
import { SubMenuComponent } from '@pages/main-menu/sub-menu/sub-menu.component';
import { AutorisationsComponent } from '@pages/administration/autorisations/autorisations.component';
import { DetailAutorisationComponent } from '@pages/administration/autorisations/detail-autorisation/detail-autorisation.component';
import { AllArondissementComponent } from '@pages/administration/divisions/all-arondissement/all-arondissement.component';
import { AllDepartementsComponent } from '@pages/administration/divisions/all-departements/all-departements.component';
import { ArrondissementComponent } from '@pages/administration/divisions/arrondissement/arrondissement.component';
import { DepartementComponent } from '@pages/administration/divisions/departement/departement.component';
import { DivisionsComponent } from '@pages/administration/divisions/divisions.component';
import { NotificationComponent } from '@pages/administration/notification/notification.component';
import { ParametreGenerauxComponent } from '@pages/administration/parametre/parametre-generaux/parametre-generaux.component';
import { ParametreComponent } from '@pages/administration/parametre/parametre.component';
import { SessionComponent } from '@pages/administration/parametre/session/session.component';
import { DetailsUtilisateursComponent } from '@pages/administration/utilisateurs/details-utilisateurs/details-utilisateurs.component';
import { UtilisateursComponent } from '@pages/administration/utilisateurs/utilisateurs.component';
import { QuestionRecolteDonneeComponent } from '@pages/autres/question-recolte-donnee/question-recolte-donnee.component';
import { TrancheAgeComponent } from '@pages/autres/tranche-age/tranche-age.component';
import { TypeBourseComponent } from '@pages/autres/type-bourse/type-bourse.component';
import { TypeHandicapComponent } from '@pages/autres/type-handicap/type-handicap.component';
import { DiplomeComponent } from '@pages/gestion-formation/diplome/diplome.component';
import { FiliereComponent } from '@pages/gestion-formation/filiere/filiere.component';
import { DetailFormationComponent } from '@pages/gestion-formation/formations/detail-formation/detail-formation.component';
import { FormationsComponent } from '@pages/gestion-formation/formations/formations.component';
import { NiveauEtudeComponent } from '@pages/gestion-formation/niveau-etude/niveau-etude.component';
import { NiveauEtudesComponent } from '@pages/gestion-formation/niveau-etudes/niveau-etudes.component';
import { ReferentielComponent } from '@pages/gestion-formation/referentiel/referentiel.component';
import { DetailSecteurPorteurComponent } from '@pages/gestion-formation/secteurs-porteurs/detail-secteur-porteur/detail-secteur-porteur.component';
import { SecteursPorteursComponent } from '@pages/gestion-formation/secteurs-porteurs/secteurs-porteurs.component';
import { StatutProfessionelComponent } from '@pages/gestion-formation/statut-professionel/statut-professionel.component';
import { QualificationFormationComponent } from '@pages/gestion-formation/qualification-formation/qualification-formation.component';
import { TypeDiplomeQualificationComponent } from '@pages/gestion-formation/type-diplome-qualification/type-diplome-qualification.component';
import { TypeFormationComponent } from '@pages/gestion-formation/type-formation/type-formation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { PersonnelComponent } from '@pages/gestion-personnel/personnel/personnel.component';
import { FonctionsComponent } from '@pages/gestion-personnel/fonctions/fonctions.component';
import { ServicesComponent } from '@pages/gestion-personnel/services/services.component';
import { QualitePersonnelComponent } from '@pages/gestion-structure/qualite-personnel/qualite-personnel.component';
import { RessourcesFinanciereComponent } from '@pages/gestion-structure/ressources-financiere/ressources-financiere.component';
import { StructuresComponent } from '@pages/gestion-structure/structures/structures.component';
import { TypePromoteurComponent } from '@pages/gestion-structure/type-promoteur/type-promoteur.component';
import { TypeStructuresComponent } from '@pages/gestion-structure/type-structures/type-structures.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ChipModule } from 'primeng/chip';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { DashboardComponent } from '@pages/dashboard/dashboard.component';
import { TypesQuestionComponent } from '@pages/autres/question-recolte-donnee/types-question/types-question.component';
import { QuestionComponent } from '@pages/autres/question-recolte-donnee/question/question.component';
import { QuestionParTypeComponent } from './pages/autres/question-recolte-donnee/question/question-par-type/question-par-type.component';
import { DetailsQualificationComponent } from './pages/gestion-formation/qualification-formation/details-qualification/details-qualification.component';
import { ApprenantsComponent } from './pages/gestion-structure/apprenants/apprenants.component';
import { FormateursComponent } from './pages/gestion-structure/formateurs/formateurs.component';
import { DashboardStructureComponent } from './pages/gestion-structure/structures/dashboard-structure/dashboard-structure.component';
import { MenuModule } from 'primeng/menu';
import { InfosGeneraleComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-generale/infos-generale.component';
import { IdentificationComponent } from './pages/gestion-structure/structures/dashboard-structure/identification/identification.component';
import { InfosFormateursComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-formateurs/infos-formateurs.component';
import { InfosApprenantsComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-apprenants/infos-apprenants.component';
import { InfosManuelsComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-manuels/infos-manuels.component';
import { InfosOrientationComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-orientation/infos-orientation.component';
import { InfosSuivieFormationComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-suivie-formation/infos-suivie-formation.component';
import { InfosVihViolenceComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-vih-violence/infos-vih-violence.component';
import { InfosDifficulteComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-difficulte/infos-difficulte.component';
import {DividerModule} from 'primeng/divider';
import { StyleClassModule } from 'primeng/styleclass';
import {MenubarModule} from 'primeng/menubar';
import { ApprenantTrancheAgeComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-apprenants/apprenant-tranche-age/apprenant-tranche-age.component';
import { ApprenantNiveauEtudeComponent } from './pages/gestion-structure/structures/dashboard-structure/infos-apprenants/apprenant-niveau-etude/apprenant-niveau-etude.component';



defineCustomElements();
registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        RegisterComponent,
        EmailActivateAccountComponent,
        EmailResetPasswordComponent,
        ResetPasswordComponent,
        SelectionComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        UtilisateursComponent,
        AutorisationsComponent,
        NotificationComponent,
        DivisionsComponent,
        ParametreComponent,
        DepartementComponent,
        ArrondissementComponent,
        DetailsUtilisateursComponent,
        AllDepartementsComponent,
        AllArondissementComponent,
        DetailAutorisationComponent,
        ParametreGenerauxComponent,
        SessionComponent,
        TypeHandicapComponent,
        TypeBourseComponent,
        TrancheAgeComponent,
        QuestionRecolteDonneeComponent,
        FormationsComponent,
        SecteursPorteursComponent,
        NiveauEtudesComponent,
        TypeFormationComponent,
        StatutProfessionelComponent,
        TypeDiplomeQualificationComponent,
        QualificationFormationComponent,
        DetailFormationComponent,
        DetailSecteurPorteurComponent,
        FiliereComponent,
        NiveauEtudeComponent,
        SpinnerComponent,
        DiplomeComponent,
        ReferentielComponent,
        PersonnelComponent,
        ServicesComponent,
        FonctionsComponent,
        StructuresComponent,
        TypeStructuresComponent,
        QualitePersonnelComponent,
        RessourcesFinanciereComponent,
        TypePromoteurComponent,
        DashboardComponent,
        TypesQuestionComponent,
        QuestionComponent,
        QuestionParTypeComponent,
        DetailsQualificationComponent,
        ApprenantsComponent,
        FormateursComponent,
        DashboardStructureComponent,
        InfosGeneraleComponent,
        IdentificationComponent,
        InfosFormateursComponent,
        InfosApprenantsComponent,
        InfosManuelsComponent,
        InfosOrientationComponent,
        InfosSuivieFormationComponent,
        InfosVihViolenceComponent,
        InfosDifficulteComponent,
        FormateursComponent,
        ApprenantTrancheAgeComponent,
        ApprenantNiveauEtudeComponent,
        
    ],
    imports: [
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        NgbModule,
        BreadcrumbModule,
        AvatarGroupModule,
        AvatarModule,
        ToastModule,
        ChipModule,
        MenubarModule,
        NgbModalModule,
        BreadcrumbModule,
        AccordionModule,
        CardModule,
        TieredMenuModule,
        MenuModule,
        CheckboxModule,
        BadgeModule,
        StyleClassModule,
        ConfirmDialogModule,
        MultiSelectModule,
        DropdownModule,
        SliderModule,
        InputTextModule,
        ButtonModule,
        TabViewModule,
        SidebarModule,
        SplitButtonModule,
        TableModule,
        ScrollPanelModule,
        NgbDropdownModule,
        NgbModalModule,
        BreadcrumbModule,
        FormsModule,
        ChipModule,
        ToastModule,
        AvatarModule,
        AvatarGroupModule,
        ToastrModule,
        AccordionModule,
        ReactiveFormsModule,
        ButtonModule,
        NgxSpinnerModule,
        CardModule,
        TieredMenuModule,
        CheckboxModule,
        DividerModule,
        ConfirmDialogModule,
        FormsModule,
        SliderModule,
        InputTextModule,
        ButtonModule,
        SidebarModule,
        TabViewModule,
        SplitButtonModule,
        MultiSelectModule,
        DropdownModule,
        NgbModule,
        NgbDropdownModule,
        ChartModule,
        CalendarModule,
        TableModule,
        TimelineModule,
        ProfabricComponentsModule,
        TabViewModule,
        ChartModule,
        TimelineModule,
        BadgeModule,
        ConfirmDialogModule,
        MultiSelectModule,
        SplitButtonModule,
        DropdownModule,
        InputTextModule,
        TableModule,
        SliderModule,
        CalendarModule,
        FormsModule,
        ButtonModule,
        SidebarModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule,
        ScrollPanelModule,
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true
        }),
        ProfabricComponentsModule,
        MenuModule
    ],
    providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
