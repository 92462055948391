<section class="main content-wrapper">
    <div class="content-header">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-sm-9 d-flex">
                    <h1 class="m-0 text-dark">Tableau de bord</h1>
                    <ol class="breadcrumb" style="padding-top:0.5rem">
                        <li class="breadcrumb-item">Accueil</li>
                        <li class="breadcrumb-item active">Tableau de Bord</li>
                    </ol>
                </div>
                <!-- /.col -->

                <!-- /.col -->
            </div>
            <!-- /.row -->
        </div>
        <!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <section class="content">


        <main class="main-content position-relative max-height-vh-100 h-100 mt-1 border-radius-lg ">

            <div class="container-fluid py-4 pt-0">
                <div class="row">
                    <div class="col-xl-3 col-sm-6 mb-xl-0 ">
                        <div class="card bg-primary test">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers bg-primary">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold">Nombre de structure</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                53
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-home text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card bg-success test">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold">Nombre d'étudiant</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                750
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-users text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card bg-danger test">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold">Agrément Expirés</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                53
                                            </h5>
                                        </div>
                                    </div>

                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-folder text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6">
                        <div class="card bg-dark test">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold">Nombre d'agréement</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                3
                                                <!-- <span class="text-danger text-sm font-weight-bolder">6 en attentes</span> -->
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-clipboard text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-3 col-sm-6 mb-xl-0 ">
                        <div class="card bg-secondary test">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold">Nombre de formation</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                55
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-home text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card bg-warning test">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold text-white">Agrément expirant cette année</p>
                                            <h5 class="font-weight-bolder mb-0 text-white">
                                                750
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-users text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6 mb-xl-0 mb-4">
                        <div class="card bg-info test">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold">Centre en attente de donnée</p>
                                            <h5 class="font-weight-bolder mb-0">
                                                55
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-folder text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-3 col-sm-6">
                        <div class="card test" style="background-color: rgb(241, 95, 149);">
                            <div class="card-body p-3">
                                <div class="row">
                                    <div class="col-8">
                                        <div class="numbers">
                                            <p class="text-sm mb-0 text-capitalize font-weight-bold text-white">Nombre de structure non agrée</p>
                                            <h5 class="font-weight-bolder mb-0 text-white">
                                                3
                                                <!-- <span class="text-danger text-sm font-weight-bolder">6 en attentes</span> -->
                                            </h5>
                                        </div>
                                    </div>
                                    <div class="col-4 text-end">
                                        <div style="background-color: transparent !important" class="icon icon-shape   text-center border-radius-md">
                                            <i class="fa fa-clipboard text-lg opacity-10" style="color: #ffffff;font-size:50px !important" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-7 mb-lg-0 mb-4">
                        <div class="card p-4">
                            <h6>Statistiques nationales des étudiants par filière</h6>

                            <p-chart type="bar" [data]="stackedData" [options]="stackedOptions"></p-chart>

                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="card h-100 p-3 pb-0">
                            <h6>Statistiques nationales des centres par region</h6>
                            <div style="display: flex; justify-content: center;align-items:center">
                                <p-chart type="doughnut" [data]="data" [options]="chartOptions" [style]="{'width': '70%'}"></p-chart>

                            </div>

                        </div>
                    </div>
                </div>

                <div class="row mt-0">
                    <div class="col-lg-5 mb-lg-0 mb-4 mt-2 p-2">
                        <div class="card z-index-2">

                            <p-calendar [(ngModel)]="date" [inline]="true" [showWeek]="true"></p-calendar>

                        </div>
                    </div>

                    <div class="col-lg-7 mt-2 p-2">
                        <div class="card z-index-2">
                            <div class="card-header pb-0">
                                <h6>Sales overview</h6>
                                <p class="text-sm">
                                    <i class="fa fa-arrow-up text-success"></i>
                                    <span class="font-weight-bold">4% more</span> in 2021
                                </p>
                            </div>
                            <div class="card-body p-3">
                                <div class="chart">
                                    <canvas id="chart-line" class="chart-canvas" height="300"></canvas>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </main>
    </section>
    <!-- /.content -->
</section>