import { Component, OnInit } from '@angular/core';
import {ConfirmationService} from 'primeng/api';
import * as pdfMake from 'pdfmake/build/pdfmake'
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {  NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common'
import { User } from './user.model';
import {MessageService} from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { AutorisationService } from '@services/autorisation.service';
import { AppDataState, DataStateEnum } from '@/State/load.state';
import { catchError, map, Observable, of, startWith } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-autorisations',
  templateUrl: './autorisations.component.html',
  styleUrls: ['./autorisations.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class AutorisationsComponent implements OnInit {
  datas: Observable<AppDataState<any[]>> |null = null;
  readonly DataStateEnum=DataStateEnum;
users: any[];
droitToPublish: any[];
  saveUserToEdit: any;
  visibleSidebar: boolean = false;
   selectedUsers: any[];
   visibleSidebar1: boolean = false;
   visibleSidebar2: boolean = false;
   representatives: any[];
    data:any;
   statuses: any[];
   items: any[];
   home: any;
   statusData: any;
   loading: boolean = true;
  val:any;
  Form:any;
  vals: any;
   activityValues: number[] = [0, 100];
[x: string]: any;
ChaineEditAutorisation='';
get libelle(){return this.Form.get('libelle'); }

constructor(private service: AutorisationService,private location: Location,private confirmationService: ConfirmationService, private modalService: NgbModal, private messageService: MessageService, private toastr: ToastrService) {}
ngOnInit(){
  this.Form = new FormGroup({
    "id": new FormControl(""),
    "libelle": new FormControl("", [Validators.required]),
  })
  this.items = [

    {label:'Autorisations'}
]
this.home = {icon: 'pi pi-home', routerLink: '/Dashboard'};
this.get();
}
addSingle() {
  this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
}
back(){
  this.location.back();
}
confirm(content) {
  this.confirmationService.confirm({
    message:"Voulez-vous supprimer l'autorisation ",
    accept:()=> {
      this.toastr.error('L autorisation a été supprimé avec success');
      content().close();
      this.ngOnInit();
    }
  });

  
}
editAutorisation(userToEdit:any[]){
  this.visibleSidebar=true;
  if(userToEdit){
    this.ChaineEditAutorisation='Modifier cette autorisation';
    this.saveUserToEdit= {...userToEdit};
    
  }
  else {
    this.saveUserToEdit= {};
    this.ChaineEditAutorisation='Ajouter une autorisation';}
}
open(content) {
  
this.modalService.open(content);
}
  generatePDF(){
    
    const docDefinition = {
    
      content: [
        {  
          text: 'MINEFOP',  
          fontSize: 16,  
          alignment: 'center',  
          color: '#047886'  
        },  
        {  
          text: 'Autorisations',  
          fontSize: 20,  
          bold: true,  
          alignment: 'center',  
          decoration: 'underline',  
          color: 'skyblue'  
        } ,
        {  
          text: 'Users Details',  
          style: 'sectionHeader'  
        }, 
         
        {
          style: "tableExample",
          table: {
            body: [
              ["#", "Name", "Description", "Total"],
              ...this.users.map(u => ([u.id, u.role_name, u.description, u.total])), 
            ]
          }
        },
        {  
          columns: [  
              [{ qr: `${this.users.indexOf}`, fit: '50' }],  
              [{ text: 'Signature', alignment: 'right', italics: true }],  
          ]  
      },  
      ],

      styles: {
        header: {
          fontSize: 18,
          bold: true,
          decoration: 'underline',
          margin: [0, 15, 0, 15]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          fontSize: 16,
          bold: false,
          margin: [0, 5, 0, 15]
        }
      }
    };

   // pdfMake.createPdf(docDefinition).download("test.pdf");
   pdfMake.createPdf(docDefinition).open();
  
  }
    get(){
      this.statusData = 'load'

     this.val= this.service.getAll().subscribe((data) => {
        this.data = data;
        this.statusData = 'load'
        

      },
      (error) => {
        this.statusData = 'error'

     },
     () => {
      this.statusData = 'complete'

      });
      console.log(this.val)
      // this.data$ = this.service.getAll().pipe(
      //   map(data =>{
      //     this.data=data;
      //     console.log(data);
      //   }),
      //   startWith({dataState:DataStateEnum.LOADING}),
      //   catchError(err=>of({dataState:DataStateEnum.ERROR, errorMessage:err.message}))
      // );
     }
     opens(content,value:any) {
      this.modalService.open(content, { centered: true })
      this.vals= {...value};
  
    }

     update(){
      this.submitted = true;
      console.log(this.Form.value)
        this.service.update(this.Form.value).subscribe(data => {
          this.submitted = false;
          this.visibleSidebar= false;
          this.ngOnInit();
          this.toastr.success('Autorisation modifiée avec succés', 'Success');
    
        },
        (error)=>{
          this.submitted = false;
          this.toastr.error('Nous rencontrons une erreur', 'Error');
        })
     }
     add(){
      this.submitted = true;
      if( this.Form.valid){
        this.service.create(this.Form.value).subscribe((result)=>{
         this.submitted = false;
         this.visibleSidebar= false;
          this.ngOnInit();
          this.toastr.success('Autorisationd ajouté avec succées', 'Success');
    
        },
        (error)=>{
         this.submitted = false;
         this.toastr.error('Nous rencontrons une erreur', 'Error');
    
        }
        )
      }
     }
}
