import { AppDataState, DataStateEnum } from '@/State/load.state';
import { Component, Input } from '@angular/core';
import { ApprenantTrancheAgeService } from '@services/apprenant-tranche-age.service';
import { EtatCanditatsService } from '@services/etat-canditats.service';
import { Observable, map, startWith, catchError, of } from 'rxjs';

@Component({
  selector: 'app-apprenant-tranche-age',
  templateUrl: './apprenant-tranche-age.component.html',
  styleUrls: ['./apprenant-tranche-age.component.scss']
})
export class ApprenantTrancheAgeComponent {
  @Input() id: any;
  data: any;
  data$: Observable<AppDataState<any[]>> |null = null;
  types:any;
  readonly DataStateEnum=DataStateEnum;
  value: boolean = false;
  states:any;
  table:any[]=[];
  sexes:any[]=[];
  constructor(private state: EtatCanditatsService,private service: ApprenantTrancheAgeService){}
  ngOnInit(): void {
      console.log(this.id);
      this.get();
      this.getState();
  }
  get(){
    console.log('ok')
    console.log('ok')
    this.service.getAll().subscribe(data => {
      this.data = data;
      this.iterate1(data)
     },
     (error) => {
     });
   }
   getState(){
    console.log('ok')
    this.state.getAll().subscribe(data => {
      this.states = data;
      this.iterate2(data)
     },
     (error) => {
     });
   }
   iterate1(data:any){  
    console.log(data)
   data.forEach(element => {
    let tab = [];
    element.effectifs.forEach(item => {
      tab.push(item.m)
      tab.push(item.f)
    });
    this.table.push(tab);
   });
   console.log(this.table)
   }
   iterate2(data:any){  
    console.log(data)
   data.forEach(element => {
    this.sexes.push("H")
      this.sexes.push("F")
    
   });
   console.log(this.sexes)
   }
  
}
