
<section class="content main">
<ng-container *ngIf="data$ | async as result" [ngSwitch]="result.dataState">
    <ng-container *ngSwitchCase="DataStateEnum.LOADING">
        <app-spinner></app-spinner>
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.ERROR">
    </ng-container>
    <ng-container *ngSwitchCase="DataStateEnum.LOADED">
        
    <p-card header="">
      <p-toolbar>
        <div class="p-toolbar-group-left">
          <h2>Liste des sessions</h2>
        </div>
        <div class="p-toolbar-group-right">
          <button pButton pRipple (click)="changeState()" type="button" label="Ajouter" icon="pi pi-plus" class="p-button p-button-sm"></button>
        </div>
      </p-toolbar>
      <p-toolbar *ngFor="let session of data">
        <div class="p-toolbar-group-left">
          <div class="row">
            <div class="col-lg-4" ><p>Annee</p></div>
            <div class="col-lg-4">{{session.annee}}</div>
          </div>
        </div>
        <div class="p-toolbar-group-right">
            <p-button type="button" (click)="editForm(session)" icon="pi pi-pencil" styleClass="p-button-warning">
            </p-button>
          </div>
      </p-toolbar>
        
      
        
      
  
</p-card>
    </ng-container>
  </ng-container>
</section>

<p-sidebar position="right" [style]="{width: '50%'}" [(visible)]="visibleSidebar" [baseZIndex]="10000">
  
  <ng-template pTemplate="header">
    <h3>{{titreSession}}</h3>
</ng-template>

<ng-template pTemplate="content">
    <form [formGroup]="Form">
    <div class="col-12 field">
  
        <div class="input-field">
        <input type="text"  style="display: none;" formControlName="id" class="form-control" [(ngModel)]="sessionSave.id" />
      </div>
      
      
        <div class="input-field">
            <label class="form-label" for="annee">Annee<span class="text-danger me-1">*</span></label>
            <div class="input-group">
                <input type="number" formControlName="annee" name="anne"  class="form-control" [(ngModel)]="sessionSave.annee" required />
            </div>
        </div>
    </div>
</form>
</ng-template>


<ng-template pTemplate="footer">
<button pButton pRipple type="button" [disabled]="submitted"  (click)="visibleSidebar=false" label="Annuler" class="p-button-raised p-button-secondary" style="margin-right: 10px;"></button>
<button *ngIf="titreSession==='Ajouter une session'" [disabled]="submitted"  pButton pRipple type="button" (click)="addBourseSession()" label="Sauvegarder" class="p-button-raised" ><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i> </button>
 <button *ngIf="titreSession=='Modifier cette session'" [disabled]="submitted"  pButton pRipple type="button" label="Modifier" class="p-button-raised" (click)="editBourse()"><i class="pi pi-spin pi-spinner" *ngIf="submitted"></i></button>
  
</ng-template>


</p-sidebar>

  