<table class="table table-condensed table-bordered">
    <thead>

        <tr>
            <th rowspan="2">Tranches d'ages</th>
            <th colspan="2" scope="colgroup" *ngFor="let item of states">{{item}}</th>
        </tr>
        <tr>
            <th scope="col" *ngFor="let item of sexes">{{item}}</th>
        </tr>

    </thead>
    <tbody>
        <tr *ngFor="let item of data let i = index">

            <td>{{item.trancheAge.libelle}}</td>
            <td *ngFor="let item of table[i]">
                {{item}}
            </td>

        </tr>
    </tbody>
</table>